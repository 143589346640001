import { serviceHandler } from '../../services/serviceHandler'
import { AuthenticationApi } from '../../services/apiUrls'

const { UserSettings } = AuthenticationApi

export const getSettings = (payload) => {
  return serviceHandler({
    url: UserSettings,
    methodType: 'get',
    payload
  })
}
export const saveSettings = (payload) => {
  return serviceHandler({
    url: UserSettings,
    methodType: 'put',
    payload
  })
}
