import React, { useState } from 'react'
import { IonLabel, IonAvatar, IonChip, IonImg, IonButton, useIonRouter, IonToast } from '@ionic/react'
import { intrestImages } from '../data/fields'
import styles from './Profile.module.scss'
import { AppAvailability } from '@awesome-cordova-plugins/app-availability'
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser'
import BlockModal from '../components/BlockUser/BlockModal'
import ReportModal from '../components/BlockUser/ReportModal'
import ReadMore from './ReadMore'
import { requestVerification } from '../pages/MyProfile/services'
import { handleIfValidInteraction } from '../util/a11yHelper'
import { formatUserDistance } from './util'
import { IS_ANDROID, IS_HYBRID, IS_IOS } from '../consts'

interface OwnProps {
  profile: any
  profileInfo: any
  isEditable: boolean
}

const DEET_OTHER_ID = 24

interface ProfileProps extends OwnProps {}

const Profile: React.FC<ProfileProps> = ({ profileInfo, isEditable }) => {
  const router = useIonRouter()
  let imageList: any = intrestImages
  const { id } = profileInfo
  const [isBlockOpen, setIsBlockOpen] = useState(false)
  const [isReportOpen, setIsReportOpen] = useState(false)
  const [toast, setToast] = useState({
    color: 'secondary',
    isOpen: false,
    message: '',
    icon: ''
  })

  const onBlock = () => {
    setIsBlockOpen(true)
  }

  const onReport = () => {
    setIsReportOpen(true)
  }
  const onRequestVerification = async () => {
    await requestVerification(id)
    setToast({
      isOpen: true,
      color: 'success',
      message: 'Your request for profile verification has been submitted',
      icon: 'alert-outline'
    })
  }

  const routeToProfileEdit = (e) =>
    handleIfValidInteraction(e, () => {
      router.push('/edit-profile?tab=moreAbout')
    })

  const routeToBasicInfo = (e) =>
    handleIfValidInteraction(e, () => {
      router.push('/edit-profile?tab=basicInfo')
    })

  const routeToInstagram = (e) =>
    handleIfValidInteraction(e, () => {
      openInstagram(profileInfo?.UserInfo?.instagram_id)
    })

  const routeToProfileLooking = (e) =>
    handleIfValidInteraction(e, () => {
      router.push('/edit-profile?tab=looking')
    })

  const routeToProfileInterest = (e) =>
    handleIfValidInteraction(e, () => {
      router.push('/edit-profile?tab=interest')
    })

  const handleRequestVerification = (e) => handleIfValidInteraction(e, onRequestVerification)

  const handleBlockUser = (e) => handleIfValidInteraction(e, onBlock)

  const handleReportUser = (e) => handleIfValidInteraction(e, onReport)

  const isInstagramAppAvailable = async () => {
    let isAppAvailable = false
    if (IS_IOS && IS_HYBRID) {
      isAppAvailable = await AppAvailability.check('instagram://')
    } else if (IS_ANDROID && IS_HYBRID) {
      isAppAvailable = await AppAvailability.check('com.instagram.android')
    }
    return isAppAvailable
  }

  const openInstagram = async (profile: string) => {
    const isAppAvailable = await isInstagramAppAvailable()
    console.log(`OPEN INSTA - ${profile}`, isAppAvailable)
    isAppAvailable
      ? InAppBrowser.create(`instagram://${profile}`, '_system')
      : InAppBrowser.create(`https://www.instagram.com/${profile}`, '_system')
  }

  return (
    <div className={styles.pdetails}>
      <IonToast
        color={toast.color}
        isOpen={toast.isOpen}
        message={toast.message}
        duration={1500}
      />
      <div className={styles.profileName}>
        <div className={styles.name}>
          <div className={styles.info}>
            {profileInfo.first_name}, {profileInfo?.UserInfo?.age}
            {profileInfo.account_status === 'DELETED' ? (
              <IonChip
                color='danger'
                style={{ float: 'right' }}
              >
                Deactivated
              </IonChip>
            ) : null}
            {profileInfo.is_verified && (
              <IonImg
                src='assets/images/badge.png'
                alt='Verified Profile Badge'
                className={styles.badgeimg}
              />
            )}
          </div>
          {profileInfo?.UserInfo?.main_location && profileInfo?.UserInfo?.main_location_visibility && (
            <p>{profileInfo.UserInfo?.main_location}</p>
          )}
          {profileInfo.distanceinmiles && (
            <div className='milesaway'>{formatUserDistance(profileInfo.distanceinmiles)}</div>
          )}
        </div>
        {profileInfo?.UserInfo?.instagram_id ? (
          <div
            className={styles.instagram}
            tabIndex={0}
            role='link'
            onClick={routeToInstagram}
            onKeyUp={routeToInstagram}
          >
            <IonImg
              src='assets/images/instagram.svg'
              alt='Instagram Handle:'
            />
            <span>{profileInfo?.UserInfo?.instagram_id}</span>
          </div>
        ) : null}
      </div>
      <div className={styles.about}>
        <IonLabel className={styles.subtitle}>
          About{' '}
          {isEditable && (
            <span
              className='pointer'
              role='link'
              tabIndex={0}
              aria-label='Edit my profile'
              onClick={routeToProfileEdit}
              onKeyUp={routeToProfileEdit}
            >
              Edit
            </span>
          )}
        </IonLabel>
        <p aria-live='polite'>{profileInfo?.UserInfo?.bio && ReadMore(profileInfo.UserInfo.bio)}</p>
      </div>
      <div className={styles.basics}>
        <IonLabel className={styles.subtitle}>
          Basics{' '}
          {isEditable && (
            <span
              className='pointer'
              role='link'
              tabIndex={0}
              aria-label='Edit my basic info'
              onClick={routeToBasicInfo}
              onKeyUp={routeToProfileEdit}
            >
              Edit
            </span>
          )}
        </IonLabel>
        <p>{`${profileInfo?.UserInfo?.height || ''} ft,  ${profileInfo?.UserInfo?.gender || ''} ${profileInfo.UserInfo?.pronoun_option?.label ? '(' + profileInfo?.UserInfo?.pronoun_option.label + ')' : ''}`}</p>
        <ul className={styles.basiclists}>
          {profileInfo.UserInfo?.occupation_visibility && (
            <li className={styles.items}>
              <div className={styles.label}>Occupation</div>
              <div className={styles.value}>{profileInfo?.UserInfo?.occupation}</div>
            </li>
          )}
          {profileInfo.UserInfo?.religion_visibility && (
            <li className={styles.items}>
              <div className={styles.label}>Religion</div>
              <div className={styles.value}>{profileInfo?.UserInfo?.religion_option?.label}</div>
            </li>
          )}
          {profileInfo.UserInfo?.political_view_visibility && (
            <li className={styles.items}>
              <div className={styles.label}>Political View</div>
              <div className={styles.value}>{profileInfo?.UserInfo?.politicalview_option?.label}</div>
            </li>
          )}
          {profileInfo.UserInfo?.school_visibility && (
            <li className={styles.items}>
              <div className={styles.label}>School</div>
              <div className={styles.value}>{profileInfo.UserInfo && profileInfo.UserInfo?.school}</div>
            </li>
          )}
          {profileInfo.UserInfo?.education_visibility && (
            <li className={styles.items}>
              <div className={styles.label}>Education</div>
              <div className={styles.value}>{profileInfo?.UserInfo?.education_option?.label}</div>
            </li>
          )}
          {profileInfo.UserInfo?.vaccination_status_visibility && (
            <li className={styles.items}>
              <div className={styles.label}>Vaccine Status</div>
              <div className={styles.value}>{profileInfo?.UserInfo?.vaccinated_status_option?.label}</div>
            </li>
          )}
          {profileInfo.UserInfo?.children_visibility && (
            <li className={styles.items}>
              <div className={styles.label}>Children</div>
              <div className={styles.value}>{profileInfo?.UserInfo?.children_preference?.label}</div>
            </li>
          )}
        </ul>
      </div>
      <div className={styles.deets}>
        <IonLabel className={styles.subtitle}>
          Looking For{' '}
          {isEditable && (
            <span
              role='link'
              aria-label='Edit My Looking For'
              tabIndex={0}
              className='pointer'
              onClick={routeToProfileLooking}
              onKeyUp={routeToProfileLooking}
            >
              Edit
            </span>
          )}
        </IonLabel>
        <div>
          {profileInfo.UserInfo?.userRelationshipInterests?.length > 0 ? (
            profileInfo.UserInfo?.userRelationshipInterests.map((item: any, idx) => {
              return (
                <IonChip
                  color='dark'
                  key={idx}
                >
                  <IonLabel className={styles.chiplabel}>{item.detail.label}</IonLabel>
                </IonChip>
              )
            })
          ) : (
            <IonChip color='dark'>
              <IonLabel className={styles.chiplabel}>Not Specified</IonLabel>
            </IonChip>
          )}
        </div>
      </div>
      <div className={styles.interest}>
        <IonLabel className={styles.subtitle}>
          Interests{' '}
          {isEditable && (
            <span
              className='pointer'
              tabIndex={0}
              role='link'
              aria-label='Edit My Interests'
              onClick={routeToProfileInterest}
              onKeyUp={routeToProfileInterest}
            >
              Edit
            </span>
          )}
        </IonLabel>
        <div>
          {profileInfo.UserInterests &&
            profileInfo.UserInterests.map((item: any) => {
              return (
                <IonChip
                  color='dark'
                  key={item.interest_id}
                >
                  {imageList[item.interest_id] ? (
                    <IonAvatar>
                      <IonImg
                        aria-hidden='true'
                        src={imageList[item.interest_id].icon}
                        alt=''
                      />
                    </IonAvatar>
                  ) : null}
                  <IonLabel className={styles.chiplabel}>{item.interest.name}</IonLabel>
                </IonChip>
              )
            })}
        </div>
      </div>
      <div className={styles.deets}>
        <IonLabel className={styles.subtitle}>
          Dateability Deets{' '}
          {isEditable && (
            <span
              className='pointer'
              role='link'
              tabIndex={0}
              aria-label='Edit My Dateability Deets'
              onClick={() => {
                router.push('/edit-profile?tab=deets')
              }}
            >
              Edit
            </span>
          )}
        </IonLabel>
        <div>
          {profileInfo.UserDeets &&
            profileInfo.UserDeets.map((item: any) => {
              return item.deet_id !== DEET_OTHER_ID ? (
                <IonChip
                  color='dark'
                  key={item.deet_id}
                >
                  <IonLabel className={styles.chiplabel}>{item.deets.name}</IonLabel>
                </IonChip>
              ) : item.deet_other && item.deet_other.length > 0 ? (
                <IonChip
                  color='dark'
                  key={item.deet_id}
                >
                  <IonLabel className={styles.chiplabel}>{item.deet_other}</IonLabel>
                </IonChip>
              ) : null
            })}
        </div>
      </div>
      {!isEditable && (
        <>
          {!profileInfo.is_verified && (
            <IonButton
              expand='block'
              fill='clear'
              className={styles.requestBtn}
              onClick={handleRequestVerification}
              onKeyUp={handleRequestVerification}
            >
              Request Verification
            </IonButton>
          )}
          {/* <IonButton expand="block" fill="clear" className={styles.requestBtn} onClick={() => onRequestVerification()} >Request Verification</IonButton> */}
          <IonButton
            expand='block'
            aria-label='Block this user'
            fill='clear'
            className={styles.blockBtn}
            onClick={handleBlockUser}
            onKeyUp={handleBlockUser}
          >
            Block
          </IonButton>
          <IonButton
            expand='block'
            aria-label='Report this profile'
            fill='clear'
            className={styles.blockBtn}
            onClick={handleReportUser}
            onKeyUp={handleReportUser}
          >
            Report
          </IonButton>
        </>
      )}
      <BlockModal
        isBlockOpen={isBlockOpen}
        setIsBlockOpen={setIsBlockOpen}
        id={id}
      />
      <ReportModal
        isReportOpen={isReportOpen}
        setIsReportOpen={setIsReportOpen}
        id={id}
      />
    </div>
  )
}

export default Profile
