import { useState } from 'react'
import { uniqueId } from 'lodash'
import { IonCheckbox, IonInput } from '@ionic/react'

import styles from './CustomField.module.scss'

/**
 * Provide a password input with checkbox/switch
 *
 * @param field a lot of stuff is in here
 * @param autoCapitalize html autocapitalize behavior
 * @constructor
 */
export const ShowablePasswordInput = ({ field, autoCapitalize = 'off' }: any) => {
  const [showPassword, setShowPassword] = useState(false)
  const myId = uniqueId('show_pass_')

  field.input.props.type = showPassword ? 'text' : 'password'

  return (
    <>
      <IonInput
        className={styles.customInput}
        {...field.input.props}
        {...field.input.state}
        placeholder={field.placeholder}
        autoCapitalize={autoCapitalize}
      />
      <div className={styles.showPasswordContainer}>
        <IonCheckbox
          aria-labelledby={myId}
          role='checkbox'
          color='success'
          title={`Show ${field.label}`}
          className={styles.showPasswordCheckbox}
          onIonChange={() => setShowPassword(!showPassword)}
        ></IonCheckbox>
        <span
          id={myId}
          className={styles.showPasswordLabel}
        >
          Show {field.label}
        </span>
      </div>
    </>
  )
}
