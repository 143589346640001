import { AuthenticationApi } from '../../services/apiUrls'
import { serviceHandler } from '../../services/serviceHandler'

export const getMessages = ({ page, userId }: { page: number; userId: string }) =>
  serviceHandler({
    url: `${AuthenticationApi.Message}/${userId}?limit=${30}&page=${page}`,
    methodType: 'get',
    payload: undefined
  })

export const postMessage = (payload: { to_user_id: string; message: string }) =>
  serviceHandler({
    url: AuthenticationApi.Message,
    methodType: 'post',
    payload
  })

export const getProfileById = (userId: string) =>
  serviceHandler({
    url: `${AuthenticationApi.Profile}/${userId}`,
    methodType: 'get',
    payload: undefined
  })
