/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import _ from 'lodash'
import { handleIfValidInteraction } from '../util/a11yHelper'

const ReadMore: React.FC<any> = (content) => {
  const [readMore, setReadMore] = useState(false)

  const handleReadMore = (e) => handleIfValidInteraction(e, () => setReadMore(true))

  if (readMore || content?.length < 401) {
    return content
  } else {
    return (
      <>
        {_.truncate(content, { length: 400 })}
        &nbsp;
        <a
          tabIndex={0}
          aria-label='Read full bio'
          style={{ cursor: 'pointer' }}
          onClick={handleReadMore}
          onKeyUp={handleReadMore}
        >
          Read More
        </a>
      </>
    )
  }
}
export default ReadMore
