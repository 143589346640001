import axios from 'axios'
import { AuthenticationApi } from '../../services/apiUrls'
import { SignInWithApple, ASAuthorizationAppleIDRequest } from '@awesome-cordova-plugins/sign-in-with-apple'
import { Capacitor } from '@capacitor/core'
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth'
import { IonButton, IonLabel, IonGrid, IonRow, IonImg } from '@ionic/react'
import styles from './Login.module.scss'
import { useEffect } from 'react'

export default function SocialLogins({ handleAfterLogin }) {
  useEffect(() => {
    try {
      GoogleAuth.initialize({
        clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        scopes: ['profile', 'email'],
        grantOfflineAccess: true
      })
    } catch (err) {
      console.log('Google SSO', err)
    }
  }, [])

  const GoogleLogin = async () => {
    try {
      const authResponse = await GoogleAuth.signIn()
      const apiResponse = await axios({
        method: 'POST',
        url: AuthenticationApi.GoogleLogin,
        data: {
          code: authResponse?.serverAuthCode
        }
      })

      handleAfterLogin(apiResponse.data.data)
    } catch (err) {
      console.error('GoogleLogin error', err instanceof Error ? err.message : JSON.stringify(err))
    }
  }
  const AppleMobileLogin = async () => {
    try {
      const authResponse = await SignInWithApple.signin({
        requestedScopes: [
          ASAuthorizationAppleIDRequest.ASAuthorizationScopeFullName,
          ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail
        ]
      })
      const apiResponse = await axios({
        method: 'POST',
        url: AuthenticationApi.AppleLogin,
        data: {
          authorization: {
            code: authResponse.authorizationCode,
            id_token: authResponse.identityToken
          },
          user:
            authResponse.email && authResponse.fullName?.givenName && authResponse.fullName?.familyName
              ? {
                  first_name: authResponse.fullName.givenName,
                  last_name: authResponse.fullName.familyName,
                  email: authResponse.email
                }
              : undefined
        }
      })

      handleAfterLogin(apiResponse.data.data)
    } catch (err) {
      console.error('AppleMobileLogin error', err instanceof Error ? err.message : JSON.stringify(err))
    }
  }

  return (
    <>
      <IonRow className={styles.socialIcons}>
        <IonGrid className='ion-no-margin ion-no-padding'>
          <div className={styles.field}>
            <IonLabel>
              <span>Or sign up with:</span>
            </IonLabel>
          </div>
        </IonGrid>
      </IonRow>
      <IonRow className={styles.socialIcons}>
        <IonButton
          style={{ height: '45px' }}
          fill='clear'
          role='button'
          aria-label='Sign in with Google'
          onClick={() => GoogleLogin()}
        >
          <IonImg
            aria-hidden='true'
            src='/assets/images/google.svg'
            alt='google'
            style={{ cursor: 'pointer' }}
          />
        </IonButton>
        {Capacitor.getPlatform() === 'ios' ? (
          <IonButton
            fill='clear'
            role='button'
            aria-label='Sign in with your Apple ID'
            onClick={() => AppleMobileLogin()}
          >
            <IonImg
              aria-hidden='true'
              style={{ cursor: 'pointer' }}
              src='/assets/images/apple.svg'
              alt='apple'
            />
          </IonButton>
        ) : null}
      </IonRow>
    </>
  )
}
