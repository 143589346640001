import axios from './axiosConfig'

/**
 * @typedef {'get'|'put'|'post'|'delete'} MethodTypes
 */

/**
 * Ajax helper used throughout the application
 *
 * @param {object} obj
 * @param {string} obj.url
 * @param {Object|undefined} [obj.payload]
 * @param {MethodTypes} obj.methodType
 * @param {object} [obj.options={}]
 * @returns {Promise<object>}
 */
export const serviceHandler = ({ url, payload, methodType, options = {} }) => {
  return axios[methodType](`${url}`, payload, options)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      console.log(`Error occurred while calling ${url} : ${error}`)
      if (error.response.status === 401 && window.location.pathname !== '/login') {
        setTimeout(function () {
          window.location.replace('/login')
        }, 4000)
      }
      return error
    })
}
