import axios from 'axios'
import { serviceHandler } from '../../services/serviceHandler'
import { AuthenticationApi } from '../../services/apiUrls'

const {
  OnboardBasics,
  OnboardDeets,
  OnboardInterests,
  OnboardMoreAbout,
  UploadPhoto,
  DeetsList,
  InterestsList,
  Option,
  Upload,
  DeleteUpload,
  LookingFor,

  UpdateUserDeets,
  ListUserDeets
} = AuthenticationApi

//@TODO(djw) deprecate
export const getBasicData = (payload) => {
  return serviceHandler({
    url: OnboardBasics,
    methodType: 'get',
    payload
  })
}
//@TODO(djw) deprecate
export const saveBasicData = (payload) => {
  return serviceHandler({
    url: OnboardBasics,
    methodType: 'put',
    payload
  })
}

/**
 *
 * @author DJW
 * @param payload
 * @return {Promise<Object>}
 */
export const fetchUserInfoData = () => {
  return serviceHandler({
    url: AuthenticationApi.getUserInfoData,
    methodType: 'get'
  })
}

/**
 *
 * @author DJW
 * @param payload
 * @return {Promise<Object>}
 */
export const putUserInfoData = (payload) => {
  return serviceHandler({
    url: AuthenticationApi.saveUserInfoData,
    methodType: 'put',
    payload
  })
}

export const getIntrestData = (payload) => {
  return serviceHandler({
    url: OnboardInterests,
    methodType: 'get',
    payload
  })
}
export const saveIntrestData = (payload) => {
  return serviceHandler({
    url: OnboardInterests,
    methodType: 'put',
    payload
  })
}

//@TODO(djw) to @deprecate
export const getDeetsData = (payload) => {
  return serviceHandler({
    url: OnboardDeets,
    methodType: 'get',
    payload
  })
}

/**
 * @typedef UserDeetRecord
 * @property {number} id
 * @property {number} deet_id
 * @property {string|null} deet_other
 */

/**
 * @typedef UserDeetResponse
 * @property {UserDeetRecord[]} data
 */

/**
 * Fetches the users currently selected deets
 *
 * @returns {Promise<UserDeetResponse>}
 */
export const listUserDeets = () => {
  return serviceHandler({
    url: ListUserDeets,
    methodType: 'get'
  })
}

/**
 * Send a changeset of user's selected deets to backend
 *
 * @returns {Promise}
 */

export const updateUserDeets = (payload) => {
  return serviceHandler({
    url: UpdateUserDeets,
    methodType: 'put',
    payload
  })
}
//@TODO(djw) to @deprecate
export const saveDeetsData = (payload) => {
  return serviceHandler({
    url: OnboardDeets,
    methodType: 'put',
    payload
  })
}
export const getAboutData = (payload) => {
  return serviceHandler({
    url: OnboardMoreAbout,
    methodType: 'get',
    payload
  })
}
export const saveAboutData = (payload) => {
  return serviceHandler({
    url: OnboardMoreAbout,
    methodType: 'post',
    payload
  })
}
export const uploadImage = (payload) => {
  return serviceHandler({
    url: UploadPhoto,
    methodType: 'post',
    payload
  })
}
export const deetList = (payload) => {
  return serviceHandler({
    url: `${DeetsList}?limit=100&page=1`,
    methodType: 'get',
    payload
  })
}
export const intrestList = (payload) => {
  return serviceHandler({
    url: `${InterestsList}?limit=20&page=1`,
    methodType: 'get',
    payload
  })
}
//@TODO(DJW) remove
//@deprecated
export const option = (payload) => {
  return serviceHandler({
    url: `${Option}?limit=100&page=1`,
    methodType: 'get',
    payload
  })
}

/**
 *
 * @author DJW
 * @param payload
 * @return {Promise<Object>}
 */
export const fetchUserInfoOptions = () => {
  return serviceHandler({
    url: AuthenticationApi.listAllUserInfoOptions,
    methodType: 'get'
  })
}

/**
 *
 * @author DJW
 * @param payload
 * @return {Promise<Object>}
 */
export const fetchUserInfoGenderOptions = () => {
  return serviceHandler({
    url: AuthenticationApi.listGenderInterestOptions,
    methodType: 'get'
  })
}

export const upload = (payload) => {
  return serviceHandler({
    url: Upload,
    methodType: 'post',
    payload
  })
}
export const deleteUpload = (payload) => {
  let token = localStorage.getItem('token')
  return axios.delete(DeleteUpload, {
    headers: {
      Authorization: `JWT ${token}`
    },
    data: payload
  })
}
export const fetchNotifications = () => {
  return serviceHandler({
    url: AuthenticationApi.Notifications + '?limit=20&page=1',
    methodType: 'get'
  })
}

export const fetchNotificationsJoinedDate = () => {
  return serviceHandler({
    url: AuthenticationApi.NotificationsJoinDate + '?limit=20&page=1',
    methodType: 'get'
  })
}

export const saveLookingFor = (payload) => {
  return serviceHandler({
    url: LookingFor,
    methodType: 'put',
    payload
  })
}

/**
 *
 * @TODO(djw) remove
 * @deprecated
 * @param payload
 * @return {Promise<Object>}
 */
export const getLookingFor = (payload) => {
  return serviceHandler({
    url: LookingFor,
    methodType: 'get',
    payload
  })
}

/**
 * @typedef UserRelationshipDetail
 * @property id {number}
 * @property label {string}
 */

/**
 * @typedef UserRelationshipOptions
 * @property id {number}
 * @property user_id {string}
 * @property detail {UserRelationshipDetail}
 */

/**
 * Get the currently logged in user's relationship interests
 *
 * @return {Promise<{data:UserRelationshipOptions[]}>}
 */
export const fetchUserRelationshipInterests = () => {
  return serviceHandler({
    url: AuthenticationApi.fetchUserRelationshipInterests,
    methodType: 'get'
  })
}

/**
 *
 * @return {Promise<{data:UserRelationshipDetail[]}>}
 */
export const listRelationshipTypes = () => {
  return serviceHandler({
    url: AuthenticationApi.listRelationshipOptions,
    methodType: 'get'
  })
}

/**
 * Put the user relationship interest choices
 */
export const putUserRelationshipInterests = (payload) => {
  return serviceHandler({
    url: AuthenticationApi.putUserRelationshipInterests,
    methodType: 'put',
    payload
  })
}
