import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
  useIonLoading
} from '@ionic/react'
import { AppLauncher } from '@capacitor/app-launcher'
import { Purchases, type PurchasesOfferings } from '@revenuecat/purchases-capacitor'
import { arrowForward, close } from 'ionicons/icons'
import { type ComponentPropsWithoutRef, type FC, useEffect, useState } from 'react'
import { isNumber, map } from 'lodash'
import { IS_MOBILE } from '../consts'
import { useRevenueCatContext } from '../contexts/RevenueCatContext'

export interface PaywallModalProps extends Omit<ComponentPropsWithoutRef<typeof IonPage>, 'children' | 'id'> {
  onDismiss: () => void
}

export const PaywallModal: FC<PaywallModalProps> = ({ onDismiss, ...props }) => {
  const { isPlus } = useRevenueCatContext()
  const [offerings, setOfferings] = useState<PurchasesOfferings>()
  const [selectedIndex, setSelectedIndex] = useState<number>()
  const [showLoading, hideLoading] = useIonLoading()

  useEffect(() => {
    if (IS_MOBILE) {
      Purchases.getOfferings()
        .then(setOfferings)
        .catch((e) => console.error('[revenuecat] error', e))
    }
  }, [])

  return (
    <IonPage
      {...props}
      id='paywall-modal'
    >
      {isPlus && !IS_MOBILE ? (
        <>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot='start'>
                <IonButton onClick={onDismiss}>
                  <IonIcon icon={close} />
                </IonButton>
              </IonButtons>
              <IonTitle>Manage Subscription</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonText>
              <h1>Subscription Management</h1>
              <p>You are currently subscribed to Dateability+.</p>
              <p>To manage your subscription, please use the mobile app store.</p>
            </IonText>
          </IonContent>
        </>
      ) : (
        <>
          <IonHeader>
            <IonToolbar color='primary'>
              <IonTitle>Subscribe Now!</IonTitle>
              <IonButtons slot='end'>
                <IonButton onClick={onDismiss}>
                  <IonIcon
                    slot='icon-only'
                    icon={close}
                  />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent
            className='ion-padding'
            fullscreen
          >
            <IonText color='light'>
              <h1 style={{ textAlign: 'center' }}>Want a more dateable experience?</h1>
              <p>Subscribe now to Dateability+ and gain access to:</p>
            </IonText>
            <IonList className='ion-margin-bottom'>
              <IonItem lines='none'>
                <IonIcon
                  color='light'
                  size='small'
                  slot='start'
                  icon={arrowForward}
                />
                <IonText color='light'>
                  Filter by Dateability Deets, Profile Information, Verification & and more!
                </IonText>
              </IonItem>
              <IonItem lines='none'>
                <IonIcon
                  color='light'
                  size='small'
                  slot='start'
                  icon={arrowForward}
                />
                <IonText color='light'>See all your Likes at once!</IonText>
              </IonItem>
              <IonItem lines='none'>
                <IonIcon
                  color='light'
                  size='small'
                  slot='start'
                  icon={arrowForward}
                />
                <IonText color='light'>More to come!</IonText>
              </IonItem>
            </IonList>
            {offerings?.current?.availablePackages?.length ? (
              <IonList>
                <IonListHeader className='ion-margin-bottom'>
                  <IonText color='light'>Select Plan</IonText>
                </IonListHeader>
                {map(offerings.current.availablePackages, (purchasesPackage, index) => {
                  const isSelected = index === selectedIndex
                  const bg = isSelected ? 'light' : 'dark'
                  const fg = isSelected ? 'dark' : 'light'

                  return (
                    <IonItem
                      className='ion-margin-bottom'
                      color={bg}
                      onClick={() => setSelectedIndex(index)}
                      key={purchasesPackage.identifier}
                    >
                      <IonLabel color={fg}>
                        <p>
                          <strong>{purchasesPackage.product.title}</strong>
                        </p>
                        <p>
                          <strong>{purchasesPackage.product.priceString}</strong> (billed{' '}
                          {purchasesPackage.product.subscriptionPeriod === 'P1Y' ? 'annually' : 'monthly'})
                        </p>
                        {purchasesPackage.product.introPrice ? (
                          <p>
                            {purchasesPackage.product.introPrice.periodNumberOfUnits}{' '}
                            {purchasesPackage.product.introPrice.periodUnit.toLowerCase()} free trial!
                          </p>
                        ) : null}
                      </IonLabel>
                    </IonItem>
                  )
                })}
              </IonList>
            ) : !IS_MOBILE ? (
              <IonText color='light'>
                <h4 style={{ textAlign: 'center' }}>
                  {isPlus
                    ? 'You’re already subscribed to Dateability+. To manage your subscription, please use the mobile app store'
                    : 'Subscribe on IOS or Android'}
                </h4>
              </IonText>
            ) : null}
          </IonContent>
          <IonFooter className='ion-padding ion-margin-bottom'>
            <IonButton
              hidden={!IS_MOBILE}
              disabled={!isNumber(selectedIndex)}
              expand='full'
              fill='solid'
              onClick={async () => {
                if (offerings?.current?.availablePackages?.length && isNumber(selectedIndex)) {
                  await showLoading({
                    message: 'One moment...',
                    translucent: true
                  })

                  try {
                    const purchase = await Purchases.purchasePackage({
                      aPackage: offerings.current.availablePackages[selectedIndex]
                    })

                    onDismiss()

                    console.log('[revenuecat] purchasePackage result', purchase)
                  } catch (e) {
                    console.error('[revenuecat] purchasePackage error', e)
                  } finally {
                    await hideLoading()
                  }
                }
              }}
              shape='round'
            >
              Continue
            </IonButton>
            <div
              // className='ion-margin-bottom'
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 16 }}
            >
              <IonButton
                fill='clear'
                size='small'
                onClick={() =>
                  AppLauncher.openUrl({
                    url: 'https://info.dateabilityapp.com/terms-conditions'
                  })
                }
              >
                Terms
              </IonButton>
              <IonButton
                fill='clear'
                size='small'
                onClick={() =>
                  AppLauncher.openUrl({
                    url: 'https://info.dateabilityapp.com/privacy-policy'
                  })
                }
              >
                Privacy
              </IonButton>
            </div>
          </IonFooter>
        </>
      )}
    </IonPage>
  )
}
