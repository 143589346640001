import React, { useEffect, useState, useRef } from 'react'
import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonImg,
  IonLabel,
  IonList,
  IonPage,
  IonSearchbar,
  useIonRouter,
  IonInfiniteScroll,
  IonInfiniteScrollContent
} from '@ionic/react'
import styles from './MyLikes.module.scss'
import { useLocation } from 'react-router-dom'
import { chevronForward } from 'ionicons/icons'
import type { LikeStub } from '../../util/types'
import { timeSince } from '../../data/utils'
import { getAllLikes } from './services'
import NavBar from '../../components/Navbar/NavBar'
import ToggleMenu from '../../components/ToggleMenu'

const MyLikesSubscription: React.FC = () => {
  const containerRef = useRef<HTMLIonContentElement>(null)
  const infiniteScrollRef = useRef<HTMLIonInfiniteScrollElement>(null)
  const router = useIonRouter()
  const location = useLocation()
  const [likes, setLikes] = useState<LikeStub[]>([])
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [searchText, setSearchText] = useState('')

  const scrollToTop = () => {
    if (containerRef.current) {
      containerRef.current.scrollToTop()
    }
  }

  const getLikeData = (num, isNew, text) => {
    setPage(num)
    getAllLikes({ limit: 100, page: num, search: text }).then((res: any) => {
      if (res.data) {
        setTotal(res.data.total)
        const profiles: LikeStub[] = res.data.likes
        let newData
        if (isNew) {
          newData = [...profiles]
        } else {
          newData = [...likes, ...profiles]
        }

        setLikes(newData)
        infiniteScrollRef.current?.complete()
      }
    })
  }

  useEffect(() => {
    scrollToTop()
    if (location.pathname !== '/home/my_likes') {
      return
    }
    getLikeData(1, true, searchText)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const showDetails = (id) => {
    router.push(`/home/user/${id}/like`)
  }

  const fetchMoreData = (ev) => {
    if (likes.length <= total) {
      getLikeData(page + 1, false, searchText)
    }
  }

  const onSearch = (e: any) => {
    setSearchText(e.currentTarget.value)
    getLikeData(1, true, e.currentTarget.value)
  }
  return (
    <>
      <ToggleMenu contentId='likes' />
      <IonPage
        id='likes'
        className={styles.page}
      >
        <NavBar contentId='likes' />
        <IonContent ref={containerRef}>
          <h4>Likes</h4>
          <IonSearchbar
            role='search'
            aria-label='My Likes'
            placeholder='Search'
            onIonChange={onSearch}
            value={searchText}
          />
          <div
            aria-live='assertive'
            aria-atomic='true'
          >
            {likes.length > 0 && (
              <IonList id='scrollableDiv'>
                {likes.map((profile) => {
                  return (
                    <IonItem
                      role='link'
                      button
                      tabIndex={0}
                      id={`likeItem${profile.from_user.id}`}
                      className={` ${styles.likeItem} animate__animated animate__fadeIn`}
                      key={profile.from_user.id}
                      lines='none'
                      onClick={() => {
                        showDetails(profile.from_user.id)
                      }}
                      aria-label={`View profile of ${profile.from_user.first_name}`}
                    >
                      <IonImg
                        aria-hidden='true'
                        src={
                          profile.from_user.UserPhotos.length
                            ? profile.from_user.UserPhotos[0].photo_url
                            : '/assets/images/sarah-img.svg'
                        }
                        alt={profile.from_user.first_name}
                      />
                      <IonLabel>
                        <h2>
                          {`${profile.from_user.first_name}`}, {profile.from_user.UserInfo?.age}
                        </h2>
                        <p>{timeSince(new Date(profile.liked_on))}</p>
                      </IonLabel>
                      <IonButton
                        aria-hidden='true'
                        tabIndex={-1}
                        fill='clear'
                      >
                        <IonIcon
                          aria-label={`View ${profile.from_user.first_name}'s Profile`}
                          icon={chevronForward}
                        />
                      </IonButton>
                    </IonItem>
                  )
                })}
              </IonList>
            )}

            {likes.length === 0 && searchText.length > 0 && (
              <div className={styles.noResults}>
                <p>No likes found</p>
              </div>
            )}

            <IonInfiniteScroll
              ref={infiniteScrollRef}
              onIonInfinite={fetchMoreData}
            >
              <IonInfiniteScrollContent
                loadingSpinner='crescent'
                loadingText={'Loading..'}
              />
            </IonInfiniteScroll>
          </div>
        </IonContent>
      </IonPage>
    </>
  )
}

export default MyLikesSubscription
