import { IonInput, IonLabel } from '@ionic/react'
import { ShowablePasswordInput } from './ShowablePasswordInput'

import styles from './CustomField.module.scss'

const CustomField = ({ field, errors, child, autoCapitalize = 'off' }: any) => {
  const error = errors && errors.filter((e: any) => e.id === field.id)[0]
  const errorMessage = error && errors.filter((e: any) => e.id === field.id)[0].message

  return (
    <div className={styles.field}>
      <IonLabel className={styles.fieldLabel}>
        {field.label}
        {error && <p className='animate__animated animate__bounceIn'>{errorMessage}</p>}
      </IonLabel>

      {child ? (
        <IonInput
          className={styles.customInputChild}
          {...field.input.props}
          {...field.input.state}
          placeholder={field.placeholder}
          autoCapitalize={autoCapitalize}
        />
      ) : field.input.props.type === 'password' ? (
        <ShowablePasswordInput
          field={field}
          autoCapitalize={autoCapitalize}
        />
      ) : (
        <IonInput
          className={styles.customInput}
          {...field.input.props}
          {...field.input.state}
          placeholder={field.placeholder}
          autoCapitalize={autoCapitalize}
        />
      )}
    </div>
  )
}

export default CustomField
