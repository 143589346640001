import { IonContent, IonItem, IonLabel, IonPage } from '@ionic/react'
import { useEffect, useState } from 'react'
import BackBtn from '../../components/BackBtn'
import styles from './Safety.module.scss'
import { getResources } from './services'

const Safety = () => {
  const [safetyData, setSafetyData] = useState<any>([])

  useEffect(() => {
    getResources().then((res) => {
      let data = res.data?.sort((a, b) => a.ordinal - b.ordinal)
      setSafetyData(data)
    })
  }, [])

  return (
    <IonPage className={styles.faq}>
      <IonContent
        forceOverscroll={false}
        className={styles.content}
      >
        <IonItem
          lines='none'
          className={styles.headerwrap}
        >
          <BackBtn returnPath='/home/search' />
          <IonLabel className={styles.heading}>Safety & Resources</IonLabel>
        </IonItem>
        {/* <ul className={styles.gridlist}>
        <h4 className={styles.question}>Online Safety</h4>
        <li className={styles.answer}>Keep your personal information (last name, phone number, address, or any other identifying information) private until you are 100% comfortable sharing. </li>
        <li className={styles.answer}>Do not share details about your personal life (school, daily routine) until you know a person well. </li>
        <li className={styles.answer}>Never send money to a user. If a profile is asking for personal financial information, do not respond and immediately report the profile or send an email to <a href="mailto:support@dateabilityapp.com">support@dateabilityapp.com</a></li>
        <li className={styles.answer}>Be cognisant of people who are looking for a long-distance relationship.  If a person refuses to speak over the phone or use a video call, be cautious of their intentions.</li>
        <li className={styles.answer}>If a photo seems off, reverse image the photo using images.google.com </li>
        <li className={styles.answer}>Discriminatory or inapproproate language is not tolerated at Dateability.  Please report any profile using the report button, fill out a form, or email <a href="mailto:support@dateabilityapp.com">support@dateabilityapp.com</a>.</li>
        <h4 className={styles.question}>In-Person Safety</h4>
        <li className={styles.answer}>Meet in a public place for the first few dates.</li>
        <li className={styles.answer}>Tell your family and friends where you are going and use a location sharing app.</li>
        <li className={styles.answer}>Avoid pick up and drop off on the first few dates and meet at the date location.</li>
        <li className={styles.answer}>Substances impair your judgment.  Only accept drinks that are sealed or served from a bartender to prevent substances being slipped in your drinks.</li>
        <li className={styles.answer}>Trust your gut.  If you feel uncomfortable, end the date.  It is okay to be rude when it pertains to your safety. </li>
        <h4 className={styles.question}>Consent and Sexual Health Safety </h4>
        <li className={styles.answer}>Use protection.  Condoms prevent the transmission of most sexually transmitted infections, with the exception of Herpes and HPV.  Some STIs can be prevented with vaccination. </li>
        <li className={styles.answer}>Get tested regularly and ask your partner to do so as well. Disclose infections to your partner. </li>
        <li className={styles.answer}>All sexual activity must have verbal consent.  Consent can be withdrawn at any time during an encounter.  Regularly check in with your partner and immediately stop if your partner expresses discomfort. </li>
        <p className={styles.answer}>If you have a negative experience, please understand it is at no fault of your own.  Please reach out to a friend or family member for help, or visit one of these resources listed below.</p>
        <h4 className={styles.question}>Safety for Disabled Dating</h4>
        <li className={styles.answer}>Disclose details of your health when you become more comfortable and trusting of another user. While Dateability has a section called ‘Dateability Deets,’ it is always optional. Details beyond basic descriptors should be kept private once trust is built.</li>
        <h4 className={styles.question}>What is a devotee?</h4>
        <li className={styles.answer}>A devotee is a person who fetishizes disability. They are sexually attracted to a person’s disability (amputation, paralysis, etc.)</li>
        <li className={styles.answer}>Devotees that are predatory and do not respect consent are not welcome on Dateability and will be promptly removed from the app.</li>
        <li className={styles.answer}>At Dateability, we do not support non consensual devotee relationships. If you receive unwanted messages, please contact support immediately. Inappropriate conduct and/or harassment will lead to immediate deletion of user’s profile.</li>
        <h4 className={styles.question}>Resources</h4>
        <h5 className={styles.linkheading}>Suicide and Crisis Lifeline </h5>
        <div>
          <a target="_blank" href="https://www.samhsa.gov/find-help/988">www.samhsa.gov/find-help/988</a>
          <br />
          <a href="tel:988">988</a>
        </div>
        <br />
        <h5 className={styles.linkheading}>RAINN</h5>
        <div>
          <a target="_blank" href="https://www.rainn.org">www.rainn.org</a>
          <br />
          <a href="tel:+18006564673">800-656-HOPE (4673)</a>
        </div>
        <br />
        <h5 className={styles.linkheading}>Planned Parenthood </h5>
        <div>
          <a target="_blank" href="https://www.plannedparenthood.org">www.plannedparenthood.org</a>
          <br />
          <a href="tel:+18002307526">800-230-PLAN (7526)</a>
        </div>
        <br />
        <h5 className={styles.linkheading}>National Domestic Violence Hotline </h5>
        <div>
          <a target="_blank" href="https://www.thehotline.org">www.thehotline.org </a>
          <br />
          <a href="tel:+18007997233">800-799-SAFE (7233)</a>
        </div>
        <br />
        <h5 className={styles.linkheading}>National Human Trafficking Hotline </h5>
        <div>
          <a target="_blank" href="https://www.humantraffickinghotline.org">www.humantraffickinghotline.org </a>
          <br />
          <a href="tel:+18883737888">888-373-7888</a>
        </div>
        <br />
        <h5 className={styles.linkheading}>The Trevor Project </h5>
        <div>
          <a target="_blank" href="https://www.thetrevorproject.org">www.thetrevorproject.org </a>
          <br />
          <a href="tel:+18664887386">866-488-7386</a>
        </div>
        <br />
        <h5 className={styles.linkheading}>Trans Lifeline </h5>
        <div>
          <a target="_blank" href="https://www.translifeline.org">www.translifeline.org </a>
          <br />
          <a href="tel:+18775658860">877-565-8860</a>
        </div>
        <br />
        <h5 className={styles.linkheading}>National Suicide Prevention Lifeline  </h5>
        <div>
          <a target="_blank" href="https://suicidepreventionlifeline.org">https://suicidepreventionlifeline.org </a>
          <br />
          <a href="tel:+18002738255">800-273-8255</a>
        </div>
        <br />
        <h5 className={styles.linkheading}>ADA National Network </h5>
        <div>
          <a target="_blank" href="https://www.adata.org">www.adata.org </a>
          <br />
          <a href="tel:+18009494232">800-949-4232</a>
        </div>
        <br />
        <h5 className={styles.linkheading}>Respect Ability  </h5>
        <div><a target="_blank" href="https://www.respectability.org ">www.respectability.org  </a></div>
        <br />
        <h5 className={styles.linkheading}>iBelong!  </h5>
        <div><a target="_blank" href="https://www.iBelong.ca">www.iBelong.ca </a></div>
      </ul> */}
        <ul className={styles.gridlist}>
          {safetyData.map((item) => {
            return (
              <>
                <h4 className={styles.question}>{item.title}</h4>
                <li className={styles.answer}>
                  <div dangerouslySetInnerHTML={{ __html: item.description }} />
                </li>
              </>
            )
          })}
        </ul>
        <BackBtn returnPath='/home/search' />
      </IonContent>
    </IonPage>
  )
}
export default Safety
