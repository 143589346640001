import {
  IonButton,
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonLabel,
  IonPage,
  IonRow,
  useIonRouter,
  useIonToast
} from '@ionic/react'
import React, { useState } from 'react'
import HeaderText from '../../components/HeaderSection/HeaderText'
import GoBackBtn from '../../components/GoBackBtn'
import { ChangePassword } from './services'

import styles from './ChangePassword.module.scss'

const ChangePasswordComponent = () => {
  const [present] = useIonToast()
  const router = useIonRouter()
  const [fields, setFields] = useState<any>({})
  const [confirm, setConfirm] = useState('')
  const [passwordIsVisible, setPasswordIsVisible] = useState(false)

  const onUpdateDetails = (e) => {
    let currentFields = { ...fields }
    currentFields[e.target.name] = e.target.value
    setFields(currentFields)
  }
  const updatePassword = () => {
    if (confirm === fields.password) {
      ChangePassword(fields).then((res) => {
        if (res.response && res.response.data.err) {
          present({ message: res.response.data.msg, color: 'warning', duration: 2000 })
        } else {
          present({ message: 'Password reset success', color: 'success', duration: 2000 })
          router.goBack()
        }
      })
    } else {
      present({ message: 'New password and confirm password not matching', color: 'warning', duration: 2000 })
    }
  }
  return (
    <IonPage>
      <IonContent
        fullscreen
        color={'#222460'}
        className={styles.reset}
      >
        <IonGrid className='ion-padding'>
          <IonRow>
            <IonCol size='12'>
              <HeaderText title='Change Password' />
              <GoBackBtn />
            </IonCol>
          </IonRow>
          <IonRow className='ion-margin-top ion-padding-top'>
            <IonCol size='12'>
              <IonLabel>New Password</IonLabel>
              <IonInput
                className={styles.customInput}
                placeholder='New Password'
                name='password'
                type={passwordIsVisible ? 'text' : 'password'}
                value={fields.password}
                onIonChange={(e: any) => onUpdateDetails(e)}
              />
            </IonCol>
            <div className={styles.showPasswordContainer}>
              <IonCheckbox
                className={styles.showPasswordCheckbox}
                aria-labelledby='show-hide-pass'
                role='checkbox'
                color='success'
                title='Show password'
                onIonChange={() => setPasswordIsVisible(!passwordIsVisible)}
              ></IonCheckbox>
              <IonLabel
                id='show-hide-pass'
                className={styles.showPasswordLabel}
              >
                Show password
              </IonLabel>
            </div>
          </IonRow>
          <IonRow className='ion-margin-top ion-padding-top'>
            <IonCol size='12'>
              <IonLabel>Confirm Password</IonLabel>
              <IonInput
                className={styles.customInput}
                placeholder='Confirm Password'
                name='password'
                type={passwordIsVisible ? 'text' : 'password'}
                value={confirm}
                onIonChange={(e: any) => {
                  setConfirm(e.target.value)
                }}
              />
            </IonCol>
          </IonRow>
          <IonRow className='ion-margin-top ion-padding-top'>
            <IonCol size='12'>
              <IonButton
                disabled={!fields.password ? true : undefined}
                className={'styles.customButton'}
                expand='block'
                onClick={updatePassword}
              >
                Continue
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  )
}
export default ChangePasswordComponent
