import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  useIonToast
} from '@ionic/react'
import { useEffect, useRef, useState } from 'react'
import styles from './ToggleMenu.module.scss'
import Select from 'react-select'
import { AuthenticationApi } from '../services/apiUrls'
import { serviceHandler } from '../services/serviceHandler'
import { handleIfValidInteraction } from '../util/a11yHelper'

const ContactUsModal = ({ isOpen, setIsOpen, label }) => {
  const [present] = useIonToast()
  const modal = useRef<HTMLIonModalElement>(null)
  const [contactUsOption, setContactUsOption] = useState([])
  const [selectedCategory, setSelectedCategory] = useState<any>('')
  const [msg, setMsg] = useState('')

  function cancel() {
    modal.current?.dismiss()
    setIsOpen(false)
  }

  const handleOptChange = (selectedOption) => {
    setSelectedCategory(selectedOption)
  }

  const customStyles = {
    menu: (provided: any, state: any) => ({
      ...provided,
      color: '#0D0D0D',
      backgroundColor: '#F2F2F6'
    })
  }

  function confirm() {
    modal.current?.dismiss('', 'confirm')
    setIsOpen(false)
    serviceHandler({
      url: AuthenticationApi.ContactUs,
      methodType: 'post',
      payload: {
        type: selectedCategory?.label,
        message: msg
      }
    })
      .then((res) => {
        if (res.data) {
          present({ message: `Thank you. We will be in touch!`, color: 'success', duration: 1500 })
        }
      })
      .catch((err) => {})
  }

  // function onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {
  //   if (ev.detail.role === 'confirm') {
  //     // alert(`Hello, ${ev.detail.data}!`);
  //   }
  // }

  useEffect(() => {
    serviceHandler({
      url: AuthenticationApi.ContactUSOptions,
      methodType: 'get',
      payload: {}
    })
      .then((res) => {
        const { data } = res
        let option = data.options.map((opt) => {
          return {
            label: opt.name,
            value: opt.id
          }
        })
        setContactUsOption(option)
      })
      .catch((err) => {})
  }, [])

  // const options = [
  //   { label: 'General Inquiry', value: 'General Inquiry' },
  //   { label: 'Report Abuse', value: 'Report Abuse' },
  //   { label: 'Positive Feedback', value: 'Positive Feedback' },
  //   { label: 'Criticism', value: 'Criticism' },
  //   { label: 'Account Deletion Request', value: 'Account Deletion Request' }
  // ];

  const handleCancel = (e) => handleIfValidInteraction(e, cancel)

  const handleConfirm = (e) => handleIfValidInteraction(e, confirm)

  return (
    <IonModal
      aria-label='Contact us Modal'
      aria-modal='true'
      ref={modal}
      isOpen={isOpen}
      onDidDismiss={() => setIsOpen(false)}
    >
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonButton
              aria-label='Cancel message'
              role='button'
              tabIndex={0}
              fill='clear'
              color='dark'
              onClick={handleCancel}
              onKeyUp={handleCancel}
              className={styles.contactbutton}
            >
              Cancel
            </IonButton>
          </IonButtons>
          <IonButtons slot='end'>
            <IonButton
              aria-label='Send message'
              role='button'
              tabIndex={0}
              fill='clear'
              color='dark'
              strong={true}
              onClick={handleConfirm}
              onKeyUp={handleConfirm}
              className={styles.contactbutton}
            >
              Send
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className={styles.modelwrap}>
        <IonGrid>
          <IonRow>
            <IonCol size='12'>
              <Select
                tabIndex={0}
                autoFocus={true}
                aria-label='Select a contact us category'
                isSearchable={false}
                placeholder='Select category'
                options={contactUsOption}
                onChange={handleOptChange}
                // value={
                //   options.filter(option => option.label === label)
                // }
                styles={customStyles}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='12'>
              <textarea
                tabIndex={0}
                aria-label='Enter your message'
                className={styles.customtxt}
                rows={8}
                onChange={(e: any) => {
                  setMsg(e.target.value)
                }}
                placeholder='Enter message'
              ></textarea>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonModal>
  )
}

export default ContactUsModal
