import { IonButton, IonIcon, useIonRouter } from '@ionic/react'
import { arrowBackOutline } from 'ionicons/icons'

interface BackBtnProps {
  returnPath?: string
}

export default function BackBtn<BackBtnProps>({ returnPath, ...props }) {
  const router = useIonRouter()
  return (
    <IonButton
      fill='clear'
      role='link'
      onClick={() => {
        returnPath ? router.push(returnPath) : router.canGoBack() ? router.goBack() : router.push('/home/search')
      }}
      aria-label='Back'
      {...props}
    >
      <IonIcon
        aria-hidden='true'
        slot='start'
        icon={arrowBackOutline}
      />
    </IonButton>
  )
}
