import { IonButton, IonImg, useIonToast } from '@ionic/react'
import React, { useEffect, useState } from 'react'
import HeaderText from '../../../components/HeaderSection/HeaderText'
import styles from './YourInterests.module.scss'
import { intrestImages } from '../../../data/fields'
import { getIntrestData, saveIntrestData, intrestList } from '../services'
import GoBackBtn from '../../../components/GoBackBtn'
import { handleIfValidInteraction } from '../../../util/a11yHelper'

const YourInterests: React.FC<any> = ({ next, isEditing }) => {
  const [present] = useIonToast()
  let imageList: any = intrestImages
  const [list, setList] = useState<any>([])
  const [intrestOptions, setIntrestOptions] = useState<any>([])
  useEffect(() => {
    intrestList().then((intList: any) => {
      if (intList.data) {
        setIntrestOptions(intList.data.interests)
      }
    })
    getIntrestData().then((resp: any) => {
      if (resp.data) {
        let selectedList: any = []
        resp.data.map((intrestItem: any) => {
          selectedList.push(intrestItem.interest_id)
          return null
        })
        setList(selectedList)
      }
    })
  }, [])
  const saveIntrest = () => {
    saveIntrestData({ interests: list }).then((res: any) => {
      if (res) {
        next('deets')
      }
    })
  }
  const selectItem = (item: any) => {
    let newList = [...list]
    if (list.indexOf(item) < 0) {
      if (newList.length < 6) {
        newList.push(item)
      } else {
        present({ message: "You've selected 6 interests already!", color: 'primary', duration: 1000 })
      }
    } else {
      newList.splice(list.indexOf(item), 1)
    }
    setList(newList)
  }
  return (
    <div className='slide-main ion-padding'>
      <HeaderText
        title='Your Interests'
        label={`${isEditing ? 'EDIT' : 'CREATE'} YOUR PROFILE`}
        info='(Pick up to 6)'
      />
      <GoBackBtn />
      <div
        className={styles.listing}
        role='group'
        aria-label='Select your interests (up to 6)'
      >
        {intrestOptions.map((item: any) => {
          const isSelected = list.indexOf(item.id) > -1
          return (
            <div
              className={styles.iconwrapper}
              key={item.id}
            >
              <div
                tabIndex={0}
                role='switch'
                aria-checked={isSelected}
                aria-label={`Interest in ${item.name}`}
                className={list.indexOf(item.id) > -1 ? styles.intresticonsel : styles.intresticon}
                onClick={() => selectItem(item.id)}
                onKeyUp={(e) => handleIfValidInteraction(e, () => selectItem(item.id))}
              >
                <IonImg
                  aria-hidden='true'
                  alt={item.name}
                  src={isSelected ? imageList[item.id].icon2 : imageList[item.id].icon}
                />
              </div>
              <label
                aria-hidden='true'
                className={styles.iconname}
              >
                {item.name}
              </label>
            </div>
          )
        })}
      </div>
      <IonButton
        expand='block'
        aria-label='Save your interests'
        disabled={list.length < 1 ? true : undefined}
        className={styles.customButton}
        onClick={() => saveIntrest()}
      >
        {isEditing ? 'Save' : 'Continue'}
      </IonButton>
    </div>
  )
}

export default YourInterests
