import React from 'react'
import { connect } from '../data/connect'
import { Redirect } from 'react-router'

interface StateProps {
  hasSeenTutorial: boolean
}

const AuthGuard: React.FC<StateProps> = ({ hasSeenTutorial }) => {
  const token = localStorage.getItem('token')
  return token ? <Redirect to='/home' /> : hasSeenTutorial ? <Redirect to='/login' /> : <Redirect to='/login' />
}

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    hasSeenTutorial: state.user.hasSeenTutorial
  }),
  component: AuthGuard
})
