import { StatusBar, Style } from '@capacitor/status-bar'
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonImg,
  IonLabel,
  IonPage,
  IonTextarea,
  IonTitle,
  IonToolbar,
  useIonRouter,
  useIonViewWillLeave
} from '@ionic/react'
import dayjs from 'dayjs'
import debounce from 'lodash/debounce'
import { useEffect, useRef, useState } from 'react'
import { MessageList } from 'react-chat-elements'
import { useParams } from 'react-router'
import { Message, ProfileStub } from '../../util/types'
import styles from './Chat.module.scss'
import { getMessages, getProfileById, postMessage } from './services'

import { getSocket } from '../../services/socket'
import { IS_CAPACITOR } from '../../consts'

const ChatWindow = () => {
  const router = useIonRouter()
  const params = useParams<{ userId: string }>()
  const messagesListRef = useRef<HTMLDivElement | null>(null)
  const [messages, setMessages] = useState<Message[]>([])
  const [draftMessage, setDraftMessage] = useState('')
  const [fetchParams, setFetchParams] = useState({
    page: 1,
    loading: true
  })
  const [remoteUser, setRemoteUser] = useState<ProfileStub | null>(null)

  const fetchmessages = (page: number) => {
    setFetchParams({
      ...fetchParams,
      loading: true
    })
    getMessages({
      page: page,
      userId: params.userId
    })
      .then((res) => {
        if (!res.err && res.data) {
          const oldMessages = res.data.messages.reverse()
          if (page === 1) {
            setMessages([...oldMessages])
          } else {
            setMessages([...oldMessages, ...messages])
          }
        }
        setFetchParams({
          page,
          loading: false
        })
      })
      .catch((err) => {
        console.error(err)
        setFetchParams({
          page,
          loading: false
        })
      })
  }
  useIonViewWillLeave(() => {
    setFetchParams({
      page: 1,
      loading: true
    })
  })

  useEffect(() => {
    setMessages([])
    setRemoteUser(null)
    if (IS_CAPACITOR) {
      StatusBar.setStyle({ style: Style.Dark })
    }
    fetchmessages(1)
    getProfileById(params.userId).then((res) => {
      setRemoteUser(res.data)
      if (!res.data.is_match) {
        router.push('/home/matches')
      }
    })
    return () => {
      if (IS_CAPACITOR) {
        StatusBar.setStyle({ style: Style.Light })
      }
    }
  }, [params.userId])

  useEffect(() => {
    const socket = getSocket()
    const onMessage = (message: Message) =>
      setMessages((prev) => (message.from_user_id === params.userId ? [...prev, message] : prev))

    socket.on('message', onMessage)

    return () => {
      socket.off('message', onMessage)
    }
  }, [])

  const sendMessage = () => {
    const date = new Date().getTime()
    // @ts-ignore
    const newMessages = messages.concat({
      from_user_id: 'me',
      to_user_id: params.userId,
      message: draftMessage.trim(),
      sent_on: date,
      messageId: date
    })
    setMessages(newMessages)
    messagesListRef.current?.scrollIntoView({ behavior: 'smooth' })
    setTimeout(() => {
      setDraftMessage('')
    }, 0)

    postMessage({
      to_user_id: params.userId,
      message: draftMessage
    }).then((res) => {
      if (!res.err && res.data) {
        const updatedMessages = newMessages.map((m) => {
          if (m.messageId === date) {
            return res.data
          }
          return m
        })

        setMessages(updatedMessages)
      }
    })
  }

  const onScroll = () => {
    const top = messagesListRef.current?.scrollTop as number
    if (!fetchParams.loading && top < 100) {
      fetchmessages(fetchParams.page + 1)
    }
  }

  const handleScroll = debounce(onScroll, 400)

  const messageData = messages.map((m) => ({
    position: m.from_user_id === params.userId ? 'left' : 'right',
    text: m.message,
    type: 'text',
    date: dayjs(m.sent_on).toDate(),
    className: m.from_user_id === params.userId ? 'chat-left' : 'chat-right'
  }))

  return (
    <IonPage className={styles.chat}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonBackButton
              color='dark'
              text=''
              defaultHref='/home/matches'
            />
          </IonButtons>
          <IonTitle aria-label={`Chatting with ${remoteUser?.first_name}, ${remoteUser?.UserInfo?.age}`}>
            <div
              tabIndex={0}
              role='link'
              aria-label={`View ${remoteUser?.first_name}'s profile`}
              className={styles.user}
              onClick={() => router.push(`/home/user/${remoteUser?.id}`)}
            >
              <img
                aria-hidden='true'
                src={
                  remoteUser?.UserPhotos?.length ? remoteUser?.UserPhotos[0].photo_url : '/assets/images/sarah-img.svg'
                }
                alt=''
                className={!remoteUser?.UserPhotos?.length || !remoteUser?.UserPhotos[0].photo_url ? 'blurredImg' : ''}
              />
              <IonLabel>
                {remoteUser?.first_name}, {remoteUser?.UserInfo?.age}
              </IonLabel>
            </div>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <MessageList
          className={`message-list text-dark ${styles.messageList}`}
          // toBottomHeight="100%"
          lockable
          referance={messagesListRef}
          //  @ts-ignore
          dataSource={messageData}
          onScroll={handleScroll}
        />
      </IonContent>
      <IonFooter>
        <div className={styles.typearea}>
          <IonTextarea
            placeholder='Send a message'
            rows={1}
            className={styles.textarea}
            autoGrow
            autoCapitalize='on'
            onIonChange={(e) => setDraftMessage(e.target.value as string)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                sendMessage()
              }
            }}
            value={draftMessage}
          />
          <div
            tabIndex={0}
            className={styles.sendicon}
            role='button'
            onClick={sendMessage}
            aria-label='Send Message'
          >
            <IonImg
              alt='Send'
              src='/assets/images/send.svg'
            />
          </div>
        </div>
      </IonFooter>
    </IonPage>
  )
}
export default ChatWindow
