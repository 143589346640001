// support either mouse clicks or "Enter" and "Space" key events for a11y
export const isValidClickOrKeyUpEvent = (e) => {
  return e.type !== 'keyup' || e.keyCode === 13 || e.keyCode === 32
}

export const handleIfValidInteraction = (e, callback) => {
  if (isValidClickOrKeyUpEvent(e)) {
    e.preventDefault()
    callback()
  }
}
