import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonLabel,
  IonPage,
  IonRow,
  useIonRouter,
  useIonToast
} from '@ionic/react'
import React, { useState } from 'react'
import HeaderText from '../../components/HeaderSection/HeaderText'
import GoBackBtn from '../../components/GoBackBtn'
import styles from './Forgot.module.scss'
import { forgotPassword } from './services'

const ForgotPassword = () => {
  const [present] = useIonToast()
  const [email, setEmail] = useState('')
  const router = useIonRouter()

  const sendCode = () => {
    forgotPassword({ email: email }).then((res) => {
      if (res.response && res.response.data.err) {
        present({ message: res.response.data.msg, color: 'warning', duration: 2000 })
      } else {
        present({ message: 'Reset code sent to your email', color: 'success', duration: 2000 })
        router.push(`/reset?email=${encodeURIComponent(email)}`)
      }
    })
  }
  return (
    <IonPage>
      <IonContent
        fullscreen
        color={'#222460'}
        className={styles.forgot}
      >
        <IonGrid className='ion-padding'>
          <IonRow>
            <IonCol size='12'>
              <HeaderText
                title='Forgot Password'
                label='SIGN IN'
              />
              <GoBackBtn />
            </IonCol>
          </IonRow>
          <IonRow className='ion-margin-top ion-padding-top'>
            <IonCol size='12'>
              <IonLabel>Email</IonLabel>
              <IonInput
                className={styles.customInput}
                placeholder='Enter Email ID here'
                name='email'
                type='text'
                value={email}
                onIonChange={(e: any) => setEmail(e.target.value)}
              />
            </IonCol>
          </IonRow>
          <IonRow className='ion-margin-top ion-padding-top'>
            <IonCol size='12'>
              <IonButton
                disabled={!email ? true : undefined}
                className={styles.customButton}
                expand='block'
                onClick={sendCode}
              >
                Continue
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  )
}
export default ForgotPassword
