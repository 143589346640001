import React, { useEffect, useState, useRef } from 'react'
import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonImg,
  IonLabel,
  IonList,
  IonPage,
  useIonRouter,
  useIonModal
} from '@ionic/react'
import styles from './MyLikes.module.scss'
import { useLocation } from 'react-router-dom'
import { chevronForward } from 'ionicons/icons'
import type { LikeStub } from '../../util/types'
import { timeSince } from '../../data/utils'
import { getFreeLikes } from './services'
import NavBar from '../../components/Navbar/NavBar'
import ToggleMenu from '../../components/ToggleMenu'
import { AppLauncher } from '@capacitor/app-launcher'
import { useRevenueCatContext } from '../../contexts/RevenueCatContext'
import { PaywallModal } from '../../components/PaywallModal'

const MyLikesFree: React.FC = () => {
  const containerRef = useRef<HTMLIonContentElement>(null)
  const router = useIonRouter()
  const { isPlus, managementURL } = useRevenueCatContext()
  const location = useLocation()
  const [firstLike, setFirstLike] = useState<LikeStub>()
  const [likes, setLikes] = useState<[]>([])

  const [presentPaywallModal, dismissPaywallModal] = useIonModal(PaywallModal, {
    onDismiss: () => dismissPaywallModal(null, 'cancel')
  })
  const scrollToTop = () => {
    if (containerRef.current) {
      containerRef.current.scrollToTop()
    }
  }

  const getLikeData = () => {
    getFreeLikes().then((res: any) => {
      if (res.data) {
        setLikes(res.data.likes)
        setFirstLike(res.data.firstLike)
      } else {
        setLikes([])
        setFirstLike(undefined)
      }
    })
  }

  useEffect(() => {
    scrollToTop()
    if (location.pathname !== '/home/my_likes') {
      return
    }
    getLikeData()
  }, [location])

  const showDetails = (id) => {
    router.push(`/home/user/${id}/like`)
  }

  return (
    <>
      <ToggleMenu contentId='likes' />
      <IonPage
        id='likes'
        className={styles.page}
      >
        <NavBar contentId='likes' />
        <IonContent ref={containerRef}>
          <h4>Likes</h4>
          <div
            aria-live='assertive'
            aria-atomic='true'
          >
            {firstLike && (
              <IonList id='scrollableDiv'>
                <IonItem
                  role='link'
                  button
                  tabIndex={0}
                  id={`likeItem${firstLike.from_user?.id}`}
                  className={` ${styles.likeItem} animate__animated animate__fadeIn`}
                  key={firstLike.from_user.id}
                  lines='none'
                  onClick={() => {
                    showDetails(firstLike.from_user.id)
                  }}
                  aria-label={`View profile of ${firstLike.from_user.first_name}`}
                >
                  <IonImg
                    aria-hidden='true'
                    src={
                      firstLike.from_user.UserPhotos.length
                        ? firstLike.from_user.UserPhotos[0].photo_url
                        : '/assets/images/sarah-img.svg'
                    }
                    alt={firstLike.from_user.first_name}
                  />
                  <IonLabel>
                    <h2>
                      {`${firstLike.from_user.first_name}`}, {firstLike.from_user.UserInfo?.age}
                    </h2>
                    <p>{timeSince(new Date(firstLike.liked_on))}</p>
                  </IonLabel>
                  <IonButton
                    aria-hidden='true'
                    tabIndex={-1}
                    fill='clear'
                  >
                    <IonIcon
                      aria-label={`View ${firstLike.from_user.first_name}'s Profile`}
                      icon={chevronForward}
                    />
                  </IonButton>
                </IonItem>
                <IonItem
                  role='link'
                  button
                  id={`subscription`}
                  className={` ${styles.likeItem} animate__animated animate__fadeIn`}
                  key='subscription'
                  lines='none'
                  onClick={async () => {
                    try {
                      if (isPlus && managementURL) {
                        await AppLauncher.openUrl({
                          url: managementURL
                        })
                      } else {
                        presentPaywallModal()
                      }
                    } catch (e) {
                      console.error('[app-launcher] openUrl error', e)
                    }
                  }}
                  style={{ paddingTop: '18px' }}
                >
                  <IonIcon
                    aria-hidden='true'
                    src='/assets/images/rightarrow.svg'
                  />
                  <IonLabel>
                    <p>View all likes at once by</p>
                    <p>upgrading to Dateability+</p>
                  </IonLabel>
                </IonItem>
                {likes.length > 0 &&
                  likes.map((like: any) => (
                    <IonItem
                      role='link'
                      button
                      tabIndex={0}
                      id={`likeItem${like.liked_on}`}
                      className={` ${styles.likeItem} animate__animated animate__fadeIn`}
                      key={like.liked_on}
                      lines='none'
                      aria-label={`Liked on ${like.liked_on}`}
                    >
                      <IonImg
                        aria-hidden='true'
                        src={'/assets/images/DefaultAvatar.png'}
                      />
                      <IonLabel>
                        <h2>Locked</h2>
                        <p>Liked you {timeSince(new Date(like.liked_on))}</p>
                      </IonLabel>
                    </IonItem>
                  ))}
              </IonList>
            )}
            {likes.length === 0 && !firstLike && (
              <>
                <div className={styles.noResults}>
                  <p>No likes found</p>
                </div>
                <IonItem
                  role='link'
                  id={`subscription`}
                  className={` ${styles.likeItem} animate__animated animate__fadeIn`}
                  key='subscription'
                  lines='none'
                  onClick={async () => {
                    try {
                      if (isPlus && managementURL) {
                        await AppLauncher.openUrl({
                          url: managementURL
                        })
                      } else {
                        presentPaywallModal()
                      }
                    } catch (e) {
                      console.error('[app-launcher] openUrl error', e)
                    }
                  }}
                  style={{ paddingTop: '18px' }}
                >
                  <IonIcon
                    aria-hidden='true'
                    src='/assets/images/rightarrow.svg'
                  />
                  <IonLabel>
                    <p>Unlock new filters and functionality</p>
                    <p>by upgrading to Dateability+</p>
                  </IonLabel>
                </IonItem>
              </>
            )}
          </div>
        </IonContent>
      </IonPage>
    </>
  )
}

export default MyLikesFree
