import { serviceHandler } from '../../services/serviceHandler'
import { AuthenticationApi } from '../../services/apiUrls'

export interface NotificationCount {
  notification_type: string
  count: number
}

/**
 * Service handler between api/app
 *
 */
export function NotificationsUnreadCount(): Promise<{ data: [NotificationCount] }> {
  return serviceHandler({
    url: AuthenticationApi.NotificationsUnread,
    methodType: 'get'
  })
}
