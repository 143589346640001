import { serviceHandler } from '../../services/serviceHandler'
import { AuthenticationApi } from '../../services/apiUrls'

const { Login } = AuthenticationApi

export const loginService = (payload) => {
  return serviceHandler({
    url: Login,
    methodType: 'post',
    payload
  })
}
