import { serviceHandler } from '../../services/serviceHandler'
import { AuthenticationApi, likeApi, profileApi } from '../../services/apiUrls'
import { isEmpty } from 'lodash'

const { Match } = AuthenticationApi

export const getAllMatches = (payload) => {
  return serviceHandler({
    url: Match,
    methodType: 'get',
    payload
  })
}

export const getAllProfiles = (page = 1, limit = 20) => {
  let filterString = `?limit=${limit}&page=${page}`

  const childPreference = localStorage.getItem('childPreference')
  const deets = localStorage.getItem('deets')
  const education = localStorage.getItem('education')
  const interests = localStorage.getItem('interests')
  const politicalViews = localStorage.getItem('politicalViews')
  const relationship = localStorage.getItem('relationship')
  const religion = localStorage.getItem('religion')
  const vaccinationStatus = localStorage.getItem('vaccinationStatus')
  const verified = localStorage.getItem('verified')

  if (!isEmpty(childPreference)) {
    filterString += `&childPreference=${childPreference}`
  }
  if (!isEmpty(deets)) {
    filterString += `&deets=${deets}`
  }
  if (!isEmpty(education)) {
    filterString += `&education=${education}`
  }
  if (!isEmpty(interests)) {
    filterString += `&interests=${interests}`
  }
  if (!isEmpty(politicalViews)) {
    filterString += `&politicalViews=${politicalViews}`
  }
  if (!isEmpty(relationship)) {
    filterString += `&relationship=${relationship}`
  }
  if (!isEmpty(religion)) {
    filterString += `&religion=${religion}`
  }
  if (!isEmpty(vaccinationStatus)) {
    filterString += `&vaccinationStatus=${vaccinationStatus}`
  }
  if (!isEmpty(verified)) {
    filterString += `&verified=${verified}`
  }

  return serviceHandler({
    url: `${profileApi}${filterString}`,
    methodType: 'get'
  })
}

export const likeProfile = (payload) =>
  serviceHandler({
    url: likeApi,
    methodType: 'post',
    payload
  })

export const dislikeProfile = ({ to_user_id }) =>
  serviceHandler({
    url: `${likeApi}/${to_user_id}`,
    methodType: 'delete'
  })
