import { serviceHandler } from '../../services/serviceHandler'
import { AuthenticationApi } from '../../services/apiUrls'

const { Match } = AuthenticationApi

export const getAllMatches = ({ limit, page, search }) => {
  return serviceHandler({
    url: `${Match}?findAll=${false}&limit=${limit}&page=${page}&searchKey=name&searchValue=${search}`,
    methodType: 'get'
  })
}
