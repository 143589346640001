import { serviceHandler } from '../../services/serviceHandler'
import { AuthenticationApi } from '../../services/apiUrls'

const { Forgotpassword } = AuthenticationApi

export const forgotPassword = (payload) => {
  return serviceHandler({
    url: Forgotpassword,
    methodType: 'post',
    payload
  })
}
