import { serviceHandler } from '../../services/serviceHandler'
import { AuthenticationApi } from '../../services/apiUrls'

const { OwnProfile, GetUser } = AuthenticationApi

export const getUser = (payload) => {
  return serviceHandler({
    url: OwnProfile,
    methodType: 'get',
    payload
  })
}
export const updateUser = (payload) => {
  return serviceHandler({
    url: GetUser,
    methodType: 'put',
    payload
  })
}
