import React, { useCallback, useEffect, useState } from 'react'
import {
  IonButton,
  IonContent,
  IonLoading,
  IonGrid,
  IonItem,
  IonLabel,
  IonPage,
  IonRange,
  IonToggle,
  IonInput,
  useIonRouter,
  IonCol,
  IonRow,
  useIonModal,
  useIonToast
} from '@ionic/react'
import { AppLauncher } from '@capacitor/app-launcher'
import { PaywallModal } from '../../components/PaywallModal'
import styles from './FilterOptions.module.scss'
import './alertmodule.scss'
import { saveSettings, getSettings } from './services'
import BackBtn from '../../components/BackBtn'
import { deetList, fetchUserInfoOptions, intrestList } from '../Onboarding/services'
import { filter, findIndex, includes, isBoolean, join, map, sortBy, split } from 'lodash'
import Select from 'react-select'
import { useRevenueCatContext } from '../../contexts/RevenueCatContext'

interface DeetRecordChoice {
  id: number
  name: string
}
interface SelectOption {
  label: string
  value: number
}

const OtherDeetID = 24

const customStyles = {
  option: (provided) => ({
    ...provided,
    color: 'black'
  }),
  control: (provided) => ({
    ...provided,
    color: 'black'
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'black'
  })
}
const mapSelectOptions = (options) =>
  map(options, (option) => ({
    label: option.name || option.label,
    value: (option.id as number) || (option.value as number)
  }))
const FilterOptions: React.FC = () => {
  const router = useIonRouter()
  const { isPlus, managementURL } = useRevenueCatContext()
  const [present] = useIonToast()

  const [loading, setLoading] = useState(false)
  const [minError, setMinError] = useState('')

  // settings
  const [maximumDistance, setMaximumDistance] = useState<any>(100)
  const [minimumAge, setMinimumAge] = useState<any>(18)
  const [maximumAge, setMaximumAge] = useState<any>(100)
  // filter options
  const [deetOptions, setDeetOptions] = useState<SelectOption[]>([])
  const [interestOptions, setInterestOptions] = useState<SelectOption[]>([])
  const [educationOptions, setEducationOptions] = useState<SelectOption[]>([])
  const [childPreferenceOptions, setChildPreferenceOptions] = useState<SelectOption[]>([])
  const [politicalViewsOptions, setPoliticalViewsOptions] = useState<SelectOption[]>([])
  const [relationshipOptions, setRelationshipOptions] = useState<SelectOption[]>([])
  const [religionOptions, setReligionOptions] = useState<SelectOption[]>([])
  const [vaccinationStatusOptions, setVaccinationStatusOptions] = useState<SelectOption[]>([])
  const [verified, setVerified] = useState<boolean>(false)
  // selected options
  const [selectedDeets, setSelectedDeets] = useState<SelectOption[]>([])
  const [selectedInterests, setSelectedInterests] = useState<SelectOption[]>([])
  const [selectedEducation, setSelectedEducation] = useState<SelectOption[]>([])
  const [selectedChildPreference, setSelectedChildPreference] = useState<SelectOption[]>([])
  const [selectedPoliticalViews, setSelectedPoliticalViews] = useState<SelectOption[]>([])
  const [selectedRelationship, setSelectedRelationship] = useState<SelectOption[]>([])
  const [selectedReligion, setSelectedReligion] = useState<SelectOption[]>([])
  const [selectedVaccinationStatus, setSelectedVaccinationStatus] = useState<SelectOption[]>([])

  const [presentPaywallModal, dismissPaywallModal] = useIonModal(PaywallModal, {
    onDismiss: () => dismissPaywallModal(null, 'cancel')
  })
  const getDetails = async () => {
    const settings = await getSettings()
    if (settings.data) {
      let settingsData = { ...settings.data }
      setMaximumDistance(settingsData.max_distance_range_end || 0)
      setMaximumAge(settingsData.age_range_end + '')
      setMinimumAge(settingsData.age_range_start + '')
    }

    // we want to
    if (isPlus) {
      setLoading(true)
      const deets = await deetList()
      const interests = await intrestList()
      const infoOptions = await fetchUserInfoOptions()

      if (deets.data) {
        let sortedDeets: DeetRecordChoice[] = sortBy(deets.data.deets, 'name')
        let otherIndex = findIndex(sortedDeets, (item) => item.id === OtherDeetID)
        let otherDeet = sortedDeets.splice(otherIndex, 1)
        sortedDeets.push(otherDeet[0])
        let formattedDeetOptions = mapSelectOptions(sortedDeets)

        setDeetOptions(formattedDeetOptions)
        setSelectedDeets(
          filter(formattedDeetOptions, (deet) =>
            includes(split(localStorage.getItem('deets'), ','), deet.value.toString())
          )
        )
      }
      if (interests.data) {
        let formattedInterestOptions = mapSelectOptions(interests.data.interests)
        setInterestOptions(formattedInterestOptions)
        setSelectedInterests(
          filter(formattedInterestOptions, (interest) =>
            includes(split(localStorage.getItem('interests'), ','), interest.value.toString())
          )
        )
      }
      if (infoOptions.data) {
        const {
          childPreferenceOptions,
          educationOptions,
          politicalViewOptions,
          relationshipOptions,
          religionOptions,
          vaccinatedStatusOptions
        } = infoOptions.data
        setChildPreferenceOptions(mapSelectOptions(childPreferenceOptions))
        setEducationOptions(mapSelectOptions(educationOptions))
        setPoliticalViewsOptions(mapSelectOptions(politicalViewOptions))
        setRelationshipOptions(mapSelectOptions(relationshipOptions))
        setReligionOptions(mapSelectOptions(religionOptions))
        setVaccinationStatusOptions(mapSelectOptions(vaccinatedStatusOptions))
      }
    }
    localStorage.setItem('isUpdated', '')
    setLoading(false)
  }

  const resetSelectedOptions = () => {
    setSelectedChildPreference([])
    setSelectedDeets([])
    setSelectedEducation([])
    setSelectedInterests([])
    setSelectedPoliticalViews([])
    setSelectedRelationship([])
    setSelectedReligion([])
    setSelectedVaccinationStatus([])
    setVerified(false)

    localStorage.removeItem('childPreference')
    localStorage.removeItem('deets')
    localStorage.removeItem('education')
    localStorage.removeItem('interests')
    localStorage.removeItem('politicalViews')
    localStorage.removeItem('relationship')
    localStorage.removeItem('religion')
    localStorage.removeItem('vaccinationStatus')
    localStorage.removeItem('verified')
  }

  useEffect(() => {
    getDetails()
    if (isBoolean(isPlus) && !isPlus) {
      resetSelectedOptions()
      onSave(false)
    }
  }, [isPlus])

  const updateMinAge = (e) => {
    const maxAgeNumber = Number(maximumAge)
    const minAgeNumber = Number(e.target.value)

    setMinimumAge(minAgeNumber)

    if (minAgeNumber > maxAgeNumber) {
      setMinError('MIN_BELOW_MAX')
    } else if (minAgeNumber <= 17 || maxAgeNumber <= 17) {
      setMinError('MIN_ABOVE_17')
    } else if (minAgeNumber > 100) {
      setMinError('MAX_BELOW_100')
    } else {
      setMinError('')
    }
  }

  const updateMaxAge = (e) => {
    const minAgeNumber = Number(minimumAge)
    const maxAgeNumber = Number(e.target.value)

    setMaximumAge(maxAgeNumber)

    if (minAgeNumber > maxAgeNumber) {
      setMinError('MIN_BELOW_MAX')
    } else if (maxAgeNumber <= 17 || minimumAge <= 17) {
      setMinError('MIN_ABOVE_17')
    } else if (maxAgeNumber > 100) {
      setMinError('MAX_BELOW_100')
    } else {
      setMinError('')
    }
  }

  const onSave = useCallback(
    async (showToast = true) => {
      localStorage.setItem(
        'childPreference',
        join(
          map(selectedChildPreference, (childPreference) => childPreference.value),
          ','
        )
      )
      localStorage.setItem(
        'deets',
        join(
          map(selectedDeets, (deet) => deet.value),
          ','
        )
      )
      localStorage.setItem(
        'education',
        join(
          map(selectedEducation, (education) => education.value),
          ','
        )
      )
      localStorage.setItem(
        'interests',
        join(
          map(selectedInterests, (interest) => interest.value),
          ','
        )
      )
      localStorage.setItem(
        'politicalViews',
        join(
          map(selectedPoliticalViews, (politicalView) => politicalView.value),
          ','
        )
      )
      localStorage.setItem(
        'relationship',
        join(
          map(selectedRelationship, (relationship) => relationship.value),
          ','
        )
      )
      localStorage.setItem(
        'religion',
        join(
          map(selectedReligion, (religion) => religion.value),
          ','
        )
      )
      localStorage.setItem(
        'vaccinationStatus',
        join(
          map(selectedVaccinationStatus, (vaccinationStatus) => vaccinationStatus.value),
          ','
        )
      )
      localStorage.setItem('verified', verified ? 'true' : 'false')

      await saveSettings({
        age_range_start: parseInt(minimumAge),
        age_range_end: parseInt(maximumAge),
        max_distance_range_end: parseInt(maximumDistance) || null
      }).then(async (res) => {
        if (res.data) {
          localStorage.setItem('isUpdated', 'true')
          if (showToast) {
            await present({
              message: 'Filter options saved successfully!',
              color: 'success',
              duration: 2000
            })
          }
        }
      })
    },
    [
      selectedChildPreference,
      selectedDeets,
      selectedEducation,
      selectedInterests,
      selectedPoliticalViews,
      selectedRelationship,
      selectedReligion,
      selectedVaccinationStatus,
      verified,
      minimumAge,
      maximumAge,
      maximumDistance
    ]
  )
  const onChangeVisibility = (isChecked) => {
    if (isChecked) {
      setMaximumDistance(50)
    } else {
      setMaximumDistance(null)
    }
  }

  return (
    <IonPage className={styles.settings}>
      <IonContent
        forceOverscroll={false}
        className={styles.content}
      >
        <IonItem
          lines='none'
          className={styles.headerwrap}
        >
          <BackBtn
            aria-label='Back to search page'
            returnPath='/home/search'
            onClick={() => {
              getDetails()
              // navigate to search
              router.push('/home/search')
            }}
          />
          <IonLabel className={styles.heading}>Filter Options</IonLabel>
        </IonItem>

        <IonGrid className={styles.gridlist}>
          <div className={styles.dropdown}>
            <IonLabel
              id='label_location_filter'
              className={styles.distanceHeader}
              style={{
                fontWeight: 'bold'
              }}
            >
              Search Users By:
            </IonLabel>
            {maximumDistance === null && (
              <p className={styles.infotext}>
                The distance filter functionality is currently disabled to give you the best experience. You can enable
                it by toggling it to the right, but please note it may limit your results.
              </p>
            )}
            <div className={styles.distanceHeader}>
              <IonLabel
                id='label_location_filter'
                className={styles.distanceLabel}
                style={{
                  fontWeight: 'normal'
                }}
              >
                Location Filter
              </IonLabel>
              <IonToggle
                aria-labelledby='label_location_filter'
                checked={maximumDistance !== null && maximumDistance > 0}
                onClick={(e: any) => {
                  onChangeVisibility(e.target.checked)
                }}
              />
            </div>
            {maximumDistance !== null && maximumDistance > 0 && (
              <div className={styles.distanceHeader}>
                <IonRange
                  aria-label='Distance range in miles'
                  defaultValue={maximumDistance || 1}
                  value={maximumDistance || 1}
                  min={1}
                  onIonChange={(e) => setMaximumDistance(e.detail.value)}
                ></IonRange>
                <IonInput
                  className={styles.customInput}
                  aria-label='Manually set range in miles'
                  placeholder='Enter Distance'
                  name='distance'
                  id='distance_field'
                  type='text'
                  value={maximumDistance}
                  onIonChange={(e) => setMaximumDistance(e.detail.value)}
                />
              </div>
            )}
          </div>
          <div className={styles.dropdown}>
            <IonLabel
              style={{
                fontWeight: 'normal'
              }}
            >
              Age Range
            </IonLabel>
            <div className={styles.ageRange}>
              <div className={styles.ageSection}>
                <div className={styles.ageLabel}>Minimum age:</div>
                <IonInput
                  className={styles.customInput}
                  aria-label='Minimum age in years'
                  placeholder='Min Age'
                  name='age_min'
                  id='min_age_field'
                  type='number'
                  inputMode='numeric'
                  min={'18'}
                  value={minimumAge}
                  onIonChange={useCallback((e) => updateMinAge(e), [])}
                />
              </div>
              <div className={styles.ageSection}>
                <div className={styles.ageLabel}>Maximum age:</div>
                <IonInput
                  className={styles.customInput}
                  placeholder='Max Age'
                  aria-label='Maximum age in years'
                  name='age_max'
                  id='max_age_field'
                  min={'18'}
                  type='number'
                  inputMode='numeric'
                  value={maximumAge}
                  onIonChange={useCallback((e) => updateMaxAge(e), [])}
                />
              </div>
            </div>
            {minError === 'MIN_BELOW_MAX' && (
              <div className={styles.minAgeError}> Minimum age must be less than the maximum age</div>
            )}
            {minError === 'MIN_ABOVE_17' && <div className={styles.minAgeError}> Minimum age is at least 18</div>}
            {minError === 'MAX_BELOW_100' && (
              <div className={styles.minAgeError}> Maximum age must be 100 or below</div>
            )}
          </div>
          <IonButton
            hidden={isPlus}
            role='link'
            id='subscription'
            className={` ${styles.likeItem} animate__animated animate__fadeIn`}
            style={{
              display: 'flex',
              margin: '12px 12px'
            }}
            key='subscription'
            onClick={async () => {
              try {
                if (isPlus && managementURL) {
                  await AppLauncher.openUrl({
                    url: managementURL
                  })
                } else {
                  presentPaywallModal()
                }
              } catch (e) {
                console.error('[app-launcher] openUrl error', e)
              }
            }}
          >
            Dateability+ Filters
          </IonButton>
          <IonRow>
            <IonItem>
              <IonLabel>Verified</IonLabel>
              <IonToggle
                checked={verified}
                disabled={!isPlus}
                onIonChange={useCallback((e) => setVerified(e.detail.checked), [])}
              />
            </IonItem>
          </IonRow>
          <IonRow>
            <IonCol
              size='12'
              style={{ color: 'black' }}
            >
              <IonLabel>Interests</IonLabel>
              <Select
                isDisabled={!isPlus}
                autoFocus={true}
                aria-label='Interests'
                isSearchable={false}
                placeholder='No Preference'
                // @todo - this works but i'm unsure why it complains
                // @ts-ignore - this is an issue with the library
                options={interestOptions}
                isMulti
                onChange={useCallback((selectedOption) => setSelectedInterests([...selectedOption]), [])}
                value={selectedInterests}
                styles={customStyles}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol
              size='12'
              style={{ color: 'black' }}
            >
              <IonLabel>Dateability Deets</IonLabel>
              <Select
                isDisabled={!isPlus}
                autoFocus={true}
                aria-label='Deets'
                isSearchable={false}
                placeholder='No Preference'
                // @todo - this works but i'm unsure why it complains
                // @ts-ignore - this is an issue with the library
                options={deetOptions}
                isMulti
                onChange={useCallback((selectedOption) => setSelectedDeets([...selectedOption]), [])}
                value={selectedDeets}
                styles={customStyles}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol
              size='12'
              style={{ color: 'black' }}
            >
              <IonLabel>Education</IonLabel>
              <Select
                isDisabled={!isPlus}
                autoFocus={true}
                aria-label='Education'
                isSearchable={false}
                placeholder='No Preference'
                options={educationOptions}
                isMulti
                onChange={useCallback((selectedOption) => setSelectedEducation([...selectedOption]), [])}
                value={selectedEducation}
                styles={customStyles}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol
              size='12'
              style={{ color: 'black' }}
            >
              <IonLabel>Religion</IonLabel>
              <Select
                isDisabled={!isPlus}
                autoFocus={true}
                aria-label='Religion'
                isSearchable={false}
                placeholder='No Preference'
                options={religionOptions}
                isMulti
                onChange={useCallback((selectedOption) => setSelectedReligion([...selectedOption]), [])}
                value={selectedReligion}
                styles={customStyles}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol
              size='12'
              style={{ color: 'black' }}
            >
              <IonLabel>Political Views</IonLabel>
              <Select
                isDisabled={!isPlus}
                autoFocus={true}
                aria-label='Political Views'
                isSearchable={false}
                placeholder='No Preference'
                options={politicalViewsOptions}
                isMulti
                onChange={useCallback((selectedOption) => setSelectedPoliticalViews([...selectedOption]), [])}
                value={selectedPoliticalViews}
                styles={customStyles}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol
              size='12'
              style={{ color: 'black' }}
            >
              <IonLabel>Vaccination Status</IonLabel>
              <Select
                isDisabled={!isPlus}
                autoFocus={true}
                aria-label='Vaccination Status'
                isSearchable={false}
                placeholder='No Preference'
                options={vaccinationStatusOptions}
                isMulti
                onChange={useCallback((selectedOption) => setSelectedVaccinationStatus([...selectedOption]), [])}
                value={selectedVaccinationStatus}
                styles={customStyles}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol
              size='12'
              style={{ color: 'black' }}
            >
              <IonLabel>Child Preferences</IonLabel>
              <Select
                isDisabled={!isPlus}
                autoFocus={true}
                aria-label='Child Preferences'
                isSearchable={false}
                placeholder='No Preference'
                options={childPreferenceOptions}
                isMulti
                onChange={useCallback((selectedOption) => setSelectedChildPreference([...selectedOption]), [])}
                value={selectedChildPreference}
                styles={customStyles}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol
              size='12'
              style={{ color: 'black' }}
            >
              <IonLabel>Looking For</IonLabel>
              <Select
                isDisabled={!isPlus}
                autoFocus={true}
                aria-label='Relationship'
                isSearchable={false}
                placeholder='No Preference'
                options={relationshipOptions}
                isMulti
                onChange={useCallback((selectedOption) => setSelectedRelationship([...selectedOption]), [])}
                value={selectedRelationship}
                styles={customStyles}
              />
            </IonCol>
          </IonRow>
          <IonButton
            fill='outline'
            onClick={onSave}
            color='primary'
            expand='block'
          >
            Save & Apply
          </IonButton>
        </IonGrid>
        {loading && <IonLoading isOpen={true} />}
      </IonContent>
    </IonPage>
  )
}

export default FilterOptions
