import { IonButton, IonItem, IonIcon, useIonRouter } from '@ionic/react'
import { arrowBackOutline } from 'ionicons/icons'

export default function GoBackBtn() {
  const router = useIonRouter()
  return (
    <IonItem lines='none'>
      <IonButton
        fill='clear'
        onClick={() => {
          router.goBack()
        }}
      >
        <IonIcon
          aria-hidden='true'
          slot='start'
          icon={arrowBackOutline}
        />{' '}
        Go Back
      </IonButton>
    </IonItem>
  )
}
