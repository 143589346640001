import { useEffect, useState } from 'react'
import { IonContent, IonIcon, IonImg, IonItem, IonLabel, IonList, IonPage, useIonRouter } from '@ionic/react'
import {
  rocketOutline,
  thumbsUpOutline,
  heartOutline,
  chatbubbleOutline,
  alertOutline,
  notificationsOutline
} from 'ionicons/icons'
import NavBar from '../../components/Navbar/NavBar'
import styles from './Notifications.module.scss'
import { timeSince } from '../../data/utils'
import { fetchNotificationsJoinedDate } from '../Onboarding/services'
import notifyInst from '../singleTon/notifyInst'
import ViewAnnounce from './viewAnnounce'
import { handleIfValidInteraction } from '../../util/a11yHelper'

type Notification = {
  id: string
  title: string
  message: string
  notification_time: string
  notification_type:
    | 'WELCOME'
    | 'LIKE'
    | 'MATCH'
    | 'MESSAGE'
    | 'ANNOUNCEMENT'
    | 'VERIFICATION_REQUEST'
    | 'ADMIN_MESSAGE'
  user_id: string
}

const Notifications = () => {
  const router = useIonRouter()
  const [notifications, setNotifications] = useState<Notification[]>([])
  notifyInst.setPropertyValue('hasnotify', false)
  useEffect(() => {
    fetchNotificationsJoinedDate().then((notifications) => {
      if (notifications && notifications.data) {
        setNotifications(notifications.data.notifications)
      }
    })
  }, [])

  const switchNotificationType = (type) => {
    switch (type) {
      case 'LIKE':
        return thumbsUpOutline
      case 'MATCH':
        return heartOutline
      case 'MESSAGE':
        return chatbubbleOutline
      case 'WELCOME':
        return rocketOutline
      case 'ADMIN_MESSAGE':
        return './images/dlogo.png'
      default:
        return notificationsOutline
    }
  }

  const navigateTo = (e, notification) =>
    handleIfValidInteraction(e, () => {
      if (notification.notification_type === 'LIKE') {
        router.push('/home/my_likes')
      } else if (notification.notification_type === 'MATCH' || notification.notification_type === 'MESSAGE') {
        router.push('/home/matches')
      } else if (notification.notification_type === 'VERIFICATION_REQUEST') {
        router.push('/verify')
      }
    })

  return (
    <>
      <IonPage
        id='notifications'
        className={styles.page}
      >
        <NavBar contentId='notifications' />
        <IonContent>
          <IonList>
            {notifications.map((notification) => {
              if (notification.notification_type === 'ANNOUNCEMENT') {
                return (
                  <IonItem
                    id={`notification_${notification.id}`}
                    key={notification.id}
                    lines='none'
                    className={` ${styles.announceItem} animate__animated animate__fadeIn`}
                  >
                    <IonImg
                      alt='Dateability Announcement'
                      src='assets/images/dlogo.png'
                    />
                    <IonLabel className='annouce_wrap'>
                      <h3 className={styles.verifyTitle}>{notification.title}</h3>
                      <p className={styles.notificationMessage}>
                        <ViewAnnounce message={notification.message} />
                      </p>
                    </IonLabel>
                    <IonImg
                      aria-hidden='true'
                      src='assets/images/wave.png'
                    />
                  </IonItem>
                )
              } else if (notification.notification_type === 'VERIFICATION_REQUEST') {
                return (
                  <IonItem
                    id={`notification_${notification.id}`}
                    key={notification.id}
                    lines='none'
                    className={` ${styles.announceItem} animate__animated animate__fadeIn`}
                  >
                    <IonImg
                      alt='Verification Request'
                      src='assets/images/dlogo.png'
                    />
                    <IonLabel
                      role='link'
                      tabIndex={0}
                      onKeyUp={(e) => navigateTo(e, notification)}
                      onClick={(e) => navigateTo(e, notification)}
                    >
                      <h3 className={styles.verifyTitle}>
                        You have a profile verification request -{' '}
                        <span className={styles.verifylink}>Click to Verify</span>
                      </h3>
                      <p className={styles.notificationMessage}>
                        {timeSince(new Date(notification.notification_time))}
                      </p>
                    </IonLabel>
                    <IonIcon
                      aria-hidden='true'
                      size='large'
                      className={styles.exclaim}
                      icon={alertOutline}
                    />
                  </IonItem>
                )
              } else if (notification.notification_type === 'ADMIN_MESSAGE') {
                return (
                  <IonItem
                    id={`notification_${notification.id}`}
                    key={notification.id}
                    lines='none'
                    className={` ${styles.notificationItem} animate__animated animate__fadeIn`}
                  >
                    <IonImg
                      alt='Message from admin'
                      src='assets/images/dlogo.png'
                    />
                    <IonLabel>
                      <h2 className={styles.notificationTitle}>{notification.message}</h2>
                      <p className={styles.notificationMessage}>
                        {timeSince(new Date(notification.notification_time))}
                      </p>
                    </IonLabel>
                  </IonItem>
                )
              }
              return (
                <IonItem
                  id={`notification_${notification.id}`}
                  key={notification.id}
                  lines='none'
                  className={` ${styles.notificationItem} animate__animated animate__fadeIn`}
                >
                  <IonIcon
                    aria-label={notification.notification_type}
                    color='primary'
                    icon={switchNotificationType(notification.notification_type)}
                  />
                  <IonLabel
                    tabIndex={0}
                    role='link'
                    onKeyUp={(e) => navigateTo(e, notification)}
                    onClick={(e) => navigateTo(e, notification)}
                  >
                    <h2 className={styles.notificationTitle}>{notification.message}</h2>
                    <p className={styles.notificationMessage}>{timeSince(new Date(notification.notification_time))}</p>
                  </IonLabel>
                </IonItem>
              )
            })}
          </IonList>
        </IonContent>
      </IonPage>
    </>
  )
}
export default Notifications
