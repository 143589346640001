import { Component } from 'react'
import { IonContent, IonLoading, IonPage, IonButton, IonIcon, IonLabel } from '@ionic/react'
// import AdSense from 'react-adsense';
import styles from './search.module.scss'
import ToggleMenu from '../../components/ToggleMenu'
import { dislikeProfile, getAllProfiles, likeProfile } from './services'
import { ProfileStub } from '../../util/types'
import NavBar from '../../components/Navbar/NavBar'
import MatchModal from '../../components/MatchModal'
import SwipeCard from '../../components/SwipeCard'
import { withRouter } from 'react-router'
import _ from 'lodash'
import { filterOutline, refreshOutline } from 'ionicons/icons'

const PAGE_LIMIT = 20

class Search extends Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      removedProfile: false,
      currentPageInfo: {
        page: 0,
        total: 0
      },
      endOfSearch: false,
      profiles: [],
      matchedProfile: null
    }
  }

  componentDidMount(): void {
    let pageNum: number = 1
    if (localStorage.getItem('swipepageno')) {
      pageNum = Number(localStorage.getItem('swipepageno'))
    }
    this.fetchProfiles(pageNum)
  }

  componentDidUpdate(prevProps: Readonly<any>): void {
    const isUpdated = localStorage.getItem('isUpdated') === 'true'
    const pathname = prevProps?.location?.pathname || ''

    if (
      isUpdated &&
      (pathname.includes('/home/user/own') ||
        pathname.includes('/home/settings') ||
        pathname.includes('/home/filterOptions'))
    ) {
      this.fetchProfiles(1)
      localStorage.setItem('swipepageno', '1')
      localStorage.setItem('isUpdated', '')
    } else if (isUpdated && pathname.includes('/home/user/')) {
      const id = pathname.split('/home/user/')[1]
      this.removeProfileFromList(id, true)
    }
  }

  removeProfileFromList = (id, addDelay) => {
    const { currentPageInfo } = this.state
    const profileToRemove = _.find(this.state.profiles, { id })

    if (profileToRemove) {
      const profiles = _.filter(this.state.profiles, (p) => p.id !== id)
      if (profiles.length < 1) {
        this.fetchProfiles(currentPageInfo.page + 1)
        localStorage.setItem('swipepageno', currentPageInfo.page + 1)
      }
      if (addDelay) {
        setTimeout(() => {
          this.setState({ profiles })
        }, 1000)
      } else {
        this.setState({ profiles })
      }
    }
  }

  resetProfiles = () => {
    this.fetchProfiles(1)
    localStorage.setItem('swipepageno', '1')
    localStorage.setItem('isUpdated', '')
  }

  fetchProfiles = async (page: number) => {
    this.setState({ loading: true }, async () => {
      const res = await getAllProfiles(page)
      if (res.data) {
        if (res.data.profile.length !== 0) {
          this.setState({
            loading: false,
            currentPageInfo: { page, total: res.data.total },
            profiles: [...res.data.profile]
          })
        } else {
          this.setState({ currentPageInfo: { page: 0, total: 0 }, loading: false, profiles: [] })
        }
      }
    })
  }

  setMatchedProfile = (profile) => {
    this.setState({ matchedProfile: profile })
  }

  skipProfile = async (profile: ProfileStub, index: number) => {
    this.removeProfileFromList(profile.id, false)
    if (index === 0) {
      const { currentPageInfo } = this.state
      if (currentPageInfo.total > PAGE_LIMIT * currentPageInfo.page) {
        this.fetchProfiles(currentPageInfo.page + 1)
        localStorage.setItem('swipepageno', currentPageInfo.page + 1)
      }
    }
    dislikeProfile({ to_user_id: profile.id })
  }

  likeProfile = async (profile: ProfileStub, index: number) => {
    this.removeProfileFromList(profile.id, false)
    const res = await likeProfile({ to_user_id: profile.id })
    if (res.data.match) {
      this.setState({ matchedProfile: profile })
    }
  }
  render() {
    const { profiles, matchedProfile, loading } = this.state

    return (
      <>
        <ToggleMenu contentId='mains' />
        <IonPage id='mains'>
          <NavBar contentId='mains' />
          <IonContent
            aria-live='assertive'
            aria-atomic='true'
            className={styles.search}
            scrollY={false}
            scrollX={false}
          >
            {loading ? (
              <IonLoading
                aria-label='Loading...'
                isOpen={true}
              />
            ) : (
              <>
                <div className={styles.swipewrap}>
                  {profiles.length ? (
                    profiles.map((profile: ProfileStub, i) => (
                      <SwipeCard
                        key={`card_${profile.id}_${i}`}
                        profile={profile}
                        index={i}
                        onSkip={(profile, index) => this.skipProfile(profile, index)}
                        onLike={(profile, index) => this.likeProfile(profile, index)}
                      />
                    ))
                  ) : (
                    <div
                      aria-label='No profiles to view'
                      className={styles.emptySearchMsgContainer}
                    >
                      <p style={{ color: 'black', padding: 20 }}>
                        We’re a brand new app, so our user base may be limited. To view more users, please update your
                        filters!
                      </p>
                      <IonButton
                        role='link'
                        type='button'
                        color='primary'
                        onClick={() => this.props.history.push('/home/filterOptions')}
                      >
                        <IonIcon
                          aria-hidden='true'
                          icon={filterOutline}
                          slot='start'
                        />
                        Update Filters
                      </IonButton>
                      <br />
                      <IonButton
                        role='button'
                        type='button'
                        color='primary'
                        onClick={this.resetProfiles}
                      >
                        <IonIcon
                          aria-hidden='true'
                          icon={refreshOutline}
                          slot='start'
                        />
                        Refresh User List
                      </IonButton>
                    </div>
                  )}
                </div>
                {/* {!isPlatform("android") && !isPlatform("ios") &&  !isPlatform("capacitor") &&
                  <div className={styles.gbanner}>
                      <ins className="adsbygoogle"
                          style={{display:'block'}}
                          data-ad-client="ca-pub-1297135558856957"
                          data-ad-slot="9682719836"
                          data-ad-format="auto"
                          data-full-width-responsive="true"></ins>
                      <script>
                          (adsbygoogle = window.adsbygoogle || []).push({});
                      </script>
                      <AdSense.Google
                      client='ca-pub-1297135558856957'
                      slot='9682719836'
                      style={{ height: 80, width:'500px', marginTop:5 }}
                      format=''
                    />
                  </div>
                } */}
              </>
            )}
            <MatchModal
              matchedProfile={matchedProfile}
              setMatchedProfile={this.setMatchedProfile}
            />
          </IonContent>
        </IonPage>
      </>
    )
  }
}

export default withRouter((props) => <Search {...props} />)
