import React from 'react'
import { IonCol, IonRouterLink, IonRow } from '@ionic/react'
import styles from './CustomField.module.scss'

export const Action = (props: any) => (
  <IonRow className='ion-text-center ion-justify-content-center'>
    <IonCol
      size='12'
      className={styles.customLink}
    >
      <p>
        {props.message}
        &nbsp;
        <IonRouterLink
          className={styles.customLink}
          routerLink={props.link}
        >
          {props.text}
        </IonRouterLink>
      </p>
    </IonCol>
  </IonRow>
)
