export const poseList = [
  {
    description: 'Put your hand over your heart, as if you were giving the Pledge of Allegiance.',
    image: 'assets/images/pose1.png',
    key: '1'
  },
  {
    description: 'One horizontal peace sign over your eye.',
    image: 'assets/images/pose2.png',
    key: '2'
  },
  {
    description: 'Cupping your ear.',
    image: 'assets/images/pose3.png',
    key: '3'
  },
  {
    description: 'Stick your tongue out on the side of your mouth.',
    image: 'assets/images/pose4.png',
    key: '4'
  },
  {
    description: 'Cross your arm over your chest.',
    image: 'assets/images/pose5.png',
    key: '5'
  },
  {
    description: 'Give a “hang loose” gesture.',
    image: 'assets/images/pose6.png',
    key: '6'
  },
  {
    description: 'Put your arm up like you are flexing your muscle.',
    image: 'assets/images/pose7.png',
    key: '7'
  },
  {
    description: 'Put your finger on your nose.',
    image: 'assets/images/pose8.png',
    key: '8'
  },
  {
    description: 'Smile and stick your tongue out.',
    image: 'assets/images/pose9.png',
    key: '9'
  },
  {
    description: 'Puff up your cheeks and make your eyes big.',
    image: 'assets/images/pose10.png',
    key: '10'
  },
  {
    description: 'Put your flat hand on the top of your head.',
    image: 'assets/images/pose11.png',
    key: '11'
  },
  {
    description: 'Put your hand up as if you are under oath.',
    image: 'assets/images/pose12.png',
    key: '12'
  },
  {
    description: 'Frown and give a thumbs down.',
    image: 'assets/images/pose13.png',
    key: '13'
  },
  {
    description: 'Cover your mouth with your fingers like you are surprised.',
    image: 'assets/images/pose14.png',
    key: '14'
  },
  {
    description: 'Smile and show four fingers.',
    image: 'assets/images/pose15.png',
    key: '15'
  },
  {
    description: 'Salute with two fingers on your forehead.',
    image: 'assets/images/pose16.png',
    key: '16'
  },
  {
    description: 'Give a kissy face.',
    image: 'assets/images/pose17.png',
    key: '17'
  },
  {
    description: 'Put one hand in a zero shape over one eye.',
    image: 'assets/images/pose18.png',
    key: '18'
  },
  {
    description: 'Give a wink.',
    image: 'assets/images/pose19.png',
    key: '19'
  },
  {
    description: 'Put your hand out flat as if you were carrying a platter.',
    image: 'assets/images/pose20.png',
    key: '20'
  }
]
