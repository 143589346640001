import { useState } from 'react'

export const useFormInput = (initialValue = '') => {
  const [value, setValue] = useState(initialValue)

  const handleChange = async (e) => {
    const tempValue = await e.currentTarget.value
    setValue(tempValue)
  }

  return {
    value,
    reset: (newValue) => setValue(newValue),
    onIonChange: handleChange,
    onKeyUp: handleChange
  }
}

export const validateForm = (fields) => {
  let errors = []

  fields.forEach((field) => {
    if (field.required) {
      const fieldValue = field.input.state.value

      if (fieldValue === '') {
        const error = {
          id: field.id,
          message: `Please check your ${field.id}`
        }

        errors.push(error)
      }
    }
  })

  return errors
}

export function getAge(dateString) {
  const today = new Date()
  const birthDate = new Date(dateString)
  let age = today.getFullYear() - birthDate.getFullYear()
  const m = today.getMonth() - birthDate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }
  return age
}

export function timeSince(date) {
  var seconds = Math.floor((new Date() - date) / 1000)

  var interval = seconds / 31536000

  if (interval > 1) {
    return Math.floor(interval) + ' years ago'
  }
  interval = seconds / 2592000
  if (interval > 1) {
    return Math.floor(interval) + ' months ago'
  }
  interval = seconds / 86400
  if (interval > 1) {
    return Math.floor(interval) + ' days ago'
  }
  interval = seconds / 3600
  if (interval > 1) {
    return Math.floor(interval) + ' hours ago'
  }
  interval = seconds / 60
  if (interval > 1) {
    return Math.floor(interval) + ' minutes ago'
  }
  return 'now'
}
