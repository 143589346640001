import { serviceHandler } from '../../services/serviceHandler'
import { AuthenticationApi } from '../../services/apiUrls'

const { Resetpassword } = AuthenticationApi

export const resetPassword = (payload) => {
  return serviceHandler({
    url: Resetpassword,
    methodType: 'post',
    payload
  })
}
