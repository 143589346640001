import { IonButton, IonCol, IonContent, IonFooter, IonGrid, IonImg, IonPage, IonRow } from '@ionic/react'
import React, { useEffect, useState } from 'react'
import HeaderText from '../../components/HeaderSection/HeaderText'
import styles from './Tutorial.module.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'
import { useIonRouter } from '@ionic/react'
import { getUser, updateUser } from '../Agreement/services'
import dayjs from 'dayjs'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'

const Tutorial = () => {
  const [mySlides, setMySlides] = useState<any>(null)
  const [page, setPage] = useState(0)
  const [userOnBoarded, setUserOnBoarded] = useState(false)

  useEffect(() => {
    getUser().then((user) => {
      // Checking if user was onboarded by verifying if age is already present for user.
      setUserOnBoarded(!!user?.data?.UserInfo?.age)
    })
  }, [])

  const router = useIonRouter()
  const isSlideChanged = (e: any) => {
    setPage(e.activeIndex)
  }
  const nextPage = async () => {
    updateUser({ is_tutorial_viewed: true, tutorial_viewed_on: dayjs().format('MM/DD/YYYY') })

    if (userOnBoarded) {
      router.goBack()
      return
    }

    router.push('/onboarding')
  }
  return (
    <IonPage className={styles.tutorial}>
      <IonContent>
        <Swiper
          role='region'
          aria-roledescription='carousel'
          aria-label='Dateability Tutorial'
          pagination={true}
          modules={[Pagination]}
          className={styles.mySwiper}
          onSlideChange={(e: any) => isSlideChanged(e)}
          onSwiper={(swiper) => setMySlides(swiper)}
        >
          <SwiperSlide>
            <div
              role='group'
              className={styles.content}
              aria-label='Welcome to Dateability'
            >
              <HeaderText
                aria-hidden='true'
                label='WELCOME TO'
                title='Dateability '
              />
              <div className={styles.imageSec}>
                <IonImg
                  src='/assets/images/tutorial_1.png'
                  alt='Find your match'
                />
              </div>
              <h5>Create your profile</h5>
              <p style={{ color: '#000000' }}>Upload photos and set your age, gender and location preferences.</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              role='group'
              className={styles.content}
              aria-label='Set your Dateability Deets'
            >
              <HeaderText
                aria-hidden='true'
                label='WELCOME TO'
                title='Dateability  '
              />
              <div className={styles.imageSec}>
                <IonImg
                  className={styles.cellImage}
                  src='/assets/images/checktag.png'
                  alt='Example Deet - Wheelchair'
                />
                <IonImg
                  className={styles.cellImage}
                  src='/assets/images/cell1.png'
                  alt='Example Deet - Mobility Aid'
                />
                <IonImg
                  className={styles.cellImage}
                  src='/assets/images/cell2.png'
                  alt='Example Deet - Hearing Impairment'
                />
                <IonImg
                  className={styles.cellImage}
                  src='/assets/images/cell3.png'
                  alt='Example Deet - Physical Disability'
                />
              </div>
              <h5 aria-hidden='true'>Dateability Deets</h5>
              <p style={{ color: '#000000' }}>Select the options that best describe you or enter your own.</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              role='group'
              className={styles.content}
            >
              <HeaderText
                aria-hidden='true'
                label='WELCOME TO'
                title='Dateability '
              />
              <div
                aria-hidden='true'
                className={styles.whiteImageSec}
              >
                <IonImg
                  src='/assets/images/tutorial_3.gif'
                  alt='Find a match'
                />
              </div>
              <h5 className={styles.noMargin}>Find a match</h5>
              <p style={{ color: '#000000' }}>Look through users in your area and see who likes you.</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              role='group'
              className={styles.content}
            >
              <HeaderText
                aria-hidden='true'
                label='WELCOME TO'
                title='Dateability '
              />
              <div
                aria-hidden='true'
                className={styles.imageSec}
              >
                <IonImg
                  src='/assets/images/tutorial_4.png'
                  alt='Message your match'
                />
              </div>
              <h5>Message your match</h5>
              <p style={{ color: '#000000' }}>If you both swipe on each other, it's a match!</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              role='group'
              className={styles.content}
            >
              <HeaderText
                aria-hidden='true'
                label='DATEABILITY'
                title='Safety Tip #1'
              />
              <div
                aria-hidden='true'
                className={styles.imageSec}
              >
                <IonImg
                  src='/assets/images/safety_tips_2.png'
                  alt='Do not send a user money or share credit or financial information.'
                />
              </div>
              <b aria-hidden='true'>Beware of scams and scammers!</b>
              <h5>Do not send a user money or share credit or financial information</h5>
              <p style={{ color: '#000000' }}>Whether it's on Dateability or on an outside app.</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              role='group'
              className={styles.content}
              aria-label='Safety Tip #2'
            >
              <HeaderText
                aria-hidden='true'
                label='DATEABILITY'
                title='Safety Tips '
              />
              <div
                aria-hidden='true'
                className={styles.imageSec}
              >
                <IonImg
                  src='/assets/images/safety_tips_3.png'
                  alt='Performing a reverse image search.'
                />
              </div>
              <b>We strongly recommend</b>
              <h5>Performing a reverse image search</h5>
              <p style={{ textAlign: 'left', color: '#000000' }}>
                <ul>
                  <li>Confirm images are authentic.</li>
                  <li>Verify the individual is who they say they are.</li>
                </ul>
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              role='group'
              className={styles.content}
              aria-label='Safety Tip #3'
            >
              <HeaderText
                aria-hidden='true'
                label='DATEABILITY'
                title='Safety Tips '
              />
              <div
                aria-hidden='true'
                className={styles.imageSec}
              >
                <IonImg
                  src='/assets/images/safety_tips_4.png'
                  alt='Video chat with a user before meeting that individual in person.'
                />
              </div>
              <b>For your safety, please</b>
              <h5>Video chat with a user before meeting that individual in person</h5>
              <p style={{ color: '#000000' }}>Get a better sense of who the other user is.</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              role='group'
              className={styles.content}
              aria-label='Safety Tip #4'
            >
              <HeaderText
                aria-hidden='true'
                label='DATEABILITY'
                title='Safety Tips '
              />
              <div
                aria-hidden='true'
                className={styles.imageSec}
              >
                <IonImg
                  src='/assets/images/safety_tips_5.png'
                  alt='Meet in a public place for your first date.'
                />
              </div>
              <b>It is best to </b>
              <h5>Meet in a public place for your first date</h5>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              role='group'
              className={styles.content}
              aria-label='Safety Tip #5'
            >
              <HeaderText
                aria-hidden='true'
                label='DATEABILITY'
                title='Safety Tips '
              />
              <div
                aria-hidden='true'
                className={styles.imageSec}
              >
                <IonImg
                  src='/assets/images/safety_tips_6.png'
                  alt='Report profiles that raise suspicion or engage in any inappropriate behavior.'
                />
              </div>
              <b>With Dateability, you are in control</b>
              <h5>Report profiles that raise suspicion or engage in any inappropriate behavior</h5>
            </div>
          </SwiperSlide>
        </Swiper>
      </IonContent>
      <IonFooter
        role='navigation'
        className={styles.tfooter}
      >
        <IonButton
          tabIndex={0}
          role='button'
          className={styles.customButton}
          expand='block'
          onClick={nextPage}
        >
          {page === 8 ? 'Start using Dateability' : 'Skip Tutorial'}
        </IonButton>
      </IonFooter>
    </IonPage>
  )
}
export default Tutorial
