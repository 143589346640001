import { serviceHandler } from '../../services/serviceHandler'
import { AuthenticationApi } from '../../services/apiUrls'

const { Like } = AuthenticationApi

export const getAllLikes = (payload) => {
  return serviceHandler({
    url: `${Like}?findAll=${false}&limit=${payload.limit}&page=${payload.page}&searchKey=name&searchValue=${payload.search}`,
    methodType: 'get',
    payload
  })
}

export const getFreeLikes = (payload) => {
  return serviceHandler({
    url: `${Like}/free`,
    methodType: 'get',
    payload
  })
}

export const addLike = (payload) => {
  return serviceHandler({
    url: Like,
    methodType: 'get',
    payload
  })
}
export const disLike = (payload) => {
  return serviceHandler({
    url: Like,
    methodType: 'delete ',
    payload
  })
}
