import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  useIonToast,
  IonLabel
} from '@ionic/react'
import { useContext, useRef, useState } from 'react'
import { IonRouterContext } from '@ionic/react'
import styles from '../ToggleMenu.module.scss'
import { reportUser } from '../../pages/MyProfile/services'

const ReportModal = ({ isReportOpen, setIsReportOpen, id }) => {
  const ionRouterContext = useContext(IonRouterContext)
  const modal = useRef<HTMLIonModalElement>(null)
  const [msg, setMsg] = useState('')
  const [present] = useIonToast()

  function cancel() {
    modal.current?.dismiss()
    setIsReportOpen(false)
  }

  function confirm() {
    modal.current?.dismiss('', 'confirm')
    setIsReportOpen(false)
    reportUser({ reported_user_id: id, note: msg }).then((resp: any) => {
      ionRouterContext.push('/home/search')
    })
  }

  // function onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {
  //   if (ev.detail.role === 'confirm') {
  //     // alert(`Hello, ${ev.detail.data}!`);
  //   }
  // }

  return (
    <IonModal
      aria-label='Report user'
      aria-modal='true'
      ref={modal}
      isOpen={isReportOpen}
      onDidDismiss={() => setIsReportOpen(false)}
    >
      <IonHeader role=''>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonButton
              fill='clear'
              color='dark'
              onClick={cancel}
              className={styles.contactbutton}
            >
              Cancel
            </IonButton>
          </IonButtons>
          <IonButtons slot='end'>
            <IonButton
              fill='clear'
              color='dark'
              strong={true}
              onClick={confirm}
              className={styles.contactbutton}
            >
              Report
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className={styles.modelwrap}>
        <IonGrid>
          <IonRow>
            <IonCol size='12'>
              <IonLabel
                id='id_reason_report_user'
                className={styles.note_label}
              >
                Reason for reporting this person
              </IonLabel>
              <textarea
                aria-labelledby='id_reason_report_user'
                autoFocus={true}
                className={styles.customnotetxt}
                rows={8}
                onChange={(e: any) => {
                  setMsg(e.target.value)
                }}
                placeholder="Please tell us why you're reporting this person"
              ></textarea>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonModal>
  )
}

export default ReportModal
