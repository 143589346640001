import { serviceHandler } from '../../services/serviceHandler'
import { AuthenticationApi } from '../../services/apiUrls'
import axios from 'axios'

const { VerificationPhoto, DeleteUpload } = AuthenticationApi
export const getAllPhoto = (page = 1, limit = 20) =>
  serviceHandler({
    url: `${VerificationPhoto}?limit=${limit}&page=${page}`,
    methodType: 'get'
  })

export const savePhoto = (payload) =>
  serviceHandler({
    url: VerificationPhoto,
    methodType: 'post',
    payload
  })

export const deletePhoto = ({ to_user_id }) =>
  serviceHandler({
    url: `${VerificationPhoto}/${to_user_id}`,
    methodType: 'delete'
  })
export const deleteUpload = (payload) => {
  return axios.delete(DeleteUpload, {
    data: { ...payload },
    headers: {
      Authorization: `JWT ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json'
    }
  })
}
