import { IonButton, IonCheckbox, IonLabel } from '@ionic/react'
import React, { useEffect, useState } from 'react'
import { forEach } from 'lodash'
import styles from './LookingFor.module.scss'
import HeaderText from '../../../components/HeaderSection/HeaderText'
import { useIonRouter } from '@ionic/react'
import { fetchUserRelationshipInterests, putUserRelationshipInterests, listRelationshipTypes } from '../services'
import GoBackBtn from '../../../components/GoBackBtn'

export const LookingFor: React.FC<any> = ({ next, isEditing }) => {
  const [list, setList] = useState<{ id: number; label: string }[]>([])

  const [selectedTypes, setSelectedTypes] = useState<{ [key: number]: boolean }>({})

  const router = useIonRouter()

  useEffect(() => {
    listRelationshipTypes().then((resp) => {
      setList(resp?.data || [])
    })

    fetchUserRelationshipInterests().then((resp) => {
      let newMap = {}
      forEach(resp?.data || [], (element) => {
        newMap[element.detail.id] = true
      })
      setSelectedTypes(newMap)
    })
  }, [])

  const onSaveUserRelationshipInterests = () => {
    putUserRelationshipInterests(Object.keys(selectedTypes)).then((resp) => {
      if (resp.data) {
        if (isEditing) {
          router.push('/home/user/own')
        } else {
          next('basicInfo')
        }
      }
    })
  }

  const onSelect = (detail) => {
    let newList = { ...selectedTypes }

    if (newList[detail.id]) {
      delete newList[detail.id]
    } else {
      newList[detail.id] = true
    }

    setSelectedTypes(newList)
  }

  return (
    <div className={styles.lookingwrap}>
      <HeaderText
        title='Looking for'
        label={`${isEditing ? 'EDIT' : 'CREATE'} YOUR PROFILE`}
      />
      <GoBackBtn />
      <div
        className={styles.itemsList}
        role='group'
        aria-label='What are you looking for? Checkbox group'
      >
        {list.map((detail: any, key: number) => {
          return (
            <div
              className={styles.container}
              key={key}
            >
              <div className={styles.lookinglist}>
                <IonLabel className={styles.checkboxLabel}>
                  <label htmlFor={`lbl_${key}`}>{detail.label}</label>
                  <IonCheckbox
                    slot='end'
                    color='primary'
                    id={`lbl_${key}`}
                    name={`lbl_${key}`}
                    checked={selectedTypes[detail.id]}
                    onClick={(e) => onSelect(detail)}
                  />
                </IonLabel>
              </div>
            </div>
          )
        })}
      </div>
      <IonButton
        aria-label='Save Looking For Preferences'
        className={styles.customButton}
        disabled={Object.keys(selectedTypes).length < 1 ? true : undefined}
        expand='block'
        onClick={onSaveUserRelationshipInterests}
      >
        {isEditing ? 'Save' : 'Continue'}
      </IonButton>
    </div>
  )
}

export default LookingFor
