import { serviceHandler } from '../../services/serviceHandler'
import { AuthenticationApi } from '../../services/apiUrls'

const { Signup, VerifyEmail } = AuthenticationApi

export const signup = (payload) => {
  return serviceHandler({
    url: Signup,
    methodType: 'post',
    payload
  })
}

export const verifyEmailOTP = (userId, payload) => {
  return serviceHandler({
    url: VerifyEmail + userId,
    methodType: 'post',
    payload
  })
}
