import { IonButton, IonCheckbox, IonLabel, IonTextarea, IonSpinner, IonItem } from '@ionic/react'
import React, { useCallback, useEffect, useState } from 'react'
import _, { forEach } from 'lodash'
import styles from './DateabilityDeets.module.scss'
import HeaderText from '../../../components/HeaderSection/HeaderText'
import { useIonRouter } from '@ionic/react'
import { deetList, updateUserDeets, listUserDeets } from '../services'
import GoBackBtn from '../../../components/GoBackBtn'

interface DeetRecordChoice {
  id: number
  name: string
}

interface UserDeetRecord {
  deet_id: number
  name: string
  other: string | null
}

const OtherDeetID = 24

export const DateabilityDeets: React.FC<any> = ({ next, isEditing }) => {
  const router = useIonRouter()

  //TODO eliminate if possible
  // used for tracking the state of the `Other(Type In)` user input
  const [custom, setCustom] = useState('')

  //List of possible deets
  const [deetRecords, setDeetRecords] = useState<DeetRecordChoice[]>([])
  //A map of selected deets
  const [userSelectedDeetsMap, setUserSelectedDeetsMap] = useState<Map<number, UserDeetRecord>>(new Map())

  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    listUserDeets().then((response) => {
      let newMap = new Map()

      forEach(response.data, (record) => {
        if (record.deet_id) {
          newMap.set(record.deet_id, record)
          if (record.deet_id === OtherDeetID) {
            setCustom(record.deet_other as string)
          }
        }
      })

      setUserSelectedDeetsMap(() => newMap)
    })

    setIsLoading(true)
    deetList()
      .then((dlist: any) => {
        if (dlist.data) {
          let sortedDeets: DeetRecordChoice[] = _.sortBy(dlist.data.deets, 'name')
          let otherIndex = sortedDeets.findIndex((item) => item.id === OtherDeetID)
          let otherDeet = sortedDeets.splice(otherIndex, 1)
          sortedDeets.push(otherDeet[0])
          setDeetRecords(() => sortedDeets)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  const saveDeets = () => {
    setIsLoading(true)
    updateUserDeets(Object.fromEntries(userSelectedDeetsMap))
      .then((response) => {
        router.push(`/home/user/own`)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleUserSelect = useCallback((is_checked: boolean, name: string, deet_id: number) => {
    setUserSelectedDeetsMap((prevSelected) => {
      const newMap = new Map(prevSelected)
      if (!is_checked) {
        newMap.delete(deet_id)
        if (deet_id === OtherDeetID) {
          setCustom('')
        }
      } else {
        newMap.set(deet_id, { deet_id: deet_id, name: name, other: null })
      }
      return newMap
    })
  }, [])

  const handleOtherDeetProvided = (name: string, deet_id: number, other_str: string) => {
    setUserSelectedDeetsMap((prevSelectedMap) => {
      const newMap = new Map(prevSelectedMap)
      if (deet_id) {
        newMap.set(deet_id, { deet_id: deet_id, name: name, other: other_str })
      }
      return newMap
    })
  }

  return (
    <div className={styles.deetswrap}>
      <HeaderText
        title='Dateability Deets'
        label={`${isEditing ? 'EDIT' : 'CREATE'} YOUR PROFILE`}
      />
      <GoBackBtn />
      {isLoading && (
        <>
          <IonItem
            lines='none'
            className={styles.centered_deets_spinner}
          >
            <IonLabel aria-label='One moment, data is loading'>One moment, loading...</IonLabel>
          </IonItem>
          <IonItem
            lines='none'
            className={styles.centered_deets_spinner}
          >
            <IonSpinner
              name='circles'
              color='primary'
            />
          </IonItem>
        </>
      )}
      <div
        className={styles.itemsList}
        role='group'
        aria-label='Set your Dateability Deets. Checkbox group'
        key={userSelectedDeetsMap.size}
      >
        {deetRecords.map((record) => {
          return (
            <div
              className={styles.container}
              key={record.id}
            >
              <div className={styles.deetlist}>
                <IonLabel className={styles.checkboxLabel}>
                  <label htmlFor={`lbl_${record.id}`}>{record.name}</label>
                  <IonCheckbox
                    slot='end'
                    color='primary'
                    id={`lbl_${record.id}`}
                    name={`lbl_${record.id}`}
                    checked={userSelectedDeetsMap.has(record.id)}
                    onIonChange={(e) => handleUserSelect(e.target.checked, record.name, record.id)}
                  />
                </IonLabel>
              </div>
              {record.id === OtherDeetID && userSelectedDeetsMap.has(OtherDeetID) && (
                <div className={styles.customTarea}>
                  <IonTextarea
                    value={custom}
                    auto-grow={true}
                    placeholder="Enter details about your 'other' selection here"
                    maxlength={200}
                    onIonChange={(e: any) => {
                      setCustom(e.target.value)
                      handleOtherDeetProvided(record.name, record.id, e.target.value)
                    }}
                  ></IonTextarea>
                </div>
              )}
            </div>
          )
        })}
      </div>
      <IonButton
        aria-label='Save your Dateability Deets'
        className={styles.customButton}
        disabled={userSelectedDeetsMap.size <= 0 ? true : undefined}
        expand='block'
        onClick={() => saveDeets()}
      >
        {isEditing ? 'Save' : 'Continue'}
      </IonButton>
    </div>
  )
}

export default DateabilityDeets
