import React from 'react'
import { IonCardTitle, IonCol, IonGrid, IonRow } from '@ionic/react'
import styles from './HeaderText.module.scss'

const HeaderText = ({ title, label, info, ...props }: any) => {
  return (
    <IonGrid
      className={styles.gridview}
      {...props}
    >
      <IonRow>
        <IonCol
          size='12'
          className={styles.headingSection}
        >
          <IonCardTitle
            aria-label={label}
            aria-level={1}
          >
            {label}
          </IonCardTitle>
          <h5 className={styles.subheading}>{title}</h5>
          {info ? <h6 style={{ marginTop: 0 }}>{info}</h6> : null}
        </IonCol>
      </IonRow>
    </IonGrid>
  )
}
export default HeaderText
