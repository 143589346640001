/**
 * Takes either a string or numeric value representing
 *  a distance (ex: 3.5) and returns a normalized string.
 *
 * @param {string|number} input
 * @returns {string}
 */
export const formatUserDistance = (input) => {
  const parsed = parseInt(input, 10)
  if (parsed < 3) {
    return 'Less than 3 miles'
  }
  return `${parsed} miles away`
}
