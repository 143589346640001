import { useEffect, useState } from 'react'
import {
  IonButton,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  useIonToast,
  IonModal,
  IonItem,
  IonCheckbox,
  useIonAlert
} from '@ionic/react'
import { useParams } from 'react-router'
import { useIonRouter } from '@ionic/react'
import CustomField from '../../components/CustomField'
import { useSignupFields, useEmailOtpValidationFields } from '../../data/fields'
import { Action } from '../../components/Action'
import { validateForm } from '../../data/utils'
import { signup, verifyEmailOTP } from './services'
import HeaderText from '../../components/HeaderSection/HeaderText'
import { getUser } from '../Agreement/services'
import GoBackBtn from '../../components/GoBackBtn'
import SocialLogins from '../Login/SocialLogins'
import { sendEmailOTP } from '../MyProfile/services'
import { handleIfValidInteraction } from '../../util/a11yHelper'

import styles from './Signup.module.scss'

const Signup = () => {
  const params = useParams()
  const fields = useSignupFields()
  const otpFields = useEmailOtpValidationFields()
  const [errors, setErrors] = useState(false)
  const [showEmailVerificationModal, setShowEmailVerificationModal] = useState(false)
  const [privacy, setPrivacy] = useState(false)
  const [terms, setTerms] = useState(false)
  const [acceptable, setAcceptable] = useState(false)
  const [allow_text_promotion, setTextPromotion] = useState(false)
  const [userId, setUserId] = useState('')
  const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

  //	Initializing our router
  const router = useIonRouter()
  const [present] = useIonToast()
  const [presentAlert] = useIonAlert()

  const handleAfterLogin = (data) => {
    let userObj = {
      email: data.email,
      first_name: data.first_name,
      last_name: data.last_name,
      profile_picture_url: data.profile_picture_url,
      username: data.username,
      objectId: data.objectId,
      id: data.id
    }
    localStorage.setItem('userDetails', JSON.stringify(userObj))
    localStorage.setItem('token', data.token)

    getUser().then((res: any) => {
      if (res.data) {
        if (res.data.UserDeets && res.data.UserDeets.length > 0) {
          router.push(`/home/user/own`)
        } else if (res.data.is_tutorial_viewed) {
          router.push('/onboarding')
        } else if (res.data.is_terms_agreed || res.data.is_privacy_agreed || res.data.is_acceptable_use_agreed) {
          router.push('/tutorial')
        } else {
          router.push('/agreement')
        }
      }
    })
  }
  const submitRegistration = () => {
    const errors = validateForm(fields)
    let inputs: any = {
      email: '',
      password: '',
      first_name: '',
      last_name: ''
    }
    fields.map((item: any) => {
      if (item.id === 'email') {
        if (EMAIL_REGEX.test(item.input.state.value)) {
          inputs[item.id] = item.input.state.value
          otpFields[0].input.state.reset(item.input.state.value)
        } else {
          present({ message: 'Please provide a valid email address!', color: 'danger', duration: 1000 })
        }
      } else if (item.id === 'name') {
        inputs[item.items[0].id] = item.items[0].input.state.value
        inputs[item.items[1].id] = item.items[1].input.state.value
      } else {
        inputs[item.id] = item.input.state.value
      }

      return null
    })

    if (!errors.length && inputs.first_name && inputs.last_name && inputs.password === inputs.cpassword) {
      inputs['is_terms_agreed'] = terms
      inputs['is_privacy_agreed'] = privacy
      inputs['is_acceptable_use_agreed'] = acceptable
      delete inputs.cpassword
      signup(inputs).then((res: any) => {
        if (res.data) {
          setUserId(res.data?.id)
          setShowEmailVerificationModal(true)
        } else {
          if (res.response.data.err) {
            if (res.response.status === 400) {
              presentAlert({
                message:
                  'It looks like you already have an account. Please log in or reset your password with Forgot Password.',
                buttons: [
                  {
                    text: 'Ok',
                    role: 'cancel',
                    cssClass: styles.CancelBtn
                  }
                ],
                cssClass: 'logOutAlert'
              })
            } else {
              present({ message: res.response.data.msg, color: 'warning', duration: 1000 })
            }
          } else {
            present({ message: res.response.data.msg, color: 'success', duration: 1000 })
          }
        }
      })
    } else if (inputs.password !== inputs.cpassword) {
      present({ message: 'Password and Confirm password does not match', color: 'danger', duration: 1000 })
    } else {
      present({ message: 'Please enter all sign up details', color: 'danger', duration: 1000 })
    }
  }

  // (ryan): this appears to be the last step, verifying your email
  const createAccount = async () => {
    if (otpFields[1].input.state.value) {
      const data = await verifyEmailOTP(userId, { code: otpFields[1].input.state.value })

      let otpVerified = false

      if (data?.err === false) {
        otpVerified = true
      }
      if (otpVerified) {
        setShowEmailVerificationModal(false)
        present({ message: 'Sign up successful! Please login.', color: 'success', duration: 1000 })
        router.push('/login')
      } else {
        present({ message: 'Invalid login code! Please check and re-enter again.', color: 'danger', duration: 1000 })
      }
    } else {
      present({ message: 'Invalid login code! Please check and re-enter again.', color: 'danger', duration: 1000 })
    }
  }
  useEffect(() => {
    return () => {
      fields.forEach((field: any) => {
        if (field.items?.length) {
          field.items.forEach((item: any) => {
            item.input.state.reset('')
          })
        } else {
          field?.input.state.reset('')
        }
      })
      setErrors(false)
    }
  }, [])

  const handleResendEmailOTP = (e) => {
    handleIfValidInteraction(e, async () => {
      let otpRes = await sendEmailOTP(userId)

      // (ryan): what's this, two response structures? indeed!
      // errors are returned as Axios responses, while success messages are returned as objects
      if (otpRes?.response?.data?.msg) {
        present({ message: otpRes.response.data.msg, color: 'warning', duration: 1000 })
      } else if (otpRes.msg) {
        present({ message: otpRes.msg, color: 'success', duration: 1000 })
      }
    })
  }

  return (
    <IonPage className={styles.signupPage}>
      <IonContent
        fullscreen
        color={'#222460'}
      >
        <IonGrid className='ion-padding'>
          <IonRow>
            <IonCol
              size='12'
              className={styles.headingText}
            >
              <HeaderText
                title="Let's Get Started!"
                label='SIGN UP FOR DATEABILITY'
              />
              <GoBackBtn />
            </IonCol>
          </IonRow>
          <IonRow className='ion-margin-top ion-padding-top'>
            <IonCol size='12'>
              {fields.map((field: any, index: number) => {
                if (field.items?.length) {
                  return (
                    <IonRow
                      key={`signupfield${index}`}
                      className='ion-no-margin ion-no-padding'
                    >
                      <IonCol size='6'>
                        <CustomField
                          child={true}
                          field={field.items[0]}
                          errors={errors}
                          autoCapitalize={field.isCapital}
                        />
                      </IonCol>
                      <IonCol size='6'>
                        <CustomField
                          child={true}
                          field={field.items[1]}
                          errors={errors}
                          autoCapitalize={field.isCapital}
                        />
                      </IonCol>
                    </IonRow>
                  )
                } else {
                  return (
                    <CustomField
                      key={`signupfield${index}`}
                      field={field}
                      errors={errors}
                      autoCapitalize={field.isCapital}
                    />
                  )
                }
              })}
              <IonRow>
                <IonItem
                  lines={'none'}
                  className={styles.checks}
                >
                  <IonCheckbox
                    className={styles.optincheckbox}
                    color='success'
                    slot='start'
                    name='cb_terms'
                    id='cb_terms'
                    checked={terms}
                    onClick={() => {
                      setTerms(!terms)
                    }}
                  />
                  <label
                    className={styles.optinlabel}
                    htmlFor='cb_terms'
                  >
                    I agree to the{' '}
                    <a
                      href='https://info.dateabilityapp.com/terms-conditions'
                      target='_blank'
                      rel='noreferrer'
                      aria-label='View Terms of Use.'
                      className={styles.optinLink}
                    >
                      Terms of Use
                    </a>
                  </label>
                </IonItem>
                <IonItem
                  lines={'none'}
                  className={styles.checks}
                >
                  <IonCheckbox
                    className={styles.optincheckbox}
                    color='success'
                    slot='start'
                    name='cb_privacy'
                    id='cb_privacy'
                    checked={privacy}
                    onClick={() => {
                      setPrivacy(!privacy)
                    }}
                  />
                  <label
                    htmlFor='cb_privacy'
                    className={styles.optinlabel}
                  >
                    I agree to the{' '}
                    <a
                      href='https://info.dateabilityapp.com/privacy-policy'
                      target='_blank'
                      rel='noreferrer'
                      aria-label='View Privacy Policy.'
                      className={styles.optinLink}
                    >
                      Privacy Policy
                    </a>
                  </label>
                </IonItem>
                <IonItem
                  lines={'none'}
                  className={styles.checks}
                >
                  <IonCheckbox
                    className={styles.optincheckbox}
                    color='success'
                    slot='start'
                    role='checkbox'
                    name='cb_acceptable'
                    id='cb_acceptable'
                    title='Acceptable Use Policy'
                    checked={acceptable}
                    onClick={() => {
                      setAcceptable(!acceptable)
                    }}
                  />
                  <label
                    htmlFor='cb_acceptable'
                    className={styles.optinlabel}
                  >
                    I agree to the{' '}
                    <a
                      href='https://info.dateabilityapp.com/acceptable-use-policy'
                      target='_blank'
                      rel='noreferrer'
                      aria-label='View Acceptable Use Policy.'
                      className={styles.optinLink}
                    >
                      Acceptable Use Policy
                    </a>
                  </label>
                </IonItem>
                <IonItem
                  lines={'none'}
                  className={styles.checks}
                >
                  <IonCheckbox
                    className={styles.optincheckbox}
                    color='success'
                    slot='start'
                    name='cb_allow_text_promotion'
                    id='cb_allow_text_promotion'
                    checked={allow_text_promotion}
                    onClick={() => {
                      setTextPromotion(!allow_text_promotion)
                    }}
                  />
                  <label
                    htmlFor='cb_allow_text_promotion'
                    className={styles.optinlabel}
                  >
                    I agree to receive marketing messages from Dateability at the email provided above. I understand I
                    will receive 2 messages a month, data rates may apply, reply STOP to opt out
                  </label>
                </IonItem>
              </IonRow>
              <IonButton
                className={styles.customButton}
                disabled={!terms || !privacy || !acceptable ? true : undefined}
                expand='block'
                onClick={submitRegistration}
                aria-label='Submit Sign up Form'
              >
                Continue
              </IonButton>
            </IonCol>
          </IonRow>
          <SocialLogins handleAfterLogin={handleAfterLogin} />
          <IonRow className={styles.footerText}>
            <IonGrid className='ion-no-margin ion-no-padding'>
              <Action
                message='Do you already have a account?'
                text='Sign in'
                link='/login'
              />
            </IonGrid>
          </IonRow>
        </IonGrid>
        <IonModal
          aria-modal='true'
          isOpen={showEmailVerificationModal}
          className={styles.signupPage}
          aria-labelledby='modal_verify_header'
          aria-describedby='modal_verify_desc'
          backdropDismiss={false}
          onDidDismiss={() => setShowEmailVerificationModal(false)}
        >
          <IonContent
            fullscreen
            color={'#222460'}
          >
            <IonGrid className='ion-padding'>
              <IonRow>
                <IonCol
                  size='12'
                  className={styles.headingText}
                >
                  <IonCardTitle id='modal_verify_header'>Verify your Email</IonCardTitle>
                  <h5 id='modal_verify_desc'>Enter the Login Code sent to your Email</h5>
                </IonCol>
              </IonRow>
              {otpFields.map((field: any, index: number) => {
                if (field.items?.length) {
                  return (
                    <IonRow
                      key={`otpfield${index}`}
                      className='ion-no-margin ion-no-padding'
                    >
                      <IonCol size='6'>
                        <CustomField
                          child={true}
                          field={field.items[0]}
                          errors={errors}
                        />
                      </IonCol>
                      <IonCol size='6'>
                        <CustomField
                          child={true}
                          field={field.items[1]}
                          errors={errors}
                        />
                      </IonCol>
                    </IonRow>
                  )
                } else {
                  return (
                    <CustomField
                      key={`otpfield${index}`}
                      field={field}
                      errors={errors}
                    />
                  )
                }
              })}
              <IonRow>
                <div
                  role='button'
                  tabIndex={0}
                  className={styles.link}
                  onClick={handleResendEmailOTP}
                  onKeyUp={handleResendEmailOTP}
                >
                  Resend Email Code
                </div>
              </IonRow>
              <IonRow>
                <div className={styles.spam_info}>
                  Note: Please check your email's spam, promotional, or similar folders.
                </div>
              </IonRow>

              <br />

              <IonRow>
                <IonCol size='12'>
                  <IonButton
                    aria-label='Submit Verification Form'
                    className={styles.customButton}
                    expand='block'
                    onClick={createAccount}
                  >
                    Continue
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  )
}

export default Signup
