import { useEffect, useState } from 'react'
import { IonHeader, IonContent, IonIcon, IonModal, IonButton, useIonRouter } from '@ionic/react'
import { Animate } from 'react-simple-animate'
import styles from './MatchModal.module.scss'
import { heartOutline } from 'ionicons/icons'

export default function MatchModal({ matchedProfile, setMatchedProfile }) {
  const router = useIonRouter()
  const [userPhoto, setUserPhoto] = useState('/assets/images/genericavatar.svg')

  useEffect(() => {
    const userPhoto = localStorage.getItem('userPhoto')
    setUserPhoto(userPhoto ? userPhoto : '/assets/images/genericavatar.svg')
  }, [])

  return (
    <IonModal
      role='alert'
      isOpen={!!matchedProfile}
    >
      <IonHeader></IonHeader>
      <IonContent className='ion-padding'>
        <h2
          aria-label={`Congratuations! You've matched with ${matchedProfile?.first_name}`}
          className='text-primary ion-text-center strong text-2xl'
        >
          Congratulations!
        </h2>
        <Animate
          play
          duration={0.3}
          delay={0.1}
          start={{
            scale: '0.8'
          }}
          end={{
            scale: '1'
          }}
        >
          <span
            aria-hidden='true'
            className='flex text-red ion-align-items-center ion-justify-content-center ion-margin semibold'
          >
            <h1 className='text-red ion-no-margin title-lg semibold'>Its a match</h1>
            <IonIcon
              className='ion-margin-start'
              size='large'
              icon={heartOutline}
            />
          </span>
        </Animate>
        <div className={styles.matchContainer}>
          <Animate
            play
            duration={0.3}
            delay={0.1}
            start={{
              transform: 'rotate(-20deg)',
              translate: '0'
            }}
            end={{
              transform: 'rotate(0deg)',
              translate: '20px'
            }}
          >
            <div className={styles.dottedborder}>
              <div className={styles.mpWrap}>
                <div
                  className={styles.pImg}
                  style={{ backgroundImage: `url(${userPhoto})` }}
                />
              </div>
            </div>
          </Animate>
          <Animate
            play
            duration={0.3}
            delay={0.1}
            start={{
              transform: 'rotate(20deg)',
              translate: '0'
            }}
            end={{
              transform: 'rotate(0deg)',
              translate: '-20px'
            }}
          >
            <div className={styles.pWrap}>
              <div
                className={styles.pImg}
                style={{
                  backgroundImage: `url(${matchedProfile?.UserPhotos && matchedProfile?.UserPhotos[0]?.photo_url})`
                }}
              />
            </div>
          </Animate>
        </div>
        <h3 aria-hidden='true'>{`${matchedProfile?.first_name}`}</h3>
        <div className={styles.footer}>
          <IonButton
            expand='block'
            color='primary'
            className='ion-margin-bottom'
            onClick={() => {
              setMatchedProfile(null)
              router.push(`/chat/${matchedProfile?.id}`)
            }}
          >
            Message {`${matchedProfile?.first_name}`}
          </IonButton>
          <IonButton
            expand='block'
            fill='outline'
            color='medium'
            className='ion-margin-bottom'
            onClick={() => {
              setMatchedProfile(null)
              router.push(`/home/search`)
            }}
          >
            Keep Swiping
          </IonButton>
        </div>
      </IonContent>
    </IonModal>
  )
}
