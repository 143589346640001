import React, { useEffect, useState, useRef } from 'react'
import {
  IonContent,
  IonPage,
  IonList,
  IonItem,
  IonLabel,
  IonSearchbar,
  IonButton,
  IonIcon,
  useIonRouter,
  IonImg,
  IonInfiniteScroll,
  IonInfiniteScrollContent
} from '@ionic/react'
import styles from './match.module.scss'
import { getAllMatches } from './services'
import NavBar from '../../components/Navbar/NavBar'
import ToggleMenu from '../../components/ToggleMenu'
import type { MatchStub } from '../../util/types'
import { getAge } from '../../data/utils'
import { timeSince } from '../../data/utils'
import { chatboxOutline, chevronForward, peopleOutline } from 'ionicons/icons'
import { useLocation } from 'react-router-dom'
import _ from 'lodash'

const Matches: React.FC = () => {
  const containerRef = useRef<HTMLIonContentElement>(null)
  const infiniteScrollRef = useRef<HTMLIonInfiniteScrollElement>(null)
  const router = useIonRouter()
  const location = useLocation()
  const [matches, setMatches] = useState<MatchStub[]>([])
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [searchText, setSearchText] = useState('')

  const scrollToTop = () => {
    if (containerRef.current) {
      containerRef.current.scrollToTop()
    }
  }

  const getMatchData = (num, isNew, text) => {
    setPage(num)
    getAllMatches({ limit: 100, page: num, search: text }).then((res) => {
      if (res.data) {
        setTotal(res.data.total)
        const messages = _.map(res.data.match, (m) => {
          return {
            from_user: m.from_user,
            to_user: m.to_user,
            matched_on: m.matched_on,
            message: _.orderBy(
              _.compact(
                _.map(
                  [
                    ...m.from_user.MessagesFromUser,
                    ...m.from_user.MessagesToUser,
                    ...m.to_user.MessagesFromUser,
                    ...m.to_user.MessagesToUser
                  ],
                  (me) =>
                    (m.from_user_id === me.from_user_id && m.to_user_id === me.to_user_id) ||
                    (m.to_user_id === me.from_user_id && m.from_user_id === me.to_user_id)
                      ? me
                      : null
                )
              ),
              ['sent_on'],
              ['desc']
            )[0]
          }
        })
        const fixedMessages = _.map(messages, (m) => {
          return {
            ...m,
            message:
              (m && m.message && m.from_user.id === m.message.from_user_id && m.to_user.id === m.message.to_user_id) ||
              (m && m.message && m.to_user.id === m.message.from_user_id && m.from_user.id === m.message.to_user_id)
                ? m.message
                : {}
          }
        })
        const sortedMessages = _.orderBy(
          fixedMessages,
          (m) => {
            return m.message && m.message.sent_on ? m.message.sent_on : m.matched_on
          },
          ['desc']
        )
        let newData
        if (isNew) {
          newData = [...sortedMessages]
        } else {
          newData = [...matches, ...sortedMessages]
        }

        setMatches(newData)
        infiniteScrollRef.current?.complete()
      }
    })
  }
  useEffect(() => {
    scrollToTop()
    if (location.pathname !== '/home/matches') {
      return
    }
    getMatchData(1, true, searchText)
  }, [location])

  const showDetails = (id: string) => {
    router.push(`/chat/${id}`)
  }

  const fetchMoreData = () => {
    if (matches.length <= total) {
      getMatchData(page + 1, false, searchText)
    }
  }

  const onSearch = (e) => {
    setSearchText(e.currentTarget.value)
    getMatchData(1, true, e.currentTarget.value)
  }

  const matchCard = (data, matched_on, message, key = data.id) => {
    return (
      <IonItem
        key={key}
        tabIndex={0}
        detail
        button
        id={`matchItem_${data.id}`}
        className={` ${styles.matchItem} animate__animated animate__fadeIn`}
        lines='none'
        role='link'
        aria-label={`View Messages from ${data.first_name}`}
        onClick={() => {
          showDetails(data.id)
        }}
      >
        <IonImg
          aria-hidden='true'
          src={data.UserPhotos?.length ? data.UserPhotos[0].photo_url : '/assets/images/sarah-img.svg'}
          alt='Photo'
        />
        <IonLabel>
          <h2 style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            {`${data.first_name}, ${getAge(data.UserInfo?.age)}`}
            <small style={{ color: '#000000', fontWeight: 'lighter' }}>
              <IonIcon
                aria-label='Matched'
                style={{ marginRight: 5, verticalAlign: 'middle' }}
                icon={peopleOutline}
              />
              {timeSince(new Date(matched_on))}
            </small>
          </h2>
          <p>{message && message.message ? message.message : 'No messages yet'}</p>
          <small style={{ color: '#000000' }}>
            <IonIcon
              aria-label='Last message'
              style={{ marginRight: 5, verticalAlign: 'middle' }}
              icon={chatboxOutline}
            />
            {timeSince(new Date(message.sent_on))}
          </small>
        </IonLabel>
        <IonButton
          aria-hidden='true'
          tabIndex={-1}
          role='link'
          className={styles.detailButton}
          fill='clear'
        >
          <IonIcon icon={chevronForward} />
        </IonButton>
      </IonItem>
    )
  }

  return (
    <>
      <ToggleMenu contentId='messages' />
      <IonPage
        id='messages'
        className={styles.page}
      >
        <NavBar contentId='messages' />
        <IonContent ref={containerRef}>
          <h4>Matches</h4>
          <IonSearchbar
            role='search'
            aria-label='My Matches'
            placeholder='Search'
            onIonChange={onSearch}
            value={searchText}
          />
          <div
            aria-live='assertive'
            aria-atomic='true'
          >
            {matches.length > 0 && (
              <IonList
                id='scrollableDiv'
                style={{ marginBottom: '8px' }}
              >
                {matches.map((profile, index) => {
                  let details: any = localStorage.getItem('userDetails')
                  let userDetails = JSON.parse(details)
                  if (profile.to_user.id !== userDetails.id) {
                    return matchCard(profile.to_user, profile.matched_on, profile.message)
                  } else {
                    return matchCard(profile.from_user, profile.matched_on, profile.message)
                  }
                })}
              </IonList>
            )}

            {matches.length === 0 && searchText.length > 0 && (
              <div className={styles.noResults}>
                <p>No matches found</p>
              </div>
            )}

            <IonInfiniteScroll
              ref={infiniteScrollRef}
              onIonInfinite={fetchMoreData}
            >
              <IonInfiniteScrollContent
                loadingSpinner='crescent'
                loadingText={'Loading..'}
              />
            </IonInfiniteScroll>
          </div>
        </IonContent>
      </IonPage>
    </>
  )
}

export default Matches
