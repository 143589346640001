import { Route } from 'react-router-dom'
import { useEffect } from 'react'
import { IonApp, IonRouterOutlet, IonSplitPane, setupIonicReact } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'

import MainTabs from './pages/MainTabs/MainTabs'
import AuthGuard from './components/AuthGuard'
import Login from './pages/Login/Login'
import Signup from './pages/Signup/Signup'
import Agreement from './pages/Agreement/Agreement'
import Tutorial from './pages/Tutorial/Tutorial'
import Onboarding from './pages/Onboarding/Onboarding'
import ChatWindow from './pages/Chat/ChatWindow'
import Faq from './pages/Faq'
import Safety from './pages/Safety'
import ForgotPassword from './pages/ForgotPassword'
import ResetPassword from './pages/ResetPassword'
import Verification from './pages/Verification'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
// import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

import 'react-chat-elements/dist/main.css'

/* Theme variables */
import './theme/variables.css'
import './theme/override.css'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import '@ionic/react/css/ionic-swiper.css'
import Notifications from './pages/Notifications'
import { SplashScreen } from '@capacitor/splash-screen'
import ChangePasswordComponent from './pages/ChangePassword'

setupIonicReact({
  swipeBackEnabled: false
})

const App = () => {
  useEffect(() => {
    SplashScreen.hide()
  }, [])

  return (
    <IonApp className={'dark-theme'}>
      <IonReactRouter>
        <IonSplitPane contentId='main'>
          <IonRouterOutlet id='main'>
            <Route
              path='/home'
              component={MainTabs}
            />
            <Route
              path='/login'
              component={Login}
            />
            <Route
              path='/reset'
              component={ResetPassword}
            />
            <Route
              path='/forgot'
              component={ForgotPassword}
            />
            <Route
              path='/change'
              component={ChangePasswordComponent}
            />
            <Route
              path='/signup'
              component={Signup}
            />
            <Route
              path='/agreement'
              component={Agreement}
            />
            <Route
              path='/agreement/settings'
              component={Agreement}
            />
            <Route
              path='/tutorial'
              component={Tutorial}
            />
            <Route
              path='/onboarding'
              component={Onboarding}
            />
            <Route
              path='/edit-profile'
              component={Onboarding}
            />
            <Route
              path='/chat/:userId'
              component={ChatWindow}
            />
            <Route
              path='/verify'
              component={Verification}
            />
            <Route
              path='/faq'
              component={Faq}
            />
            <Route
              path='/safety'
              component={Safety}
            />
            <Route
              path='/notifications'
              component={Notifications}
            />
            <Route
              path='/'
              component={AuthGuard}
              exact
            />
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  )
}

export default App
