import { IonContent, IonPage, IonImg } from '@ionic/react'
import Profile from '../../components/Profile'
import styles from '../MyProfile/MyProfile.module.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'

const ProfileView = () => {
  const profile = {
    first_name: 'Sarah',
    last_name: 'Morisette',
    age: 22,
    gender: 'Female',
    bio: `An old man turned ninety-eight. He won the
    lottery, and died the next day. It's a black fly
    in your Chardonnay. It's a death row pardon
    two minutes too late`,
    height: `5'6"`,
    pronoun: 'she/her',
    occupation: 'Singer, songwriter, and actress',
    political_view: 'Liberal hippy',
    education: 'B.S. Toronto Uni',
    vaccination_status: 'Vaccinated',
    children: 3,
    religion: 'Buddhist',
    basics: [
      { label: 'Occupation:', value: 'Rapper' },
      { label: 'Religion:', value: 'Christian' },
      { label: 'School:', value: 'Conservative' },
      { label: 'Vaccine Status:', value: 'Unvaccinated' },
      { label: 'Children:', value: 'Have children, want more' }
    ],
    interests: ['camping', 'animals', 'music', 'concerts'],
    deets: ['Wheelchair User', 'Physical Disability'],
    photos: ['/assets/images/sample2.png'],
    isEditable: false
  }

  return (
    <IonPage id='profile-page'>
      <IonContent>
        <div className={styles.imgsecion}>
          <Swiper
            pagination={true}
            modules={[Pagination]}
            className={styles.mySwiper}
          >
            <SwiperSlide>
              <div className={styles.content}>
                <div className={styles.imageSec}>
                  <IonImg
                    src='/assets/images/sarah-img.svg'
                    alt='apple'
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.content}>
                <div className={styles.imageSec}>
                  <IonImg
                    src='/assets/images/sarah-img.svg'
                    alt='apple'
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.content}>
                <div className={styles.imageSec}>
                  <IonImg
                    src='/assets/images/sarah-img.svg'
                    alt='apple'
                  />
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <Profile
          profile={profile}
          profileInfo={{}}
          isEditable={false}
        />
      </IonContent>
    </IonPage>
  )
}
export default ProfileView
