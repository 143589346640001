import { io, Socket } from 'socket.io-client'

let socket: Socket | null = null

export const getSocket = () => {
  if (!socket) {
    socket = io(process.env.REACT_APP_HOST as string, {
      autoConnect: false,
      query: {
        token: localStorage.getItem('token')
      },
      transports: ['websocket']
    })
  }
  return socket
}
