import {
  IonButtons,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonMenuToggle,
  IonToolbar,
  IonAvatar,
  useIonRouter,
  IonImg
} from '@ionic/react'
import { closeOutline, notificationsOutline } from 'ionicons/icons'
import { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import notifyInst from '../../pages/singleTon/notifyInst'
import { handleIfValidInteraction } from '../../util/a11yHelper'

const NavBar = ({ contentId }) => {
  let details: any = localStorage.getItem('userDetails')
  let userData: any = JSON.parse(details || '{}')
  const [notification, setNotification] = useState(notifyInst.getPropertyByName('hasnotify'))
  const router = useIonRouter()

  const genericPhoto = '/assets/images/genericavatar.svg'
  const userPhoto = localStorage.getItem('userPhoto')
  setInterval(() => {
    // update notification status every 5 seconds
    setNotification(notifyInst.getPropertyByName('hasnotify'))
  }, 5000)

  const routeToProfile = (e) => handleIfValidInteraction(e, () => router.push('/home/user/own'))

  const routeToNotifications = (e) => handleIfValidInteraction(e, () => router.push('/notifications'))

  const routeGoBack = (e) => handleIfValidInteraction(e, router.goBack)

  return (
    <IonHeader role='banner'>
      <IonToolbar
        mode='md'
        color='tertiary'
      >
        <div className={styles.headarea}>
          <div
            role='link'
            tabIndex={0}
            aria-label='Go to My Profile'
            className={styles.imagedp}
            onClick={routeToProfile}
            onKeyUp={routeToProfile}
          >
            <IonAvatar
              aria-hidden='true'
              className={styles.userdp}
            >
              <img
                src={userPhoto ? userPhoto : genericPhoto}
                alt='My Avatar'
              />
            </IonAvatar>
            <p
              aria-hidden='true'
              className={styles.username}
            >
              {userData.first_name}
            </p>
          </div>
          {contentId === 'notifications' ? (
            <div
              role='link'
              tabIndex={0}
              aria-label='Close Notifications Panel'
              className={styles.notify}
              onClick={routeGoBack}
              onKeyUp={routeGoBack}
            >
              <IonIcon
                aria-hidden='true'
                src={closeOutline}
              />
            </div>
          ) : (
            <>
              <div
                tabIndex={0}
                role='link'
                // @todo (ryan) - would be nice to support aria-live="polite" here
                // but that causes the screenreader to read this on every page load (at least in MacOS)
                //aria-live="polite"
                aria-label={`Open Notifications Panel (${notification ? 'You have new notifications' : 'No new notifications'})`}
                className={notification ? styles.notify_active : styles.notify}
                onClick={routeToNotifications}
                onKeyUp={routeToNotifications}
              >
                <IonIcon
                  aria-hidden='true'
                  src={notificationsOutline}
                />
              </div>
              <IonImg
                src={'/assets/images/filter.svg'}
                style={{
                  height: '30px',
                  margin: '0px 10px'
                }}
                onClick={() => router.push('/home/filterOptions')}
              />
              <div
              // (ryan) - this wrapper prevents a screenreader (MacOS) from keeping :focus on the previous link
              // I think this has to do with the way Ionic restructures parts of the DOM
              >
                <IonButtons slot='end'>
                  <IonMenuToggle autoHide={true}>
                    <IonMenuButton
                      role='button'
                      aria-label='Open Main Navigation'
                      aria-haspopup='menu'
                    />
                  </IonMenuToggle>
                </IonButtons>
              </div>
            </>
          )}
        </div>
      </IonToolbar>
    </IonHeader>
  )
}

export default NavBar
