import React from 'react'
import MyLikesSubscription from './MyLikesSubscription'
import MyLikesFree from './MyLikesFree'
import { useRevenueCatContext } from '../../contexts/RevenueCatContext'

const MyLikes: React.FC = () => {
  const { isPlus } = useRevenueCatContext()

  return <> {isPlus ? <MyLikesSubscription /> : <MyLikesFree />}</>
}

export default MyLikes
