import {
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonIcon,
  IonCheckbox,
  IonContent,
  IonItem,
  IonLabel,
  IonPage,
  IonText
} from '@ionic/react'
import { useEffect, useState } from 'react'
import styles from './Agreement.module.scss'
import HeaderText from '../../components/HeaderSection/HeaderText'
import { useIonRouter } from '@ionic/react'
import { useLocation } from 'react-router-dom'
import TermsOfUse from './TermsOfUse'
import PrivacyPolicy from './PrivacyPolicy'
import AcceptablePolicy from './AcceptablePolicy'
import { alertCircleOutline, checkmarkCircleOutline } from 'ionicons/icons'
import { getUser, updateUser } from './services'
import BackBtn from '../../components/BackBtn'

const Agreement = () => {
  const location = useLocation()
  const [agreedItems, setAgreedItems] = useState<{
    is_terms_agreed?: boolean
    is_privacy_agreed?: boolean
    is_acceptable_use_agreed?: boolean
  }>({})
  const [agreedPromotions, setAgreedPromotions] = useState<{
    allow_email_promotion?: boolean
    allow_text_promotion?: boolean
    allow_push_promotion?: boolean
  }>({})

  const router = useIonRouter()
  const fromSettings = location.pathname === '/agreement/settings'

  const nextPage = () => {
    updateUser({ ...agreedPromotions, ...agreedItems }).then(() => {
      fromSettings ? router.push('/home/settings') : router.push('/tutorial')
    })
  }
  useEffect(() => {
    getUser().then((info: any) => {
      if (info.data) {
        let infoData = { ...info.data }
        setAgreedItems({
          is_terms_agreed: infoData.is_terms_agreed,
          is_privacy_agreed: infoData.is_privacy_agreed,
          is_acceptable_use_agreed: infoData.is_acceptable_use_agreed
        })
        setAgreedPromotions({
          allow_email_promotion: infoData.allow_email_promotion,
          allow_push_promotion: infoData.allow_push_promotion,
          allow_text_promotion: infoData.allow_text_promotion
        })
      }
    })
  }, [])
  const updatePromotion = (key: string) => {
    let currentPromotion = { ...agreedPromotions }
    currentPromotion[key] = !currentPromotion[key]
    setAgreedPromotions(currentPromotion)
  }
  return (
    <IonPage className={styles.termsPage}>
      <IonContent className='ion-padding body-bg'>
        <IonItem
          lines='none'
          className={styles.headerwrap}
        >
          {router?.routeInfo?.lastPathname === '/home/settings' ? <BackBtn returnPath='/home/settings' /> : null}
          <HeaderText
            label='WELCOME TO'
            title='Dateability '
          />
        </IonItem>
        <IonText
          color='dark'
          className='font-bold ion-text-start'
        >
          <h2 className='mt-2 mb-2'>Read and accept the terms to proceed ahead</h2>
        </IonText>
        <div className={styles.viewdata}>
          <IonAccordionGroup multiple>
            <IonAccordion
              value='first'
              key={1}
            >
              <IonItem
                tabIndex={0}
                slot='header'
                color='#E3ECFD'
                className={styles.acclabel}
              >
                <div>
                  <IonLabel
                    className={styles.head}
                    aria-label='Terms of use.'
                  >
                    Terms of Use
                    {agreedItems.is_terms_agreed ? (
                      <IonIcon
                        aria-label='You have agreed to the terms of use.'
                        icon={checkmarkCircleOutline}
                        color='success'
                      />
                    ) : (
                      <IonIcon
                        aria-label='You have not agreed to the terms of use.'
                        icon={alertCircleOutline}
                        color='danger'
                      />
                    )}
                  </IonLabel>
                  <p className={styles.textgrey}>
                    Please expand this section to read {!agreedItems.is_terms_agreed && 'and agree to'} the Terms of Use
                  </p>
                </div>
              </IonItem>
              <div
                className='ion-padding'
                slot='content'
              >
                <p
                  className={styles.policycontent}
                  dangerouslySetInnerHTML={{ __html: TermsOfUse }}
                ></p>
                <div className={styles.lines} />
                <div className={styles.checks}>
                  <label
                    htmlFor='terms'
                    className={styles.checkboxLabel}
                  >
                    <IonCheckbox
                      color='success'
                      slot='start'
                      id='terms'
                      checked={agreedItems.is_terms_agreed}
                      onClick={(e: any) => setAgreedItems({ ...agreedItems, is_terms_agreed: e.target.checked })}
                    />
                    I have read and agree with the terms
                  </label>
                </div>
              </div>
            </IonAccordion>
            <IonAccordion
              value='second'
              key={2}
            >
              <IonItem
                slot='header'
                color='#E3ECFD'
                className={styles.acclabel}
              >
                <div>
                  <IonLabel
                    className={styles.head}
                    aria-label='Privacy policy.'
                  >
                    Privacy Policy
                    {agreedItems.is_privacy_agreed ? (
                      <IonIcon
                        aria-label='You have agreed to the privacy policy.'
                        icon={checkmarkCircleOutline}
                        color='success'
                      />
                    ) : (
                      <IonIcon
                        aria-label='You have not agreed to the privacy policy.'
                        icon={alertCircleOutline}
                        color='danger'
                      />
                    )}
                  </IonLabel>
                  <p className={styles.textgrey}>
                    Please expand this section to read {!agreedItems.is_privacy_agreed && 'and agree to'} the Privacy
                    Policy
                  </p>
                </div>
              </IonItem>
              <div
                className='ion-padding'
                slot='content'
              >
                <p
                  className={styles.policycontent}
                  dangerouslySetInnerHTML={{ __html: PrivacyPolicy }}
                ></p>
                <div className={styles.lines} />
                <div className={styles.checks}>
                  <label
                    htmlFor='policy'
                    className={styles.checkboxLabel}
                  >
                    <IonCheckbox
                      color='success'
                      slot='start'
                      id='policy'
                      checked={agreedItems.is_privacy_agreed}
                      onClick={(e: any) => setAgreedItems({ ...agreedItems, is_privacy_agreed: e.target.checked })}
                    />
                    I have read and agree with the privacy policy
                  </label>
                </div>
              </div>
            </IonAccordion>
            <IonAccordion
              value='third'
              key={3}
            >
              <IonItem
                slot='header'
                color='#E3ECFD'
                className={styles.acclabel}
              >
                <div>
                  <IonLabel
                    className={styles.head}
                    aria-label='Acceptable Use Of policy.'
                  >
                    Acceptable Use Of Policy
                    {agreedItems.is_acceptable_use_agreed ? (
                      <IonIcon
                        aria-label='You have agreed to the acceptable use policy.'
                        icon={checkmarkCircleOutline}
                        color='success'
                      />
                    ) : (
                      <IonIcon
                        aria-label='You have not agreed to the acceptable use policy.'
                        icon={alertCircleOutline}
                        color='danger'
                      />
                    )}
                  </IonLabel>
                  <p className={styles.textgrey}>
                    Please expand this section to read {!agreedItems.is_acceptable_use_agreed && 'and agree to'} the
                    acceptable use Policy
                  </p>
                </div>
              </IonItem>
              <div
                className='ion-padding'
                slot='content'
              >
                <p
                  className={styles.policycontent}
                  dangerouslySetInnerHTML={{ __html: AcceptablePolicy }}
                ></p>
                <div className={styles.lines} />
                <div className={styles.checks}>
                  <label
                    htmlFor='policy'
                    className={styles.checkboxLabel}
                  >
                    <IonCheckbox
                      color='success'
                      slot='start'
                      id='policy'
                      checked={agreedItems.is_acceptable_use_agreed}
                      onClick={(e: any) =>
                        setAgreedItems({ ...agreedItems, is_acceptable_use_agreed: e.target.checked })
                      }
                    />
                    I have read and agree with the acceptable use policy
                  </label>
                </div>
              </div>
            </IonAccordion>
          </IonAccordionGroup>
          <div>
            <IonItem
              lines={'none'}
              className={styles.check}
            >
              <IonCheckbox
                className={styles.optincheckbox}
                color='success'
                slot='start'
                id='email'
                checked={agreedPromotions.allow_email_promotion}
                onClick={() => {
                  updatePromotion('allow_email_promotion')
                }}
              />
              <IonLabel className={styles.optinlabel}>Opt-in to receive email promotions from Dateability.</IonLabel>
            </IonItem>
            <IonItem
              lines={'none'}
              className={styles.check}
            >
              <IonCheckbox
                className={styles.optincheckbox}
                color='success'
                slot='start'
                id='text'
                checked={agreedPromotions.allow_text_promotion}
                onClick={() => {
                  updatePromotion('allow_text_promotion')
                }}
              />
              <IonLabel className={styles.optinlabel}>Opt-in to receive text promotions from Dateability.</IonLabel>
            </IonItem>
            <IonItem
              lines={'none'}
              className={styles.check}
            >
              <IonCheckbox
                className={styles.optincheckbox}
                color='success'
                slot='start'
                id='push'
                checked={agreedPromotions.allow_push_promotion}
                onClick={() => {
                  updatePromotion('allow_push_promotion')
                }}
              />
              <IonLabel className={styles.optinlabel}>Opt-in to receive push promotions from Dateability.</IonLabel>
            </IonItem>
            <div>
              <IonButton
                disabled={
                  !agreedItems.is_privacy_agreed ||
                  !agreedItems.is_terms_agreed ||
                  !agreedItems.is_acceptable_use_agreed
                    ? true
                    : undefined
                }
                expand='block'
                className={styles.agreebtn}
                onClick={nextPage}
              >
                Continue
              </IonButton>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  )
}
export default Agreement
