import {
  IonButton,
  IonCol,
  IonGrid,
  IonImg,
  IonInput,
  IonRow,
  IonTextarea,
  useIonToast,
  IonLabel,
  IonModal,
  IonContent,
  IonCardTitle,
  IonIcon,
  IonSpinner
} from '@ionic/react'
import dayjs from 'dayjs'
import _, { range } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useIonRouter } from '@ionic/react'
import styles from './MoreAbout.module.scss'
import HeaderText from '../../../components/HeaderSection/HeaderText'
import Select from 'react-select'
import { getAboutData, saveAboutData, upload, deleteUpload } from '../services'

import { fetchUserInfoGenderOptions } from '../services'

import { updateUser } from '../../Agreement/services'
import GoBackBtn from '../../../components/GoBackBtn'
import { chevronBackOutline, chevronForwardOutline, informationCircleOutline } from 'ionicons/icons'
import { handleIfValidInteraction } from '../../../util/a11yHelper'
import { IS_ANDROID, IS_MOBILE } from '../../../consts'

const acceptedFiles = ['image/jpeg', 'image/jpg', 'image/png']
const photoList = [
  { photo_url: '', description: '' },
  { photo_url: '', description: '' },
  { photo_url: '', description: '' },
  { photo_url: '', description: '' },
  { photo_url: '', description: '' },
  { photo_url: '', description: '' }
]

type Props = {
  next: (tabName: string) => void
  isEditing?: boolean
}

type PhotoItem = {
  photo_url: string
  description: string
}

interface UserAboutData {
  bio: string
  height: string
  age: string
  main_location: string
  gender: string
  gender_id: number | string
  gender_option: { id: number; alt_label: string }
  first_name?: string
  userPhotos: PhotoItem[]
  instagram_id: number | string

  userGenderInterests: any
}

export const MoreAboutYou: React.FC<Props> = ({ next, isEditing }) => {
  const [present] = useIonToast()
  const [validate, setValidate] = useState<boolean>(false)
  const [aboutData, setAboutData] = useState<UserAboutData>({
    bio: '',
    height: '',
    age: '',
    main_location: '',
    gender: '',
    gender_id: '',
    gender_option: { id: 0, alt_label: '' },

    first_name: '',
    userPhotos: photoList,
    instagram_id: '',
    userGenderInterests: []
  })

  const [genderInterests, setGenderInterests] = useState<any>([])

  const [genderSelectOptions, setGenderSelectOptions] = useState([])
  const [userGenderSelectOptions, setUserGenderSelectOptions] = useState([])

  const [showAndroidDateTutorial, setShowAndroidDateTutorial] = useState(false)

  const [photoIsLoading, setPhotoIsLoading] = useState<Record<number, boolean>>({})

  const router = useIonRouter()
  useEffect(() => {
    const asyncStartup = async () => {
      const fetchedTypes = await fetchUserInfoGenderOptions()
      setGenderSelectOptions(fetchedTypes.data.map((detail) => ({ label: detail.label, value: detail.id })))
      setUserGenderSelectOptions(
        fetchedTypes.data
          .filter((elm) => elm.alt_label !== null)
          .map((detail) => ({ label: detail.alt_label, value: detail.id }))
      )

      const aboutDataResp = await getAboutData()
      let response = { ...aboutDataResp.data }
      if (aboutDataResp.data) {
        const genderInterests = response.userGenderInterests.map((i) =>
          _.find(fetchedTypes.data, { id: i.gender_interest_id })
        )
        setGenderInterests(genderInterests)

        if (!aboutDataResp.data.userPhotos || aboutDataResp.data.userPhotos.length === 0) {
          response.userPhotos = [...photoList]
        } else {
          const photos: any = []
          ;[0, 1, 2, 3, 4, 5].map((index) =>
            photos.push(
              response.userPhotos[index]
                ? { ...response.userPhotos[index] }
                : {
                    photo_url: '',
                    description: ''
                  }
            )
          )
          response.userPhotos = [...photos]
        }
        setAboutData(response)
      } else {
        setAboutData((oldState) => {
          const newState = { ...oldState }
          newState.userPhotos = photoList
          return newState
        })
      }
    }

    //@todo(djw) add start loading pinwheel
    asyncStartup().then()
    //@todo(djw) add .finally stop pinwheel!
  }, [])

  const onDrop = (e: any, key: number) => {
    const [first] = e.target.files
    if (first && acceptedFiles.includes(first.type)) {
      const body = new FormData()
      body.append('photo', first)

      setPhotoIsLoading((loadingStatus) => {
        let newStatus = { ...loadingStatus }
        newStatus[key] = true
        return newStatus
      })

      upload(body)
        .then((photoInfo: any) => {
          if (photoInfo.data) {
            let moreInfo = { ...aboutData }
            moreInfo.userPhotos[key].photo_url = photoInfo.data.url
            setAboutData(moreInfo)
          }
        })
        .finally(() => {
          setPhotoIsLoading((oldStatus) => {
            let newStatus = { ...oldStatus }
            delete newStatus[key]
            return newStatus
          })
        })
    } else {
      present({
        message: 'Please select valid image format(PNG,JPG,JPEG)',
        color: 'danger',
        duration: 2000
      }).then()
    }
  }
  const getAge = (dateString: string) => {
    var today = new Date()
    var birthDate = new Date(dateString || today)
    var age = today.getFullYear() - birthDate.getFullYear()
    var m = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--
    }
    return age
  }
  const saveAbouts = async (isVerify: any) => {
    setValidate(true)
    if (
      aboutData.bio &&
      aboutData.height &&
      aboutData.age &&
      aboutData.main_location &&
      aboutData.gender &&
      genderInterests &&
      genderInterests.length > 0
    ) {
      delete aboutData.userGenderInterests
      const interested_in_ids = genderInterests.map((i) => i.value)

      await updateUser({ interested_in_ids, first_name: aboutData.first_name ? aboutData.first_name : null })

      // This is a fix for users without first name (eg., Apple login users)
      if (aboutData.first_name) {
        let userDetails = localStorage.getItem('userDetails')
        if (userDetails) {
          let details = JSON.parse(userDetails)
          details.first_name = aboutData.first_name
          localStorage.setItem('userDetails', JSON.stringify(details))
        }
      }

      let hasPhotoRequire = 0
      aboutData.userPhotos?.map((img: any) => {
        if (img.photo_url) {
          hasPhotoRequire = hasPhotoRequire + 1
        }
        return null
      })
      if (hasPhotoRequire < 4) {
        await present({
          message: 'You must add at least 4 photos to continue',
          color: 'danger',
          duration: 2000
        })
      } else if (getAge(aboutData.age as string) > 17) {
        let userImage = aboutData.userPhotos?.map((imgs: any) => ({
          photo_url: imgs.photo_url,
          description: imgs.description
        }))
        let reqBody = {
          bio: aboutData.bio,
          main_location: aboutData.main_location,
          height: aboutData.height,
          gender: aboutData.gender,
          gender_type_id: aboutData.gender_id,
          age: dayjs(aboutData.age).format('YYYY-MM-DD') + 'T00:00:00.000Z',
          instagram_id: aboutData.instagram_id,
          photos: userImage
        }
        const res = await saveAboutData(reqBody)
        if (res.data) {
          localStorage.setItem('isUpdated', 'true')
          if (isVerify) {
            if (isEditing) {
              router.push('/verify')
            } else {
              next('verify')
            }
          } else if (isEditing) {
            router.push(`/home/user/own`)
          } else {
            next('looking')
          }
          // next("basicInfo");
        }
      } else {
        present({
          message: 'You must be at least 18 years to join the App',
          color: 'danger',
          duration: 2000
        })
      }
    } else {
      present({
        message: 'Please update the required fields',
        color: 'danger',
        duration: 2000
      })
    }
  }
  const saveAndGoToVerify = () => {
    saveAbouts(true).then()
  }
  const updateField = (field: any) => {
    let currentData = { ...aboutData }
    if (field.target?.name === 'main_location') {
      currentData[field.target.name] = field.target?.value.replace(/\d+/g, '')
    } else {
      currentData[field.target.name] = field.target?.value
    }
    setAboutData(currentData)
  }
  const onUpdateDiscr = (field: any, key: number) => {
    let currentData = { ...aboutData }
    currentData.userPhotos[key][field.target.name] = field.target.value
    setAboutData(currentData)
  }

  const onGenderChange = (selected: any) => {
    let basicData = { ...aboutData }
    basicData.gender = selected.label
    basicData.gender_id = selected.value
    basicData.gender_option = { id: selected.value, alt_label: selected.label }
    setAboutData(basicData)
  }
  const onInterestedChange = (list: any, target: any) => {
    if (target.option) {
      if (target.option.label === 'Everyone') {
        setGenderInterests([target.option])
      } else {
        const items = list.filter((i) => i.label !== 'Everyone')
        setGenderInterests(items)
      }
    } else {
      setGenderInterests([])
    }
  }

  const handleHeightChange = (value: number, key: 'ft' | 'in') => {
    let [heightFoot = 0, heightIn = 0] = (aboutData?.height || '').split('.')
    if (key === 'ft') {
      heightFoot = value
    } else {
      heightIn = value
    }
    const newHeight = `${heightFoot}.${heightIn}`
    setAboutData((data) => ({
      ...data,
      height: newHeight
    }))
  }

  const deleteImage = (key: number) => {
    let currentData = { ...aboutData }
    if (currentData.userPhotos[key].photo_url) {
      deleteUpload({ url: currentData.userPhotos[key].photo_url })
    }
    currentData.userPhotos[key] = { photo_url: '', description: '' }
    setAboutData(currentData)
  }
  const customStyles = {
    menu: (provided: any) => ({
      ...provided,
      color: '#0D0D0D',
      backgroundColor: '#F2F2F6'
    })
  }
  const details: any = localStorage.getItem('userDetails')
  const userData: any = JSON.parse(details)
  const [heightFoot = 0, heightIn = 0] = (aboutData.height || '').split('.')

  return (
    <div className={styles.moreabout}>
      <HeaderText
        title={`More about ${userData.first_name}`}
        label={isEditing ? 'Edit Profile' : 'CREATE YOUR PROFILE'}
        back={true}
      />
      <GoBackBtn />
      <div>
        <IonGrid className='ion-padding'>
          {!userData.first_name ? (
            <IonRow>
              <div className={styles.fullwidth}>
                <IonLabel>Your first name</IonLabel>
                <IonTextarea
                  aria-label='Your first name'
                  aria-required='true'
                  autoGrow={true}
                  name='first_name'
                  placeholder='Enter First Name here'
                  tabIndex={1}
                  maxlength={200}
                  autoCapitalize='on'
                  className={styles.customInput}
                  value={aboutData.first_name}
                  onIonChange={(e) => updateField(e)}
                ></IonTextarea>
              </div>
              {validate && !aboutData.bio && <small className='error-message'>Bio is required</small>}
            </IonRow>
          ) : null}
          <IonRow>
            <div className={styles.fullwidth}>
              <IonLabel className={styles.reqwarning}>
                <span>
                  Bio<span className='red'>*</span>
                </span>
                <span className='req_red'>* required field</span>
              </IonLabel>
              <IonTextarea
                aria-label='Your Bio.'
                aria-required='true'
                autoGrow={true}
                name='bio'
                placeholder='Enter Bio here'
                maxlength={500}
                autoCapitalize='on'
                className={styles.customInput}
                value={aboutData.bio}
                onIonChange={(e) => updateField(e)}
              ></IonTextarea>
            </div>
            {validate && !aboutData.bio && <small className='error-message'>Bio is required</small>}
          </IonRow>
          <IonRow>
            <IonCol size='12'>
              <div>
                <IonLabel>
                  Height<span className='red'>*</span>
                </IonLabel>
                <div className='flex'>
                  <Select
                    aria-label='Your height in feet'
                    aria-required={'true'}
                    options={range(1, 10).map((i, f) => ({
                      value: f,
                      label: `${f} ft.`
                    }))}
                    placeholder='Foot'
                    isSearchable={false}
                    className={styles.reactselect3 + ' ion-margin-end'}
                    classNamePrefix='react-select'
                    styles={customStyles}
                    // @ts-ignore
                    onChange={(e) => handleHeightChange(e.value, 'ft')}
                    name='height_feet'
                    value={
                      aboutData.height
                        ? {
                            label: `${Math.min(Number(heightFoot), 8)} Ft`,
                            value: Math.min(Number(heightFoot), 8)
                          }
                        : ''
                    }
                  />
                  <Select
                    aria-label='Your height in inches.'
                    aria-required={'true'}
                    options={range(0, 12).map((v, i) => ({
                      value: i,
                      label: `${i} in`
                    }))}
                    placeholder='Inches'
                    isSearchable={false}
                    className={styles.reactselect3}
                    classNamePrefix='react-select'
                    styles={customStyles}
                    // @ts-ignore
                    onChange={(e) => handleHeightChange(e?.value, 'in')}
                    name='height_inches'
                    value={
                      aboutData.height
                        ? {
                            label: `${Math.min(Number(heightIn), 11)} in`,
                            value: Math.min(Number(heightIn), 11)
                          }
                        : ''
                    }
                  />
                </div>
                {validate && !aboutData.height && <small className='error-message'>Height is required</small>}
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='12'>
              <div>
                <IonLabel>
                  Date of birth<span className='red'>*</span>
                </IonLabel>
                <IonInput
                  aria-required={'true'}
                  aria-label='Your date of birth, year dash month dash day.'
                  className={styles.customInput}
                  placeholder='Enter Date of birth'
                  name='age'
                  id='myDate'
                  type='date'
                  value={
                    aboutData.age && aboutData.age.indexOf('T') !== -1
                      ? dayjs(aboutData.age.split('T')[0]).format('YYYY-MM-DD')
                      : dayjs(aboutData.age).format('YYYY-MM-DD')
                  }
                  onIonChange={(e) => updateField(e)}
                />
                {validate && !aboutData.age && <small className='error-message'>Age is required</small>}
              </div>
            </IonCol>
          </IonRow>
          {IS_ANDROID ? (
            <IonRow>
              <IonCol size='12'>
                <div
                  className={styles.link}
                  onClick={() => setShowAndroidDateTutorial(true)}
                >
                  <IonIcon
                    slot='start'
                    icon={informationCircleOutline}
                  />{' '}
                  Tip for date selection
                </div>
              </IonCol>
            </IonRow>
          ) : null}
          <IonRow>
            <IonCol size='12'>
              <div>
                <IonLabel>
                  Location<span className='red'>*</span>
                </IonLabel>
                <IonInput
                  aria-label='Location'
                  aria-required='true'
                  className={styles.customInput}
                  placeholder='Enter city'
                  name='main_location'
                  type='text'
                  value={aboutData.main_location || ''}
                  autoCapitalize='words'
                  onIonChange={(e) => updateField(e)}
                />
              </div>
              {validate && !aboutData.main_location && <small className='error-message'>Location is required</small>}
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='12'>
              <div>
                <IonLabel>
                  Gender<span className='red'>*</span>
                </IonLabel>
                <Select
                  aria-label='What is your gender?'
                  aria-required='true'
                  options={userGenderSelectOptions}
                  isSearchable={true}
                  placeholder='Select gender'
                  className={styles.reactselect2}
                  classNamePrefix='react-select'
                  styles={customStyles}
                  onChange={onGenderChange}
                  name='gender'
                  value={
                    aboutData?.gender_option
                      ? { value: aboutData.gender_option.id, label: aboutData.gender_option.alt_label }
                      : {}
                  }
                />
              </div>
              {validate && !aboutData.gender && <small className='error-message'>Gender is required</small>}
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='12'>
              <div>
                <IonLabel>
                  Interested In<span className='red'>*</span>
                </IonLabel>
                <Select
                  aria-label='Select interested gender'
                  isSearchable={false}
                  options={genderSelectOptions}
                  placeholder='Select interest'
                  className={styles.reactselect}
                  classNamePrefix='react-select'
                  styles={customStyles}
                  isMulti={true}
                  onChange={(e: any, t: any) => onInterestedChange(e, t)}
                  name='interested_in'
                  value={genderInterests}
                />
              </div>
              {validate && genderInterests.length === 0 && (
                <small className='error-message'>Interests are required</small>
              )}
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='12'>
              <div>
                <IonLabel>
                  Instagram ID (without &nbsp;<code>@</code>)
                </IonLabel>
                <IonInput
                  aria-label='Enter instagram Id'
                  className={styles.customInput}
                  placeholder='Enter Instagram Id'
                  name='instagram_id'
                  type='text'
                  value={aboutData.instagram_id}
                  autoCapitalize='off'
                  onIonChange={(e) => updateField(e)}
                />
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='12'>
              <IonLabel aria-label='Please upload photos, atleast 4 required.'>
                Please upload photos<span className='red'>*</span>
              </IonLabel>
              <IonLabel
                style={{ fontSize: 'small' }}
                color='medium'
              >
                At least 4 required
              </IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            {aboutData.userPhotos &&
              aboutData.userPhotos.map((item: any, key: number) => {
                return (
                  <div
                    className={styles.uploadrow}
                    key={key}
                  >
                    <div className={styles.uploadwrap}>
                      {item.photo_url ? (
                        <div className={styles.imagebtn}>
                          <IonImg
                            src={item.photo_url}
                            alt='Thumbnail of photo'
                          />
                        </div>
                      ) : (
                        <>
                          {!photoIsLoading[key] && (
                            <>
                              <input
                                type='file'
                                className={styles.upload}
                                onChange={(e) => {
                                  onDrop(e, key)
                                }}
                              />
                              <div className={styles.imagebtn}>
                                <IonImg
                                  aria-hidden='true'
                                  src='/assets/images/upload.svg'
                                  alt='Select a photo to upload'
                                />
                              </div>
                            </>
                          )}
                          {photoIsLoading[key] && (
                            <IonSpinner
                              className={styles.loadingIndicator}
                              name='circles'
                              color='primary'
                            />
                          )}
                        </>
                      )}
                    </div>
                    <div className={styles.fullwidth}>
                      <IonTextarea
                        className={styles.customInput}
                        onIonChange={(e: any) => onUpdateDiscr(e, key)}
                        autoGrow={false}
                        autoCapitalize='on'
                        name='description'
                        placeholder='Enter photo description here'
                        value={item.description}
                      ></IonTextarea>
                    </div>
                    <div
                      role='button'
                      aria-label='Remove this photo'
                      className={styles.delete}
                      tabIndex={0}
                      onClick={() => deleteImage(key)}
                      onKeyUp={(e) => handleIfValidInteraction(e, () => deleteImage(key))}
                    >
                      <IonImg
                        aria-hidden='true'
                        src='/assets/images/delete.svg'
                        alt='Remove this photo'
                      />
                    </div>
                  </div>
                )
              })}
          </IonRow>
        </IonGrid>
        <IonModal
          aria-modal='true'
          isOpen={showAndroidDateTutorial}
          className={styles.moreabout}
          style={{ paddingTop: '2rem' }}
          onDidDismiss={() => setShowAndroidDateTutorial(false)}
        >
          <IonContent
            fullscreen
            color={'#222460'}
          >
            <IonGrid className='ion-padding'>
              <IonRow>
                <IonCol
                  size='12'
                  className={styles.headingText}
                >
                  <IonCardTitle>Tip for Android Calendar</IonCardTitle>
                  <h5>Easily select dates with this helpful tip</h5>
                </IonCol>
              </IonRow>
              <ol style={{ lineHeight: '2rem' }}>
                <li>Tap on the year to easily change years</li>
                <li>
                  Tap on the{' '}
                  <IonIcon
                    style={{ color: 'grey' }}
                    icon={chevronForwardOutline}
                  />{' '}
                  and{' '}
                  <IonIcon
                    style={{ color: 'grey' }}
                    icon={chevronBackOutline}
                  />{' '}
                  arrows to easily change months
                </li>
                <li>Tap on a specific date in the Calendar to select the date</li>
              </ol>
              <img
                aria-hidden='true'
                src='/assets/images/android_date_tutorial.gif'
                alt='Animation showing how to pick a date in Android Clendar'
              />
              <br />
              <IonRow>
                <IonCol size='12'>
                  <IonButton
                    className={styles.customButton}
                    expand='block'
                    onClick={() => setShowAndroidDateTutorial(false)}
                  >
                    Close
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
        </IonModal>
      </div>
      <div className='form-footer'>
        {IS_MOBILE && (
          <IonButton
            expand='block'
            fill='outline'
            onClick={() => saveAndGoToVerify()}
          >
            Verify Profile
          </IonButton>
        )}
        <IonButton
          aria-label='Save your profile details'
          className={styles.customButton}
          expand='block'
          onClick={() => saveAbouts(false)}
        >
          {isEditing ? 'Save' : 'Continue'}
        </IonButton>
      </div>
    </div>
  )
}

export default MoreAboutYou
