import { serviceHandler } from '../../services/serviceHandler'
import { AuthenticationApi } from '../../services/apiUrls'

const { OwnProfile, Profile, ReportUser, BlockUser, SaveUser, GenerateEmailOTP, VerificationRequest } =
  AuthenticationApi

export const getOwnProfile = async (payload) => {
  return serviceHandler({
    url: OwnProfile,
    methodType: 'get',
    payload
  })
}

export const getProfile = (payload) => {
  return serviceHandler({
    url: `${Profile}/${payload.id}`,
    methodType: 'get',
    payload
  })
}

export const reportUser = (payload) => {
  return serviceHandler({
    url: ReportUser,
    methodType: 'post',
    payload
  })
}

export const blockUser = (payload) => {
  return serviceHandler({
    url: BlockUser,
    methodType: 'post',
    payload
  })
}

export const saveUser = (payload) => {
  return serviceHandler({
    url: SaveUser,
    methodType: 'put',
    payload
  })
}

export const sendEmailOTP = (userId, payload) => {
  return serviceHandler({
    url: GenerateEmailOTP + userId,
    methodType: 'get',
    payload
  })
}

export const requestVerification = (requested_user_id) => {
  return serviceHandler({
    url: VerificationRequest,
    methodType: 'post',
    payload: {
      requested_user_id
    }
  })
}
