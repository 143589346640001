import { IonModal, IonHeader, IonToolbar, IonButtons, IonButton, IonContent, IonGrid } from '@ionic/react'
import { useRef } from 'react'
import styles from './ToggleMenu.module.scss'
import { deleteAccount } from '../pages/Settings/services'

const DeleteConfirm = ({ isDelete, setIsDelete }) => {
  const modal = useRef<HTMLIonModalElement>(null)

  function cancel() {
    modal.current?.dismiss()
    setIsDelete(false)
  }

  function confirm() {
    modal.current?.dismiss('', 'confirm')
    setIsDelete(false)
    deleteAccount().then((res) => {
      if (res) {
        setIsDelete(false)
        localStorage.removeItem('token')
        localStorage.removeItem('notification')
        localStorage.removeItem('userDetails')
        window.location.replace('/login')
      }
    })
  }

  // function onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {
  //   if (ev.detail.role === 'confirm') {
  //     alert(`Hello, ${ev.detail.data}!`);
  //   }
  // }

  return (
    <IonModal
      ref={modal}
      isOpen={isDelete}
      onDidDismiss={() => setIsDelete(false)}
    >
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonButton
              fill='clear'
              color='dark'
              onClick={cancel}
              className={styles.contactbutton}
            >
              Cancel
            </IonButton>
          </IonButtons>
          <IonButtons slot='end'>
            <IonButton
              fill='clear'
              color='dark'
              strong={true}
              onClick={confirm}
              className={styles.contactbutton}
            >
              Send
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className={styles.modelwrap}>
        <IonGrid>
          <div className={styles.deletemsg}>
            Are you sure you want to delete your account? This action cannot be undone.
          </div>
        </IonGrid>
      </IonContent>
    </IonModal>
  )
}

export default DeleteConfirm
