import {
  IonContent,
  IonImg,
  IonLabel,
  IonMenu,
  IonMenuToggle,
  IonToolbar,
  IonTitle,
  IonHeader,
  IonItem,
  IonList,
  IonIcon,
  useIonAlert
} from '@ionic/react'
import React, { useState } from 'react'
import styles from './ToggleMenu.module.scss'
import {
  settingsOutline,
  playOutline,
  mailOutline,
  folderOutline,
  logOutOutline,
  helpCircleOutline,
  filterOutline
} from 'ionicons/icons'
import ContactUsModal from './ContactUsModal'
import { StatusBar, Style } from '@capacitor/status-bar'
import { AdMob } from '@capacitor-community/admob'
import { IS_CAPACITOR } from '../consts'

const ToggleMenu: React.FC<any> = ({ contentId }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [presentAlert] = useIonAlert()

  const openModal = async (isOpen) => {
    if (isOpen) {
      if (IS_CAPACITOR) {
        StatusBar.setStyle({ style: Style.Dark })
      }
      await AdMob.hideBanner()
      setIsOpen(true)
    } else {
      if (IS_CAPACITOR) {
        StatusBar.setStyle({ style: Style.Light })
      }
      await AdMob.resumeBanner()
      setIsOpen(false)
    }
  }

  const onLogoutClick = () => {
    presentAlert({
      header: 'Are you sure you want to logout?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: styles.logoutCancelBtn
        },
        {
          text: 'Yes',
          role: 'confirm',
          cssClass: styles.logoutConfirmBtn,
          handler: async () => {
            // await AdMob.removeBanner();
            localStorage.setItem('token', '')
            localStorage.setItem('userDetails', '{}')
            localStorage.setItem('userPhoto', '')
            window.location.replace('/login')
          }
        }
      ],
      cssClass: 'logOutAlert'
    })
  }

  return (
    <IonMenu
      side='end'
      aria-label='Main'
      role='navigation'
      contentId={contentId}
      swipeGesture={false}
    >
      <IonHeader role='presentational'>
        <IonToolbar color='light'>
          <IonTitle className={styles.togglehead}>
            <IonImg
              src='assets/images/fulllogo.svg'
              alt='Dateability Logo'
            />
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList
          role='menu'
          className={styles.menulist}
          lines='none'
        >
          {/*
            this is the template for filter options
          */}
          <IonMenuToggle auto-hide='true'>
            <IonItem
              aria-setsize={6}
              aria-posinset={1}
              role='menuitem'
              button
              routerLink={'/home/filterOptions'}
              routerDirection='none'
            >
              <IonIcon
                aria-hidden='true'
                src={'/assets/images/filter.svg'}
              />
              <IonLabel className={styles.label}>Filter Options</IonLabel>
              <IonIcon
                aria-hidden='true'
                src='/assets/images/rightarrow.svg'
              />
            </IonItem>
          </IonMenuToggle>
          <IonMenuToggle auto-hide='true'>
            <IonItem
              aria-setsize={6}
              aria-posinset={1}
              role='menuitem'
              button
              routerLink={'/home/settings'}
              routerDirection='none'
            >
              <IonIcon
                aria-hidden='true'
                src={settingsOutline}
              />
              <IonLabel className={styles.label}>Account Preferences</IonLabel>
              <IonIcon
                aria-hidden='true'
                src='/assets/images/rightarrow.svg'
              />
            </IonItem>
          </IonMenuToggle>
          <IonMenuToggle auto-hide='true'>
            <IonItem
              aria-setsize={6}
              aria-posinset={2}
              role='menuitem'
              button
              routerLink={'/tutorial'}
              routerDirection='none'
            >
              <IonIcon
                aria-hidden='true'
                src={playOutline}
              />
              <IonLabel className={styles.label}>Watch Tutorials</IonLabel>
              <IonIcon
                aria-hidden='true'
                src='/assets/images/rightarrow.svg'
                color='#ececec'
              />
            </IonItem>
          </IonMenuToggle>
          <IonMenuToggle auto-hide='true'>
            <IonItem
              aria-setsize={6}
              aria-posinset={3}
              role='menuitem'
              button
              routerLink={'/safety'}
              routerDirection='none'
            >
              <IonIcon
                aria-hidden='true'
                src={folderOutline}
              />
              <IonLabel className={styles.label}>Safety & Resources</IonLabel>
              <IonIcon
                aria-hidden='true'
                src='/assets/images/rightarrow.svg'
              />
            </IonItem>
          </IonMenuToggle>
          <IonMenuToggle auto-hide='true'>
            <IonItem
              aria-setsize={6}
              aria-posinset={4}
              role='menuitem'
              button
              routerLink={'/faq'}
              routerDirection='none'
            >
              <IonIcon
                aria-hidden='true'
                src={helpCircleOutline}
              />
              <IonLabel className={styles.label}>FAQ</IonLabel>
              <IonIcon
                aria-hidden='true'
                src='/assets/images/rightarrow.svg'
              />
            </IonItem>
          </IonMenuToggle>
          <IonMenuToggle auto-hide='true'>
            <IonItem
              aria-setsize={6}
              aria-posinset={5}
              role='menuitem'
              button
              routerDirection='none'
              onClick={() => openModal(true)}
            >
              <IonIcon
                aria-hidden='true'
                src={mailOutline}
              />
              <IonLabel className={styles.label}>Contact us</IonLabel>
              <IonIcon
                aria-hidden='true'
                src='/assets/images/rightarrow.svg'
              />
            </IonItem>
          </IonMenuToggle>
          <IonMenuToggle auto-hide='true'>
            <IonItem
              aria-setsize={6}
              aria-posinset={6}
              role='menuitem'
              button
              routerDirection='none'
              onClick={onLogoutClick}
            >
              <IonIcon
                aria-hidden='true'
                src={logOutOutline}
              />
              <IonLabel className={styles.label}>Logout</IonLabel>
              <IonIcon
                aria-hidden='true'
                src='/assets/images/rightarrow.svg'
              />
            </IonItem>
          </IonMenuToggle>
        </IonList>
        <ContactUsModal
          isOpen={isOpen}
          setIsOpen={openModal}
          label={''}
        />
      </IonContent>
    </IonMenu>
  )
}
export default ToggleMenu
