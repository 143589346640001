import { serviceHandler } from '../../services/serviceHandler'
import { AuthenticationApi } from '../../services/apiUrls'

const { Faq } = AuthenticationApi

export const getFaq = (payload) => {
  return serviceHandler({
    url: `${Faq}?limit=100&page=1&search=`,
    methodType: 'get',
    payload
  })
}
