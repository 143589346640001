import React from 'react'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'

var Size = Quill.import('attributors/style/size')
Size.whitelist = ['12px', '16px', '18px']
const ViewAnnounce = (props) => {
  const { message } = props
  const modules = {
    toolbar: [
      [{ header: '1' }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }]
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false
    }
  }
  return (
    <>
      <ReactQuill
        theme={'snow'}
        value={message || ''}
        bounds={'.app'}
        readOnly
        modules={modules}
        // formats={formats}
      />
    </>
  )
}
export default ViewAnnounce
