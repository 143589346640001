import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  useIonToast,
  IonLabel
} from '@ionic/react'
import { useContext, useRef, useState } from 'react'
import { IonRouterContext } from '@ionic/react'
import styles from '../ToggleMenu.module.scss'
import { blockUser } from '../../pages/MyProfile/services'

const BlockModal = ({ isBlockOpen, setIsBlockOpen, id }) => {
  const ionRouterContext = useContext(IonRouterContext)
  const modal = useRef<HTMLIonModalElement>(null)
  const [msg, setMsg] = useState('')

  function cancel() {
    modal.current?.dismiss()
    setIsBlockOpen(false)
  }

  function confirm() {
    modal.current?.dismiss('', 'confirm')
    setIsBlockOpen(false)
    blockUser({ blocked_user_id: id, note: msg }).then((resp: any) => {
      ionRouterContext.push('/home/search')
    })
  }

  // function onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {
  //   if (ev.detail.role === 'confirm') {
  //     // alert(`Hello, ${ev.detail.data}!`);
  //   }
  // }

  return (
    <IonModal
      aria-label='Block user form'
      aria-modal='true'
      ref={modal}
      isOpen={isBlockOpen}
      onDidDismiss={() => setIsBlockOpen(false)}
    >
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonButton
              fill='clear'
              color='dark'
              onClick={cancel}
              className={styles.contactbutton}
            >
              Cancel
            </IonButton>
          </IonButtons>
          <IonButtons slot='end'>
            <IonButton
              fill='clear'
              color='dark'
              strong={true}
              onClick={confirm}
              className={styles.contactbutton}
            >
              Block
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className={styles.modelwrap}>
        <IonGrid>
          <IonRow>
            <IonCol size='12'>
              <IonLabel
                id='id_reason_block_label'
                className={styles.note_label}
              >
                Reason for blocking this person
              </IonLabel>
              <textarea
                aria-labelledby='id_reason_block_label'
                className={styles.customnotetxt}
                rows={8}
                onChange={(e: any) => {
                  setMsg(e.target.value)
                }}
                placeholder="Please tell us why you're blocking this person"
              ></textarea>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonModal>
  )
}

export default BlockModal
