import { type FC, useEffect, useState } from 'react'
import {
  IonButton,
  IonCol,
  IonContent,
  IonLoading,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonToggle,
  useIonRouter,
  useIonModal
} from '@ionic/react'
import styles from './Settings.module.scss'
import './alertmodule.scss'
import ContactUsModal from '../../components/ContactUsModal'
import DeleteConfirm from '../../components/DeleteConfirm'
import { saveSettings, getSettings } from './services'
import { getUser } from '../Agreement/services'
import { saveAboutData, saveBasicData } from '../Onboarding/services'
import BackBtn from '../../components/BackBtn'
import { PaywallModal } from '../../components/PaywallModal'
import { handleIfValidInteraction } from '../../util/a11yHelper'
import { camelCase } from 'lodash'
import { useRevenueCatContext } from '../../contexts/RevenueCatContext'
import { AppLauncher } from '@capacitor/app-launcher'

const links = [
  { label: 'Watch Tutorials', link: '/tutorial' },
  { label: 'Safety and Resources', link: '/safety' },
  { label: 'Contact & FAQ', link: '/faq' },
  { label: 'Privacy', link: '/agreement/settings' },
  { label: 'Terms of Service', link: '/agreement' },
  { label: 'Acceptable Policy', link: '/agreement/settings' }
]
const Notifications = [
  { label: 'Email Notifications', field: 'email_notifications', key: 'e1' },
  { label: 'Push Notifications', field: 'push_notifications', key: 'e3' }
]
const visibility = [
  { label: 'Show education', field: 'education_visibility', key: 'e1' },
  { label: 'Show school ', field: 'school_visibility', key: 'e2' },
  { label: 'Show occupation ', field: 'occupation_visibility', key: 'e3' },
  { label: 'Show religion', field: 'religion_visibility', key: 'e4' },
  { label: 'Show political view ', field: 'political_view_visibility', key: 'e5' },
  { label: 'Show vaccine status', field: 'vaccination_status_visibility', key: 'e6' },
  { label: 'Show children preference', field: 'children_visibility', key: 'e7' }
]

const Settings: FC = () => {
  const { isPlus, managementURL } = useRevenueCatContext()
  const router = useIonRouter()
  const [loading, setLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [isDelete, setIsDelete] = useState(false)
  const [label, setLabel] = useState('')
  const [userInfo, setUserInfo] = useState<any>({})
  const [basicInfo, setBasicInfo] = useState<any>({})
  const [location, setLocation] = useState('')

  // noinspection JSUnusedGlobalSymbols
  const [presentPaywallModal, dismissPaywallModal] = useIonModal(PaywallModal, {
    onDismiss: () => dismissPaywallModal(null, 'cancel')
  })

  const getDetails = async () => {
    setLoading(true)
    const settings = await getSettings()
    if (settings.data) {
      let settingsData = { ...settings.data }
      setUserInfo(settingsData)
    }

    const res = await getUser()
    if (res.data) {
      setBasicInfo(res.data.UserInfo)
      setLocation(res.data.UserInfo.main_location)
    }
    localStorage.setItem('isUpdated', '')
    setLoading(false)
  }

  useEffect(() => {
    getDetails()
  }, [])

  const onReportAbuse = () => {
    setIsOpen(true)
    setLabel('Report Abuse')
  }

  const onAccountDelete = () => {
    setIsDelete(true)
  }
  const onSettingChange = (key, value) => {
    if (userInfo[key] !== undefined) {
      saveSettings({ [key]: value }).then((res) => {
        if (res.data) {
          setUserInfo(res.data)
        }
      })
      localStorage.setItem('isUpdated', 'true')
    }
  }
  const onVisibility = (key, val) => {
    if (basicInfo[key] !== undefined) {
      saveBasicData({ [key]: val }).then((res: any) => {
        if (res.data) {
          setBasicInfo(res.data)
        }
      })
    }
  }
  const onChangePassword = () => {
    router.push(`/change`)
  }
  const updateField = async (field: any) => {
    setLocation(field.target.value)
    let reqBody = {
      main_location: field.target.value
    }
    await saveAboutData(reqBody)
    localStorage.setItem('isUpdated', 'true')
  }

  return (
    <IonPage className={styles.settings}>
      <IonContent
        forceOverscroll={false}
        className={styles.content}
      >
        <IonItem
          lines='none'
          className={styles.headerwrap}
        >
          <BackBtn
            aria-label='Back to search page'
            returnPath='/home/search'
          />
          <IonLabel className={styles.heading}>Account Preferences</IonLabel>
        </IonItem>
        {loading ? (
          <IonLoading isOpen={true} />
        ) : (
          <IonGrid className={styles.gridlist}>
            <div className={styles.dropdown}>
              <IonLabel>Subscription</IonLabel>
              <IonItem
                onClick={async () => {
                  try {
                    if (isPlus && managementURL) {
                      await AppLauncher.openUrl({
                        url: managementURL
                      })
                    } else {
                      presentPaywallModal()
                    }
                  } catch (e) {
                    console.error('[app-launcher] openUrl error', e)
                  }
                }}
              >
                <IonLabel>{isPlus ? 'Manage' : 'Get'} Dateability+</IonLabel>
              </IonItem>
            </div>
            <div className={styles.dropdown}>
              <IonLabel id='label_location'>Location</IonLabel>
              <IonInput
                aria-labelledby='label_location'
                className={styles.customInput}
                placeholder='Enter city'
                name='main_location'
                type='text'
                value={location}
                autoCapitalize='words'
                onIonChange={(e) => updateField(e)}
              />
            </div>
            <IonLabel className={styles.title}>Notifications</IonLabel>
            {Notifications.map((notif) => {
              return (
                <IonRow key={notif.key}>
                  <IonCol size='12'>
                    <IonItem
                      className={styles.radiobtn}
                      lines='none'
                    >
                      <IonLabel id={`label_${camelCase(notif.label)}`}>{notif.label}</IonLabel>
                      <IonToggle
                        aria-labelledby={`label_${camelCase(notif.label)}`}
                        checked={userInfo[notif.field]}
                        onIonChange={(e: any) => {
                          onSettingChange(notif.field, e.detail.checked)
                        }}
                      />
                    </IonItem>
                  </IonCol>
                </IonRow>
              )
            })}
            <div className={styles.borderline} />
            <IonLabel className={styles.title}>Profile Information</IonLabel>
            {visibility.map((item) => {
              return (
                <IonRow key={item.key}>
                  <IonCol size='12'>
                    <IonItem
                      className={styles.radiobtn}
                      lines='none'
                    >
                      <IonLabel id={`label_${camelCase(item.label)}`}>{item.label}</IonLabel>
                      <IonToggle
                        aria-labelledby={`label_${camelCase(item.label)}`}
                        checked={basicInfo[item.field]}
                        onIonChange={(e: any) => {
                          onVisibility(item.field, e.detail.checked)
                        }}
                      />
                    </IonItem>
                  </IonCol>
                </IonRow>
              )
            })}
            <div
              className={styles.borderline}
              role='menu'
            />
            {links.map((item, index) => {
              return (
                <IonRow key={`menuLink${index}`}>
                  <IonCol size='12'>
                    <IonItem
                      className={styles.extlink}
                      lines='none'
                    >
                      <div
                        className={styles.extlinkInner}
                        tabIndex={0}
                        role='link'
                        onClick={(e) => handleIfValidInteraction(e, () => router.push(item.link))}
                        onKeyUp={(e) => handleIfValidInteraction(e, () => router.push(item.link))}
                      >
                        <IonLabel>{item.label}</IonLabel>
                        <IonIcon
                          aria-hidden='true'
                          src={'/assets/images/extlink.svg'}
                        />
                      </div>
                    </IonItem>
                  </IonCol>
                </IonRow>
              )
            })}
            <IonRow>
              <IonCol size='12'>
                <IonButton
                  fill='outline'
                  onClick={onChangePassword}
                  color='primary'
                  expand='block'
                >
                  Change Password
                </IonButton>
                <IonButton
                  fill='outline'
                  onClick={onReportAbuse}
                  color='primary'
                  expand='block'
                >
                  Report Abuse
                </IonButton>
                <IonButton
                  fill='outline'
                  onClick={onAccountDelete}
                  color='danger'
                  expand='block'
                >
                  Delete account
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
        <ContactUsModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          label={label}
        />
        <DeleteConfirm
          isDelete={isDelete}
          setIsDelete={setIsDelete}
        />
      </IonContent>
    </IonPage>
  )
}

export default Settings
