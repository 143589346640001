export const validateField = (fields, data) => {
  let isValid = true
  fields.map((item) => {
    if (!data[item]) {
      isValid = false
    }
    return null
  })
  return isValid
}
