import { useEffect, useState } from 'react'
import { IonButton, IonCol, IonContent, IonGrid, IonPage, IonRow, useIonToast, useIonAlert } from '@ionic/react'
import styles from './Login.module.scss'
import CustomField from '../../components/CustomField'
import { useLoginFields } from '../../data/fields'
import { Action } from '../../components/Action'
import { validateForm } from '../../data/utils'
import { useParams } from 'react-router'
import { useIonRouter } from '@ionic/react'
import { loginService } from './service'
import HeaderText from '../../components/HeaderSection/HeaderText'
import { getUser } from '../Agreement/services'
import SocialLogins from './SocialLogins'
import { handleIfValidInteraction } from '../../util/a11yHelper'

const Login = () => {
  const params = useParams()

  const fields: any = useLoginFields()
  const [errors, setErrors] = useState<any>([])

  //	Initializing our router
  const router = useIonRouter()
  const [present] = useIonToast()
  const [presentAlert] = useIonAlert()

  const handleAfterLogin = (data) => {
    let userObj = {
      email: data.email,
      first_name: data.first_name,
      last_name: data.last_name,
      profile_picture_url: data.profile_picture_url,
      username: data.username,
      objectId: data.objectId,
      id: data.id
    }
    localStorage.setItem('userDetails', JSON.stringify(userObj))
    localStorage.setItem('token', data.token)
    if (
      data.account_status === 'DELETED' ||
      data.account_status === 'REJECTED' ||
      data.account_status === 'TERMINATED' ||
      data.account_status === 'SUSPENDED'
    ) {
      presentAlert({
        message: `It looks like your account has been deactivated. Please reach out to <a href="mailto:support@dateabilityapp.com">support@dateabilityapp.com</a> if you think this is a mistake and would like to restore your account.`,
        buttons: [
          {
            text: 'Ok',
            role: 'cancel',
            cssClass: styles.CancelBtn
          }
        ],
        cssClass: 'logOutAlert'
      })
    } else {
      getUser().then((res: any) => {
        if (res.data) {
          if (res.data.UserDeets && res.data.UserDeets.length > 0) {
            router.push(`/home/user/own`)
          } else if (res.data.is_tutorial_viewed) {
            router.push('/onboarding')
          } else if (res.data.is_terms_agreed || res.data.is_privacy_agreed) {
            router.push('/tutorial')
          } else {
            router.push('/agreement')
          }
        } else {
          present({
            message: res?.response?.data?.msg,
            duration: 5000
          })
        }
      })
    }
  }

  const login = () => {
    const err = validateForm(fields)
    // setErrors(err);
    if (err.length < 1) {
      loginService({
        email: fields[0].input.state.value,
        password: fields[1].input.state.value
      }).then((resp: any) => {
        if (resp.data) {
          handleAfterLogin(resp.data)
        } else {
          present({
            message: 'Something went wrong, Please try again later',
            duration: 1000
          })
        }
      })
    } else {
      present({ message: 'Please enter login details', duration: 1000 })
    }
  }

  useEffect(() => {
    return () => {
      fields.forEach((field: any) => {
        if (field.items?.length) {
          field.items.forEach((item: any) => {
            item.input.state.reset('')
          })
        } else {
          field?.input.state.reset('')
        }
      })
      setErrors(false)
    }
  }, [params])

  const routeToForgotPassword = (e) =>
    handleIfValidInteraction(e, () => {
      router.push('/forgot')
    })

  return (
    <IonPage className={styles.loginPage}>
      <IonContent
        fullscreen
        color={'#222460'}
      >
        <IonGrid className='ion-padding'>
          <IonRow>
            <IonCol
              size='12'
              className={styles.headingText}
            >
              <HeaderText
                title='Welcome'
                label='SIGN IN TO DATEABILITY'
              />
            </IonCol>
          </IonRow>
          <IonRow className='ion-margin-top ion-padding-top'>
            <IonCol size='12'>
              {fields.map((field: any) => {
                return (
                  <CustomField
                    key={field.id}
                    field={field}
                    errors={errors}
                  />
                )
              })}
              <div
                role='link'
                tabIndex={0}
                className={styles.forgot_label}
                onClick={routeToForgotPassword}
                onKeyUp={routeToForgotPassword}
              >
                Forgot password?
              </div>
              <IonButton
                className={styles.customButton}
                expand='block'
                onClick={login}
                aria-label='Submit Login Form'
              >
                Continue
              </IonButton>
            </IonCol>
          </IonRow>
          <SocialLogins handleAfterLogin={handleAfterLogin} />
          <IonRow className={styles.footerText}>
            <IonGrid className='ion-no-margin ion-no-padding'>
              <Action
                message='No account yet?'
                text='Sign up'
                link='/signup'
              />
            </IonGrid>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  )
}

export default Login
