import { IonContent, IonIcon, IonItem, IonLabel, IonPage } from '@ionic/react'
import { useEffect, useState } from 'react'
import styles from './Faq.module.scss'
import { arrowDownCircleOutline, arrowUpCircleOutline } from 'ionicons/icons'
import BackBtn from '../../components/BackBtn'
import { getFaq } from './services'
import { handleIfValidInteraction } from '../../util/a11yHelper'

const Faq = () => {
  const [faqData, setFaqData] = useState<any>([])

  useEffect(() => {
    getFaq().then((res) => {
      let data = res.data?.sort((a, b) => a.ordinal - b.ordinal)
      setFaqData(data)
    })
  }, [])

  const onAccordionPress = (index) => {
    let faq = [...faqData].map((item, i) => {
      if (index !== i) {
        item.isVisbile = false
      }

      return item
    })
    faq[index].isVisbile = !faq[index].isVisbile
    setFaqData(faq)
  }

  return (
    <IonPage className={styles.faq}>
      <IonContent
        forceOverscroll={false}
        className={styles.content}
      >
        <IonItem
          lines='none'
          className={styles.headerwrap}
        >
          <BackBtn returnPath='/home/search' />
          <IonLabel
            aria-label='Frequently Asked Questions'
            className={styles.heading}
          >
            FAQ
          </IonLabel>
        </IonItem>
        <div className={styles.gridlist}>
          {faqData.map((item, index) => (
            <div
              className={styles.gridlistitem}
              key={index}
              style={{ backgroundColor: item.isVisbile ? '#ffffff' : '#EEEEF2' }}
            >
              <div className={styles.question}>
                {index + 1}. {item.question}
                <IonIcon
                  // (ryan) it's unnecessary to have the "expand" button for a screenreader
                  // because we display the full answer anyhow (they are hidden simply by not wrapping the text)
                  // if we ever truly truncate the answer, then we should un-hide this button for screenreaders
                  aria-hidden='true'
                  tabIndex={0}
                  aria-label={item.isVisbile ? 'Collapse answer' : 'Expand answer'}
                  role='button'
                  onClick={() => onAccordionPress(index)}
                  onKeyUp={(e) => handleIfValidInteraction(e, () => onAccordionPress(index))}
                  style={{ color: item.isVisbile ? '#000000' : '#000000' }}
                  className={styles.icon}
                  icon={item.isVisbile ? arrowUpCircleOutline : arrowDownCircleOutline}
                />
              </div>
              <div
                aria-live='assertive'
                className={styles.answer}
                style={{
                  whiteSpace: item.isVisbile ? 'normal' : 'nowrap',
                  color: item.isVisbile ? '#000000' : '#000000'
                }}
                dangerouslySetInnerHTML={{ __html: item.answer }}
              />
            </div>
          ))}
        </div>
        <BackBtn returnPath='/home/search' />
      </IonContent>
    </IonPage>
  )
}
export default Faq
