import axios from 'axios'
import { Base } from './apiUrls'

const axiosInstance = axios.create({
  baseURL: Base
})

axiosInstance.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem('token')
    config.headers['Authorization'] = `JWT ${token}`
    // config.headers['Content-Type'] = `application/json`;
    config.headers['Access-Control-Allow-Origin'] = `*`
    return config
  },
  (error) => {
    // Do something with request error
    return error
  }
)

// Add a response interceptor
// axiosInstance.interceptors.response.use(
//   async response => {
//     // Any status code that lie within the range of 2xx cause this function to trigger
//     // Do something with response data
//     return response;
//   },
//   error => {
//     // Any status codes that falls outside the range of 2xx cause this function to trigger
//     // Do something with response error
//     return Promise.reject(error);
//   },
// );

export default axiosInstance
