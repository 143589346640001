import { serviceHandler } from '../../services/serviceHandler'
import { AuthenticationApi } from '../../services/apiUrls'

const { Changepassword } = AuthenticationApi

export const ChangePassword = (payload) => {
  return serviceHandler({
    url: Changepassword,
    methodType: 'post',
    payload
  })
}
