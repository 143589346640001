import { IonContent, IonPage } from '@ionic/react'

// react hook form
import { FormProvider, useForm } from 'react-hook-form'

//swiper
// import { Swiper, SwiperSlide } from 'swiper/react';

import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import './Onboarding.css'
import BasicInfo from './BasicInfo/BasicInfo'
import MoreAboutYou from './MoreAboutYou/MoreAboutYou'
import YourInterests from './YourInterests/YourInterests'
import DateabilityDeets from './DateabilityDeets/DateabilityDeets'
import Verification from '../Verification'

import 'swiper/css'
import '@ionic/react/css/ionic-swiper.css'
import LookingFor from './LookinFor'

const Onboarding: React.FC = () => {
  const [mySlides, setMySlides] = useState<any>(null)
  const [formData, setFormData] = useState<any>()
  const location = useLocation()
  const history = useHistory()
  const params = new URLSearchParams(location.search)
  const tab = params.get('tab')
  const [activeFormData, setActiveFormData] = useState<string>(tab || 'moreAbout')
  const isEditing = location.pathname === '/edit-profile'

  const methods = useForm()
  const { handleSubmit, trigger } = methods

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const tab = params.get('tab')
    if (location.search && tab) {
      setActiveFormData(tab)
    }
  }, [location])

  const next = async (fields: any) => {
    setActiveFormData(fields)
    const result = await trigger(fields)
    if (!result) return
    if (isEditing) {
      history.push('/home/user/own')
    } else {
      await mySlides?.slideNext()
    }
  }

  const onSubmit = (data: any) => {
    setFormData(data)
  }

  const renderPages = () => {
    switch (activeFormData) {
      case 'moreAbout':
        return (
          <MoreAboutYou
            next={next}
            isEditing={isEditing}
          />
        )
      case 'looking':
        return (
          <LookingFor
            next={next}
            isEditing={isEditing}
          />
        )
      case 'interest':
        return (
          <YourInterests
            next={next}
            isEditing={isEditing}
          />
        )
      case 'basicInfo':
        return (
          <BasicInfo
            next={next}
            isEditing={isEditing}
          />
        )
      case 'verify':
        return (
          <Verification
            next={next}
            isEditing={isEditing}
          />
        )
      case 'deets':
        return (
          <DateabilityDeets
            next={next}
            isEditing={isEditing}
          />
        )
      default:
        break
    }
  }
  return (
    <IonPage>
      <IonContent
        fullscreen
        className='ion-padding'
        scrollY={true}
      >
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            autoComplete='off'
          >
            {renderPages()}
          </form>
        </FormProvider>
        <div style={{ fontSize: 'smaller' }}>
          <pre>{JSON.stringify(formData, null, 2)}</pre>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default Onboarding
