/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Camera, CameraDirection, CameraResultType, CameraSource } from '@capacitor/camera'
import { IonContent, IonPage, IonImg, useIonRouter, useIonToast, IonButton } from '@ionic/react'
import HeaderText from '../../components/HeaderSection/HeaderText'
import styles from './verification.module.scss'
import { poseList } from './constants'
import GoBackBtn from '../../components/GoBackBtn'
import { upload } from '../Onboarding/services'
import { getAllPhoto, savePhoto, deleteUpload } from './services'
import { decode } from 'base64-arraybuffer'
import { IS_MOBILE } from '../../consts'

const Verification: React.FC<any> = ({ next, isEditing = true }) => {
  // const  = props;
  const [photo, setPhoto] = useState<any>([])
  const [present] = useIonToast()
  const router = useIonRouter()

  useEffect(() => {
    getAllPhoto().then((resp) => {
      if (resp.data) {
        setPhoto(resp.data.photos)
      }
    })
  }, [])
  const takePicture = async (poseKey) => {
    const image = await Camera.getPhoto({
      source: CameraSource.Camera,
      quality: 90,
      allowEditing: false,
      resultType: CameraResultType.Base64,
      direction: CameraDirection.Front
    })

    const blob = new Blob([new Uint8Array(decode(image.base64String!))], {
      type: `image/${image.format}`
    })
    const body = new FormData()
    body.append('photo', blob, Date.now().toString())
    upload(body).then((photoInfo: any) => {
      if (photoInfo.data) {
        let currentPhoto = [...photo]
        currentPhoto.push({ photo_url: photoInfo.data.url, type: poseKey })
        setPhoto(currentPhoto)
      }
    })
    // Can be set to the src of an image now
    // imageElement.src = imageUrl;
  }
  const saveAPhotos = () => {
    if (photo.length < 3) {
      present({
        message: 'You must add at least 3 photos to continue',
        color: 'danger',
        duration: 2000
      })
    } else {
      savePhoto(photo).then((res) => {
        if (res.data) {
          present({
            message: 'Successfully sent for verification',
            color: 'success',
            duration: 2000
          })
          if (isEditing) {
            router.push('/home/user/own')
          } else {
            next('basicInfo')
          }
        }
      })
    }
  }
  const redirectToNext = () => {
    if (isEditing) {
      router.push('/home/user/own')
    } else {
      next('basicInfo')
    }
  }
  const removePic = (key) => {
    let currentList = [...photo]
    const index = photo?.findIndex((item) => item.type === key)
    deleteUpload({ url: photo[index].photo_url })
    currentList.splice(index, 1)
    setPhoto(currentList)
  }
  if (IS_MOBILE) {
    return (
      <>
        <IonPage id='verify-page'>
          <IonContent className='ion-padding body-bg'>
            <HeaderText
              title='VERIFY YOUR PROFILE'
              label='Choose from poses'
            />
            <GoBackBtn />
            <p className={styles.info}>To verify your profile and receive a verification badge, follow these steps:</p>
            <li className={styles.steps}>
              <div className={styles.number}>1.</div>
              <div className={styles.point}>
                Choose 3 poses from the model photos below and submit them using your device’s camera.
              </div>
            </li>
            <li className={styles.steps}>
              <div className={styles.number}>2.</div>
              <div className={styles.point}>
                If verified, a badge next to your name will appear within 24 hours of submission.
              </div>
            </li>
            <li className={styles.steps}>
              <div className={styles.number}>3.</div>
              <div className={styles.point}>
                If you cannot complete your profile verification due to inaccessibility, please email{' '}
                <a href='mailto:support@dateabilityapp.com'>support@dateabilityapp.com</a>
              </div>
            </li>
            <div className={styles.photo_content}>
              {poseList.map((item) => {
                let filterFoto = photo.filter((lis) => item.key === lis.type)[0]
                return (
                  <div className={styles.upload_wrap}>
                    <div className={styles.imgsec}>
                      <IonImg
                        style={{ borderRadius: '6px' }}
                        onClick={() => takePicture(item.key)}
                        /* @ts-ignore */
                        src={filterFoto?.photo_url || item.image}
                        alt='skip'
                        /* @ts-ignore */
                        className={filterFoto?.photo_url ? styles.uploadedimage : styles.placeimage}
                      />
                    </div>
                    <div className={styles.details}>
                      <div className={styles.desc}>{item.description}</div>
                      {filterFoto?.photo_url ? (
                        <a
                          className={styles.action}
                          onClick={() => removePic(item.key)}
                        >
                          <IonImg
                            src='/assets/images/delete.svg'
                            alt='delete'
                          />
                          Delete
                        </a>
                      ) : (
                        <a
                          className={styles.action}
                          onClick={() => takePicture(item.key)}
                        >
                          Upload Photo
                        </a>
                      )}
                    </div>
                  </div>
                )
              })}
            </div>
            <div className={styles.customButton}>
              <IonButton
                expand='block'
                onClick={() => saveAPhotos()}
                disabled={photo.length < 3 ? true : undefined}
              >
                Continue
              </IonButton>
            </div>
          </IonContent>
        </IonPage>
      </>
    )
  }
  return (
    <>
      <IonPage id='verify-page'>
        <IonContent className='ion-padding body-bg'>
          <HeaderText
            title='VERIFY YOUR PROFILE'
            label=''
          />
          <GoBackBtn />
          <div className={styles.web_info}>
            The Verification functionality is only available for iOS and Android apps.If you only utilize Dateability's
            web application, please email <a href='mailto:support@dateabilityapp.com'>support@dateabilityapp.com</a> to
            verify you profile using another method.
          </div>
          <div className={styles.customButton}>
            <IonButton
              expand='block'
              onClick={() => redirectToNext()}
            >
              Submit for Verification
            </IonButton>
          </div>
        </IonContent>
      </IonPage>
    </>
  )
}
export default Verification
