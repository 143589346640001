let instance
let globalState = {
  match: 0,
  message: 0,
  like: 0,
  hasnotify: false
}

// @todo get rid of this garbage, and just use react context for "global" state
class StateUtility {
  constructor() {
    if (instance) {
      throw new Error('New instance cannot be created!!')
    }

    instance = this
  }

  getPropertyByName(propertyName) {
    return globalState[propertyName]
  }

  setPropertyValue(propertyName, propertyValue) {
    globalState[propertyName] = propertyValue
  }
}

let notifyUtilityInstance = Object.freeze(new StateUtility())

export default notifyUtilityInstance
