import { useFormInput } from './utils'

export const useSignupFields = () => {
  return [
    {
      id: 'name',
      isCapital: 'on',
      items: [
        {
          id: 'first_name',
          label: 'First name',
          required: true,
          placeholder: 'Enter First Name',
          input: {
            props: {
              type: 'text'
            },
            state: useFormInput('')
          }
        },
        {
          id: 'last_name',
          label: 'Last name',
          placeholder: 'Enter Last name',
          required: true,
          input: {
            props: {
              type: 'text'
            },
            state: useFormInput('')
          }
        }
      ]
    },
    {
      id: 'email',
      label: 'Email',
      placeholder: 'Enter Email ID here',
      required: true,
      isCapital: 'off',
      input: {
        props: {
          type: 'email'
        },
        state: useFormInput('')
      }
    },
    {
      id: 'password',
      label: 'Password',
      placeholder: 'Enter Password here',
      required: true,
      isCapital: 'off',
      input: {
        props: {
          type: 'password'
        },
        state: useFormInput('')
      }
    },
    {
      id: 'cpassword',
      label: 'Confirm Password',
      placeholder: 'Confirm Password here',
      required: true,
      isCapital: 'off',
      input: {
        props: {
          type: 'password'
        },
        state: useFormInput('')
      }
    }
  ]
}

export const useLoginFields = () => {
  return [
    {
      id: 'email',
      label: 'Email',
      placeholder: 'Enter Email ID here',
      required: true,
      input: {
        props: {
          type: 'email'
        },
        state: useFormInput('')
      }
    },
    {
      id: 'password',
      label: 'Password',
      placeholder: 'Enter Password here',
      required: true,
      input: {
        props: {
          type: 'password'
        },
        state: useFormInput('')
      }
    }
  ]
}

export const useEmailOtpValidationFields = () => {
  return [
    {
      id: 'otp-email',
      label: 'Email',
      placeholder: 'Enter Email ID here',
      required: true,
      input: {
        props: {
          type: 'email'
        },
        state: useFormInput('')
      }
    },
    {
      id: 'otp',
      label: 'Email Code',
      placeholder: 'Enter Email Code here',
      required: true,
      input: {
        props: {
          type: 'password'
        },
        state: useFormInput('')
      }
    }
  ]
}

export const deetOptions = [
  {
    label: 'Wheelchair User',
    selected: false
  },
  {
    label: 'Ambulatory Wheelchair user',
    selected: false
  },
  {
    label: 'Mobility aid',
    selected: false
  },
  {
    label: 'Permanent medical device',
    selected: false
  },
  {
    label: 'Service animal',
    selected: false
  },
  {
    label: 'Emotional support animal',
    selected: false
  },
  {
    label: 'Hearing impairment',
    selected: false
  },
  {
    label: 'Visual impairment',
    selected: false
  },
  {
    label: 'Speech impairment',
    selected: false
  },
  {
    label: 'Sign language',
    selected: false
  },
  {
    label: 'Chronic illness',
    selected: false
  },
  {
    label: 'Personal care assistance',
    selected: false
  },
  {
    label: 'Immunocompromised',
    selected: false
  },
  {
    label: 'Physical disability',
    selected: false
  },
  {
    label: 'Psychiatric disability',
    selected: false
  },
  {
    label: 'Other',
    selected: true
  }
]
export const intrestImages = {
  1: {
    icon: '/assets/images/activism.png',
    icon2: '/assets/images/activism.svg',
    label: 'Activism',
    key: 1
  },
  2: {
    icon: '/assets/images/animals.png',
    icon2: '/assets/images/animals.svg',
    label: 'Animals',
    key: 2
  },
  3: {
    icon: '/assets/images/arts.png',
    icon2: '/assets/images/arts.svg',
    label: 'Arts & crafts',
    key: 3
  },
  4: {
    icon: '/assets/images/boardgames.png',
    icon2: '/assets/images/boardgames.svg',
    label: 'Board games',
    key: 4
  },
  5: {
    icon: '/assets/images/camping.png',
    icon2: '/assets/images/camping.svg',
    label: 'Camping',
    key: 5
  },
  6: {
    icon: '/assets/images/concerts.png',
    icon2: '/assets/images/concerts.svg',
    label: 'Concerts',
    key: 6
  },
  7: {
    icon: '/assets/images/cooking.png',
    icon2: '/assets/images/cooking.svg',
    label: 'Cooking & baking',
    key: 7
  },
  8: {
    icon: '/assets/images/writing.png',
    icon2: '/assets/images/writing.svg',
    label: 'Creative Writing',
    key: 8
  },
  9: {
    icon: '/assets/images/dancing.png',
    icon2: '/assets/images/dancing.svg',
    label: 'Dancing',
    key: 9
  },
  10: {
    icon: '/assets/images/museums.png',
    icon2: '/assets/images/museums.svg',
    label: 'Musuems',
    key: 10
  },
  11: {
    icon: '/assets/images/music.png',
    icon2: '/assets/images/music.svg',
    label: 'Music',
    key: 11
  },
  12: {
    icon: '/assets/images/outdoors.png',
    icon2: '/assets/images/outdoors.svg',
    label: 'Outdoor recreation',
    key: 12
  },
  13: {
    icon: '/assets/images/photography.png',
    icon2: '/assets/images/photography.svg',
    label: 'Photography',
    key: 13
  },
  14: {
    icon: '/assets/images/reading.png',
    icon2: '/assets/images/reading.svg',
    label: 'Reading',
    key: 14
  },
  15: {
    icon: '/assets/images/shopping.png',
    icon2: '/assets/images/shopping.svg',
    label: 'Shopping',
    key: 15
  },
  16: {
    icon: '/assets/images/sports.png',
    icon2: '/assets/images/sports.svg',
    label: 'Sports',
    key: 16
  },
  17: {
    icon: '/assets/images/travel.png',
    icon2: '/assets/images/travel.svg',
    label: 'Traveling',
    key: 17
  },
  18: {
    icon: '/assets/images/tv.png',
    icon2: '/assets/images/tv.svg',
    label: 'TV & film',
    key: 18
  },
  19: {
    icon: '/assets/images/videogames.png',
    icon2: '/assets/images/videogames.svg',
    label: 'Video games',
    key: 19
  },
  20: {
    icon: '/assets/images/volunteering.png',
    icon2: '/assets/images/volunteering.svg',
    label: 'Volunteering',
    key: 20
  }
}
