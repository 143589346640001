const Base = `${process.env.REACT_APP_HOST}/v1`

const AuthenticationApi = {
  GoogleLogin: `${Base}/user/auth/google/login`,
  GoogleLogout: `${Base}/user/auth/google/logout`,
  AppleLogin: `${Base}/user/auth/apple/login`,
  AppleLogout: `${Base}/user/auth/apple/logout`,
  Signup: `${Base}/user/auth/signup`,
  VerifyEmail: `${Base}/verification/verify-email/`,
  OnboardBasics: `${Base}/onboarding/basics`,
  OnboardInterests: `${Base}/onboarding/interests`,
  OnboardDeets: `${Base}/onboarding/deets`,
  OnboardMoreAbout: `${Base}/onboarding/more_about_you`,
  UploadPhoto: `${Base}/upload/photo`,
  DeetsList: `${Base}/deets`,
  InterestsList: `${Base}/interests`,
  Match: `${Base}/match_simple`,
  Like: `${Base}/like`,
  Profile: `${Base}/profile`,
  OwnProfile: `${Base}/profile/own`,
  Message: `${Base}/message`,
  Login: `${Base}/user/auth/login`,
  Option: `${Base}/option`,
  Upload: `${Base}/upload/photo`,
  DeleteUpload: `${Base}/upload/file`,
  ReportUser: `${Base}/reported-users`,
  BlockUser: `${Base}/blocked-users`,
  ContactUSOptions: `${Base}/option/type/CONTACT_US`,
  ContactUs: `${Base}/contact_us`,
  Notifications: `${Base}/notifications`,
  NotificationsJoinDate: `${Base}/notifications/joined`,
  NotificationsUnread: `${Base}/notifications/unread_count`,
  GetUser: `${Base}/user`,
  Deleteaccount: `${Base}/user/delete-account`,
  Forgotpassword: `${Base}/user/auth/forgot-password`,
  Resetpassword: `${Base}/user/auth/reset-password`,
  UserSettings: `${Base}/user_settings`,
  Changepassword: `${Base}/user/reset-password`,
  SaveUser: `${Base}/user`,
  GenerateEmailOTP: `${Base}/verification/email-verify-request/`,
  VerificationPhoto: `${Base}/verification_photo`,
  Faq: `${Base}/faq`,
  Resources: `${Base}/resources`,
  VerificationRequest: `${Base}/verification_requests`,
  LookingFor: `${Base}/onboarding/looking_for`,
  ListUserDeets: `${Base}/user/deets/list`,
  UpdateUserDeets: `${Base}/user/deets/update`,

  getUserInfoData: `${Base}/user/info/fetch`,
  saveUserInfoData: `${Base}/user/info/save`,
  listChildPreferenceOptions: `${Base}/user/info/children_preferences/list`,
  listEducationOptions: `${Base}/user/info/education_options/list`,
  listGenderInterestOptions: `${Base}/user/info/gender_options/list`,
  listPoliticalViewOptions: `${Base}/user/info/political_interest_options/list`,

  listRelationshipOptions: `${Base}/user/info/relationship_options/list`,
  fetchUserRelationshipInterests: `${Base}/user/info/user_relationship_options/fetch`,
  putUserRelationshipInterests: `${Base}/user/info/user_relationship_options/save`,

  listReligions: `${Base}/user/info/religions/list`,
  listVaccinatedStatusOptions: `${Base}/user/info/vaccinated_status_options/list`,
  listAllUserInfoOptions: `${Base}/user/info/options/list`
}

export const profileApi = `${Base}/profile`
export const likeApi = `${Base}/like`

export { Base, AuthenticationApi }
